<nb-layout>
  <nb-layout-header
    fixed
    *ngIf="user.loggedIn && (fullScreen$ | async) === false"
    class="box-shadow-override"
  >
    <div class="site-header">
      <div class="header-container">
        <div class="side-toggle-container">
          <nb-icon
            (click)="toggleSidebar()"
            class="side-toggle"
            icon="menu-2-outline"
            pack="eva"
          ></nb-icon>
        </div>
        <div class="logo-container">
          <img
            src="/assets/images/ResplendentData_Logo_Just_Words.svg"
            alt="Logo"
          />
        </div>
      </div>
      <div class="header-container">
        <nb-card class="message-card" *ngIf="headerMessage">
          <nb-card-body class="message-card-body">
            <p>{{ headerMessage }}</p>
          </nb-card-body>
        </nb-card>
        <div></div>
        <nb-actions size="small">
          <nb-action
            *ngIf="!isMobile && showImpersonationMenu"
            (click)="openImpersonationDialog()"
            class="impersonation-action"
            ><nb-icon class="impersonation-action" icon="eye-outline"></nb-icon>
            <div
              class="impersonation-action"
              *ngIf="(impersonating$ | async) !== null"
            >
              {{ (impersonatingOptions$ | async)[impersonating$ | async] }}
            </div></nb-action
          >
          <nb-action
            *ngIf="!isMobile"
            icon="flag-outline"
            [nbPopover]="feedback"
            nbPopoverPlacement="bottom"
          ></nb-action>
          <nb-action
            icon="color-palette-outline"
            [nbPopover]="themeSelector"
            nbPopoverPlacement="bottom"
          ></nb-action>
          <nb-action
            *ngIf="!isMobile"
            icon="search-outline"
            routerLink="/search"
          ></nb-action>
          <nb-action
            *ngIf="!isMobile"
            [nbPopover]="notifications"
            nbPopoverTrigger="click"
            class="notification-action"
          >
            <nb-icon icon="bell-outline"></nb-icon>
            <div
              class="notification-num"
              *ngIf="(notificationCount$ | async) > 0"
            >
              <nb-icon
                *ngIf="(notificationStatus$ | async) === 'success'"
                icon="checkmark-circle-outline"
                status="success"
                class="preview-icon"
              ></nb-icon>
              <nb-icon
                *ngIf="(notificationStatus$ | async) === 'error'"
                icon="alert-circle-outline"
                status="danger"
                class="preview-icon"
              ></nb-icon>
              <div
                *ngIf="(notificationStatus$ | async) === 'loading'"
                [ngTemplateOutlet]="loadingSpinner"
              ></div>
              <div *ngIf="(notificationStatus$ | async) === 'number'">
                {{ notificationCount$ | async }}
              </div>
            </div>
          </nb-action>
          <nb-action class="user-action">
            <nb-user
              [nbContextMenu]="userMenu"
              nbContextMenuTag="user-menu"
              [onlyPicture]="userPictureOnly"
              [name]="user?.name"
              [picture]="'data:image/png;base64,' + user?.picture"
              (click)="logUser()"
              id="user-dropdown"
            >
            </nb-user>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-layout-header>
  <!-- The main menu sidebar -->
  <nb-sidebar
    tag="app-sidebar"
    responsive
    [collapsedBreakpoints]="['md']"
    [compactedBreakpoints]="['sm']"
    class="sidebar-styling"
    *ngIf="user.loggedIn && (fullScreen$ | async) === false"
  >
    <nb-menu
      [items]="menuItems"
      tag="menu"
      class="sidebar-menu-items"
    ></nb-menu>
  </nb-sidebar>
  <nb-sidebar
    right
    tag="utility-sidebar"
    class="utility-sidebar-styling"
    state="collapsed"
  >
    <resplendent-utility-sidebar></resplendent-utility-sidebar>
  </nb-sidebar>

  <nb-layout-column
    [ngClass]="{
    'full-screen-padding': (fullScreen$ | async) === true && !isMobile,
    'normal-padding': (fullScreen$ | async) === false && !isMobile,
    'mobile-padding': isMobile,
  }"
  >
    <div class="layout-content-card">
      <ng-content select="router-outlet"></ng-content>
    </div>
  </nb-layout-column>
</nb-layout>
<ng-template #themeSelector>
  <div class="theme-container">
    <div class="theme-item light" (click)="setTheme(themeOptions[0])"></div>
    <div class="theme-item dark" (click)="setTheme(themeOptions[1])"></div>
    <div class="theme-item cosmic" (click)="setTheme(themeOptions[2])"></div>
  </div>
</ng-template>
<ng-template #notifications>
  <div class="notification-container">
    <h6 *ngIf="(sessionVariables$ | async).notifications.length === 0">
      Nothing to see here...
    </h6>
    <div
      class="notification"
      *ngFor="let notification of (sessionVariables$ | async).notifications"
    >
      <div class="notification-header">
        <div class="title-container">
          <nb-icon
            *ngIf="notification.status !== 'loading'; else loadingSpinner"
            class="header-icon"
            [icon]="iconSelector[notification.status].icon"
            [status]="iconSelector[notification.status].status"
          ></nb-icon>
          <div class="title">{{ notification.title }}</div>
        </div>
        <div class="date-container">
          {{ notification.timestamp | date : 'short' }}
        </div>
      </div>
      <div class="notification-content">
        <div *ngIf="!notificationRoutes[notification.title]; else link">
          {{ notification.message }}
        </div>
        <ng-template #link>
          <a
            *ngIf="notificationRoutes[notification.title]"
            [routerLink]="
              notificationRoutes[notification.title].url +
              (notificationRoutes[notification.title].routeParam
                ? '/' + notification.related_id
                : '')
            "
            [queryParams]="notification.params"
            >{{ notification.message }}</a
          >
        </ng-template>
        <nb-icon
          icon="close-outline"
          (click)="deleteNotification(notification)"
          [ngClass]="{
            'dismiss-button': notification.dismissible,
            'dismiss-button-disabled': !notification.dismissible
          }"
        ></nb-icon>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loadingSpinner>
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="20"
    class="loading-spinner"
  ></mat-progress-spinner>
</ng-template>
<ng-template #feedback>
  <div class="feedback-container">
    <div class="feedback-header">
      <div class="title">
        <h5>⊂⁠(⁠(⁠・⁠▽⁠・⁠)⁠)⁠⊃</h5>
        <!-- <h5>Feedback</h5> -->
      </div>
      <div class="subtitle">We'd love to hear from you!</div>
      <p>
        Our feedback form is like an all-you-can-eat buffet - complaints,
        praise, ideas, you name it - we're hungry for your thoughts!
      </p>
    </div>
    <div class="feedback-content">
      <form [formGroup]="feedbackFormGroup" (ngSubmit)="submitFeedback()">
        <textarea
          nbInput
          fullWidth
          cols="30"
          rows="10"
          placeholder="Enter Feedback"
          formControlName="feedback"
          [status]="
            !feedbackFormGroup.get('feedback')?.valid &&
            feedbackFormGroup.get('feedback')?.touched
              ? 'danger'
              : 'basic'
          "
        ></textarea>
        <button nbButton status="primary" size="small" fullWidth>Submit</button>
        <div *ngIf="feedbackSubmitted" class="feedback-submitted-message">
          Thank you for your feedback!
        </div>
      </form>
    </div>
  </div>
</ng-template>
