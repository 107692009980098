<div class="wrapperDiv">
  <input
    nbInput
    fullWidth
    [status]="status"
    [formControl]="internalFormControl"
    type="text"
    [fieldSize]="size"
    [shape]="shape"
    [nbAutocomplete]="auto"
    [placeholder]="placeholder || ''"
  />
  <nb-autocomplete
    #auto
    [handleDisplayFn]="createAutoCompleteDisplayFn()"
    (selectedChange)="setValue(createAutoCompleteDisplayFn()($event))"
  >
    <nb-option
      *ngFor="let option of filteredControlOptions$ | async"
      [value]="option"
    >
      {{ aliasMap[option] }}
    </nb-option>
  </nb-autocomplete>
  <div
    *ngIf="disabled"
    class="overlayDiv"
    [ngClass]="{
      'shape-rectangle': shape === 'rectangle',
      'shape-semi-round': shape === 'semi-round',
      'shape-round': shape === 'round'
    }"
  ></div>
</div>
