import { getContrastColor } from '@helpers';
import {
  GroupingType,
  ICalculationData,
  IWidgetConfig,
  StdCalcTimeIncrement,
} from '@interfaces';
import { TimeDelta } from '@root/custom_scripts/TimeDeltaStuff';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';

export type SeriesType = 'category' | 'value' | 'time' | 'log';

const floorDateFromResolution = (
  date: Date,
  resolution: StdCalcTimeIncrement,
): string => {
  switch (resolution) {
    case 'minute':
      date.setSeconds(0, 0);
      return date.toISOString();
    case 'hour':
      date.setMinutes(0, 0, 0);
      return date.toISOString();
    case 'day':
    case 'week':
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    case 'month':
      // If the case is month, then we need
      // to set the date to the first of the month
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    case 'quarter':
      date.setMonth(Math.floor(date.getMonth() / 3) * 3);
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    case 'year':
      date.setMonth(0, 1);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
  }
};

export const pieChartDataOptions = (
  label: string,
  value: number,
  color: string,
  textColor: string,
  showDataLabels: boolean,
  calcId: string,
  prefix: string,
  suffix: string,
  hideZeroValues: boolean,
  widget: WidgetStateObject,
) => {
  return {
    name: label ?? 'Blank',
    value,
    itemStyle: { color, borderRadius: 3 },
    calcId,
    label: {
      show: showDataLabels && (!hideZeroValues || value !== 0),
      // formatter: '{b}: ' + prefix + '{c}' + suffix,
      formatter: buildLabelFormatter(
        prefix,
        suffix,
        widget.config.abbreviateNumbers.toggled,
        widget.config.rounding,
        undefined,
        widget.calcArray[0].getActiveData()?.type,
        'pie',
      ),
      // color: getContrastColor(color),
      textBorderWidth: 0,
      color: textColor,
      // backgroundColor: 'inherit',
      // padding: 4,
      // borderRadius: 5,
    },
    tooltip: {
      formatter: buildTooltipFormatter(
        prefix,
        suffix,
        widget.config.abbreviateNumbers.toggled,
        widget.config.rounding,
        widget.calcArray[0].getActiveData()?.type,
        'pie',
      ),
    },
  };
};

export const barChartDataOptions = (
  x: string | number | Date,
  y: number,
  color: string,
  showDataLabels: boolean,
  calcId: string,
  label: string,
  stackMethod: GroupingType,
  roundTo: number,
  prefix: string,
  suffix: string,
  seriesType: SeriesType,
  timeIncrement: StdCalcTimeIncrement,
  widgetConfig: IWidgetConfig,
  previousValue: number,
  widget: WidgetStateObject,
) => {
  let visualX = x;

  if (!label || label === 'null') {
    label = 'Blank';
  }

  if (seriesType === 'time') {
    // Convert the x epoch time to a date object
    if (typeof x === 'string') {
      visualX = new Date(x);
      const userTimezoneOffset = visualX.getTimezoneOffset() * 60000;
      visualX = new Date(visualX.getTime() + userTimezoneOffset);
    } else {
      visualX = new Date(Number(x));
    }
    // Now handle the time increment
    visualX = floorDateFromResolution(visualX, timeIncrement);
  } else {
    if (x === null || x === undefined) {
      visualX = 'Blank';
    }
  }

  const xLabel = stackMethod === 'stackByCalculation' ? label : visualX;
  const labelPosition = stackMethod !== 'none' ? 'inside' : 'top';
  let roundedY;
  try {
    roundedY = y.toFixed(roundTo);
  } catch (e) {
    roundedY = y;
  }

  return {
    value: [xLabel, roundedY],
    itemStyle: { color, borderRadius: 3 },
    name: label,
    calcId,
    originalX: x,
    previousValue,
    label: {
      show: showDataLabels && (!widgetConfig.hideZeroLabels || y !== 0),
      position: labelPosition,
      color: color ? getContrastColor(color) : undefined,
      formatter: buildLabelFormatter(
        prefix,
        suffix,
        widgetConfig.abbreviateNumbers.toggled,
        widgetConfig.rounding,
        undefined,
        widget.calcArray[0].getActiveData()?.type,
        'bar',
      ),
      backgroundColor: 'inherit',
      padding: 4,
      borderRadius: 5,
    },
  };
};

export const lineChartDataOptions = (
  x: string | number | Date,
  y: number,
  color: string,
  showDataLabels: boolean,
  calcId: string,
  label: string,
  stackMethod: GroupingType,
  lastPoint: boolean,
  roundTo: number,
  prefix: string,
  suffix: string,
  seriesType: SeriesType,
  timeIncrement: StdCalcTimeIncrement,
  widgetConfig: IWidgetConfig,
  previousValue: number,
  widget: WidgetStateObject,
) => {
  let visualX = x;

  if (!label || label === 'null') {
    label = 'Blank';
  }

  if (seriesType === 'time') {
    // Convert the x epoch time to a date object
    if (typeof x === 'string') {
      visualX = new Date(x);
      const userTimezoneOffset = visualX.getTimezoneOffset() * 60000;
      visualX = new Date(visualX.getTime() + userTimezoneOffset);
    } else {
      visualX = new Date(x);
    }

    // Now handle the time increment
    floorDateFromResolution(visualX, timeIncrement);
    // visualX = visualX.toISOString();
  } else {
    if (x === null || x === undefined) {
      visualX = 'Blank';
    }
  }

  const xLabel = stackMethod === 'stackByCalculation' ? label : visualX;

  let roundedY;
  try {
    roundedY = Number(y.toFixed(roundTo));
  } catch (e) {
    roundedY = y;
  }
  return {
    value: [xLabel, roundedY],
    itemStyle: { color, borderRadius: 3 },
    emphasis: { scale: 1.5 },
    calcId: calcId,
    originalX: x,
    previousValue,
    name: label,
    label: {
      show: showDataLabels && (!widgetConfig.hideZeroLabels || y !== 0),
      position: 'top',
      color: color ? getContrastColor(color) : undefined,
      formatter: buildLabelFormatter(
        prefix,
        suffix,
        widgetConfig.abbreviateNumbers.toggled,
        widgetConfig.rounding,
        undefined,
        widget.calcArray[0].getActiveData()?.type,
        'line',
      ),
      backgroundColor: 'inherit',
      padding: 4,
      borderRadius: 5,
    },
    symbol: 'circle',
    symbolSize: lastPoint && !showDataLabels ? 13 : 10,
  };
};

export const comparisonLineChartOptions = (
  x: string | number | Date,
  y: number,
  previousValue: number | null,
  label: string,
  widgetConfig: IWidgetConfig,
  seriesType: SeriesType,
  timeIncrement: StdCalcTimeIncrement,
  widget: WidgetStateObject,
) => {
  let visualX = x;

  if (!label || label === 'null') {
    label = 'Blank';
  }

  if (seriesType === 'time') {
    // Convert the x epoch time to a date object
    if (typeof x === 'string') {
      visualX = new Date(x);
      const userTimezoneOffset = visualX.getTimezoneOffset() * 60000;
      visualX = new Date(visualX.getTime() + userTimezoneOffset);
    } else {
      visualX = new Date(x);
    }
    // Now handle the time increment
    floorDateFromResolution(visualX, timeIncrement);
    // visualX = visualX.toISOString();
  } else {
    if (x === null || x === undefined) {
      visualX = 'Blank';
    }
  }

  const xLabel = visualX;

  const comparedValue = comparePoints(
    widgetConfig.comparisonType,
    widgetConfig.comparisonMethod,
    y,
    previousValue,
    widgetConfig.comparisonCustomValue,
  );

  // positive value is green, negative value is red
  const color = comparedValue >= 0 ? '#2ce69b' : '#ff708d';
  return {
    value: [xLabel, y],
    name: label,
    label: {
      show: true,
      position: 'top',
      color: color ? getContrastColor(color) : undefined,
      formatter: buildLabelFormatter(
        '',
        widgetConfig.comparisonMethod === 'percent' ? '%' : '',
        widgetConfig.abbreviateNumbers.toggled,
        widgetConfig.rounding,
        comparedValue,
        widget.calcArray[0].getActiveData()?.type,
        '',
      ),
      backgroundColor: color,
      padding: 3,
      borderRadius: 9999,
    },
    symbol: 'circle',
    symbolSize: 10,
  };
};

export const getDateFormat = (resolution: StdCalcTimeIncrement): string => {
  let format: string;

  switch (resolution) {
    case 'minute':
      format = 'HH:mm';
      break;
    case 'hour':
      format = 'MM/DD HH:00';
      break;
    case 'day':
      format = 'MMM DD';
      break;
    case 'week':
      format = 'MMM DD, YYYY';
      break;
    case 'month':
      format = 'MMM YYYY';
      break;
    case 'quarter':
      format = 'Qo YYYY';
      break;
    case 'year':
      format = 'YYYY';
      break;
    default:
      format = 'YYYY-MM-DD';
  }

  return format;
};

export const getXAxisInterval = (
  resolution: StdCalcTimeIncrement,
  multiplier: number,
): number => {
  let interval: number;

  switch (resolution) {
    case 'minute':
      interval = 60 * 1000 * multiplier;
      break;
    case 'hour':
      interval = 60 * 60 * 1000 * multiplier;
      break;
    case 'day':
      interval = 24 * 60 * 60 * 1000 * multiplier;
      break;
    case 'week':
      interval = 7 * 24 * 60 * 60 * 1000 * multiplier;
      break;
    case 'month':
      interval = 30 * 24 * 60 * 60 * 1000 * multiplier;
      break;
    case 'quarter':
      interval = 90 * 24 * 60 * 60 * 1000 * multiplier;
      break;
    case 'year':
      interval = 365 * 24 * 60 * 60 * 1000 * multiplier;
      break;
    default:
      interval = 24 * 60 * 60 * 1000 * multiplier;
  }

  return interval;
};

export const formatDate = (date: string, format: string): string => {
  const d = new Date(date);

  // Replace 'MMM' with the short month name first to avoid 'MM' conflict
  let formattedDate = format.replace(
    'MMM',
    d.toLocaleString('default', { month: 'short' }),
  );

  // Define replacements without 'MMM' since it's already been replaced
  const replacements: { [key: string]: string } = {
    YYYY: d.getFullYear().toString(),
    MM: ('0' + (d.getMonth() + 1)).slice(-2), // Make sure 'MM' doesn't replace 'MMM' in the format
    DD: ('0' + d.getDate()).slice(-2),
    HH: ('0' + d.getHours()).slice(-2),
    mm: ('0' + d.getMinutes()).slice(-2),
    'Week WW': `Week ${getWeekNumber(d)}`,
    // 'MMM' is not here because it's already replaced
    Qo: `Q${Math.ceil((d.getMonth() + 1) / 3)}`,
  };

  // Now replace the rest of the placeholders
  Object.keys(replacements).forEach((key) => {
    // Use a global regular expression to replace all instances
    const regex = new RegExp(key, 'g');
    formattedDate = formattedDate.replace(regex, replacements[key]);
  });

  return formattedDate;
};

export const getWeekNumber = (d: Date): number => {
  const date = new Date(d.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() || 7));
  const week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        (week1.getDay() || 7)) /
        7,
    )
  );
};

export const abbreviateNumber = (
  num: number | string,
  roundTo: number = 2,
  // If true, will round to the nearest whole number if the decimal is 0
  roundWhole?: boolean,
): string => {
  num = Number(num);
  let units = ['K', 'M', 'B', 'T'];
  let unitIndex = 0;

  while (Math.abs(num) >= 1000) {
    num /= 1000;
    unitIndex++;
  }
  let numStr = num.toString();
  if (unitIndex > 0) {
    numStr = num.toFixed(roundTo);
    const numberParts = numStr.split('.');
    if (roundWhole && Number(numberParts[1]) === 0) {
      numStr = Number(num).toFixed(0);
    }
  }

  return numStr + (units[unitIndex - 1] || '');
};

export const buildLabelFormatter = (
  prefix: string,
  suffix: string,
  abbreviate: boolean,
  roundTo: number,
  forcedValue: number | undefined,
  valueType: string,
  chartType: string,
) => {
  const formatter = (params: any) => {
    // Params is an object if it's coming from a series label.
    // Params is a number if it's coming from a axis label.
    let value = params.value ?? params;
    if (forcedValue !== undefined) {
      value = forcedValue;
    } else if (Array.isArray(value)) {
      value = value[1];
    }
    if (valueType === 'timedelta') {
      value = new TimeDelta(parseFloat(value)).toReadable();
    } else if (abbreviate) {
      const rounding = roundTo === undefined ? 2 : roundTo;
      value = abbreviateNumber(value, rounding, true);
    }
    if (chartType === 'pie') {
      return `${params.name}: ${prefix}${value}${suffix}`;
    }
    return prefix + value + suffix;
  };
  return formatter;
};
export const buildTooltipFormatter = (
  prefix: string,
  suffix: string,
  abbreviate: boolean,
  roundTo: number,
  valueType: string,
  chartType: string,
) => {
  const formatter = (params: any) => {
    // console.log('params', params);
    // Params is an object if it's coming from a series label.
    // Params is a number if it's coming from a axis label.
    let value = params.value ?? params;
    if (Array.isArray(value)) {
      value = value[1];
    }
    if (valueType === 'timedelta') {
      value = new TimeDelta(value).toReadable();
    } else if (abbreviate) {
      const rounding = roundTo === undefined ? 2 : roundTo;
      value = abbreviateNumber(value, rounding, true);
    }
    if (chartType === 'pie') {
      return `${params.name}: ${prefix}${value}${suffix} (${params.percent}%)`;
    }
    return `${params.name}<br/>
            ${params.name}: ${prefix}${value}${suffix}`;
  };
  return formatter;
};

export const buildVisualMapLabelFormatter = (
  prefix: string,
  suffix: string,
  abbreviate?: boolean,
  roundTo?: number,
) => {
  const formatter = (min: any, max: any) => {
    if (min === Infinity && max === Infinity) {
      // Handle the case where min and max are both Infinity
      return '';
    }
    // Min and max are numbers. They can be Infinity or -Infinity.
    let minIsNumber = true;
    let maxIsNumber = true;
    if (min === -Infinity) {
      min = '<=';
      minIsNumber = false;
    } else if (max === Infinity) {
      max = '>';
      maxIsNumber = false;
    }
    if (abbreviate) {
      const rounding = roundTo === undefined ? 2 : roundTo;
      if (typeof min === 'number') {
        min = abbreviateNumber(min, rounding);
      }
      if (typeof max === 'number') {
        max = abbreviateNumber(max, rounding);
      }
    }
    let label = '';
    if (minIsNumber && maxIsNumber) {
      label = prefix + min + suffix + ' - ' + prefix + max + suffix;
    } else if (minIsNumber) {
      label = max + ' ' + prefix + min + suffix;
    } else if (maxIsNumber) {
      label = min + ' ' + prefix + max + suffix;
    }
    return label;
  };
  return formatter;
};

export const buildMinMaxCalculator = (direction: 'up' | 'down') => {
  const generateRoundedMagnitude = (minMax: { min: number; max: number }) => {
    let num = direction === 'up' ? minMax.max : minMax.min;
    if (num === 0) {
      return 0;
    }

    let magnitude = Math.pow(10, Math.floor(Math.log10(num)));
    let leadingDigit = Math.floor(num / magnitude);

    let roundedMagnitude;
    if (direction === 'up') {
      if (leadingDigit < 9) {
        roundedMagnitude = (leadingDigit + 1) * magnitude;
      } else {
        roundedMagnitude = Math.ceil(num / magnitude) * magnitude;
      }
    } else if (direction === 'down') {
      if (leadingDigit > 1) {
        roundedMagnitude = leadingDigit * magnitude;
      } else {
        roundedMagnitude = Math.floor(num / magnitude) * magnitude;
      }
    } else {
      throw new Error("Direction must be 'up' or 'down'");
    }
    return roundedMagnitude;
  };
  return generateRoundedMagnitude;
};

interface IBuildTooltipValueFormatterProps {
  widget: WidgetStateObject;
  prefix?: string;
  suffix?: string;
  roundTo?: number;
  abbreviate?: boolean;
}

export const buildTooltipValueFormatter = ({
  widget,
  prefix = '',
  suffix = '',
}: IBuildTooltipValueFormatterProps) => {
  const formatter = (params: any) => {
    let xAxisLabel = '';
    let tooltipBody = '';

    for (const param of params) {
      // const { seriesName, value, color } = param;
      const seriesName: string = param.seriesName;
      if (seriesName === '') {
        // We don't want to show the total series in the tooltip since
        // it's only used for the stacked total label.
        continue;
      }

      const valueArray: string[] = param.value;
      const axisType: 'xAxis.time' | 'xAxis.category' = param.axisType;
      if (!xAxisLabel) {
        xAxisLabel =
          axisType === 'xAxis.time' && valueArray[0]
            ? formatDate(
                valueArray[0],
                getDateFormat(widget.config.timeIncrement.value),
              )
            : valueArray[0];
      }
      let value: any = Number(valueArray[1]);
      if (!value) {
        continue;
      }
      let previousValue: any = Number(param.data.previousValue);
      const color: string = param.color;

      let change: string = '';
      let previousValueString = '';
      if (widget.config.comparePoints) {
        const diff = comparePoints(
          widget.config.comparisonType,
          widget.config.comparisonMethod,
          value,
          previousValue,
          widget.config.comparisonCustomValue,
        );
        if (diff === Infinity || diff === -Infinity) {
          // infinity sign
          change = '∞';
        } else if (isNaN(diff)) {
          // NaN
          change = 'NaN';
        } else {
          let changeDirection = diff >= 0 ? 'more' : 'less';
          change = `${Math.abs(diff)}${
            widget.config.comparisonMethod === 'percent' ? '%' : ''
          } ${changeDirection} than previous period`;
        }

        if (widget.calcArray[0].getActiveData()?.type === 'timedelta') {
          previousValue = new TimeDelta(previousValue).toReadable();
        }
        const changeFromValue = `${prefix}${previousValue}${suffix}`;

        previousValueString = `
        <span class="hint-text">
          ${changeFromValue} previous period
        </span>
        `;
      }
      if (widget.calcArray[0].getActiveData()?.type === 'timedelta') {
        value = new TimeDelta(value).toReadable();
      }
      tooltipBody += `
      <div style="display: flex; align-items: start; justify-content: space-between; gap: 1.5rem;">
        <div style="display: flex; align-items: center;">
          <div style="width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></div>
          <span>${seriesName}</span>
        </div>
        <div style="display: flex; flex-direction: column; align-items: flex-end;">
          <div style="display: flex; justify-content: flex-end; align-items: flex-end; gap: 5px;">
            <span>${prefix}${value}${suffix}</span>
            ${previousValueString}
          </div>
          <div style="display: flex; justify-content: flex-end; align-items: flex-end; gap: 5px;">
            <span class="hint-text">${change}</span>
          </div>
        </div>
      </div>
      `;
    }
    let tooltipHeader = `
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <span>${xAxisLabel}</span>
    </div>
    `;

    return `
    <div style="padding: 10px; min-width: 150px; max-height: 500px; overflow-y: auto;">
      ${tooltipHeader}
      ${tooltipBody}
    </div>
    `;
  };
  return formatter;
};

export const comparePoints = (
  comparisonType: 'previous' | 'custom',
  comparisonMethod: 'percent' | 'difference',
  currentValue: number,
  previousValue?: number,
  comparisonCustomValue?: number,
): number => {
  if (comparisonType === 'custom') {
    if (!comparisonCustomValue) return;

    if (comparisonMethod === 'percent') {
      return Math.round(
        ((currentValue - comparisonCustomValue) / comparisonCustomValue) * 100,
      );
    } else {
      return currentValue - comparisonCustomValue;
    }
  } else if (comparisonType === 'previous') {
    if (comparisonMethod === 'percent') {
      if (previousValue === null) return 0;

      if (currentValue === null) return 0;

      return Math.round(((currentValue - previousValue) / previousValue) * 100);
    } else {
      if (previousValue === null) return 0;

      if (currentValue === null) return 0;

      return currentValue - previousValue;
    }
  }
};

interface IBuildStackedTotalLabelFormatterProps {
  widget: WidgetStateObject;
}

export const buildStackedTotalLabelFormatter = ({
  widget,
}: IBuildStackedTotalLabelFormatterProps) => {
  const formatter = (params: any) => {
    const dataIndex = params.dataIndex;
    const total = widget.calcArray.reduce((acc: number, calc) => {
      const calcData = calc.getActiveData();
      const values = calcData?.y?.[dataIndex]?.values || [];
      return (
        acc +
        values.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        )
      );
    }, 0);
    const widgetConfig = widget.config;

    const prefix = widgetConfig.dollarPrefix.toggled ? '$' : '';
    const suffix = widgetConfig.percentSuffix.toggled ? '%' : '';
    const abbreviate = widgetConfig.abbreviateNumbers.toggled;
    const roundTo = widgetConfig.rounding;

    let totalValue;
    if (abbreviate) {
      totalValue = abbreviateNumber(total, roundTo, true);
    } else {
      totalValue = total.toFixed(roundTo);
    }
    return `${prefix}${totalValue}${suffix}`;
  };
  return formatter;
};
