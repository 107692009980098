import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import {
  NbComponentShape,
  NbComponentSize,
  NbComponentStatus,
} from '@nebular/theme';
import { Observable, map, of, startWith, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() value: string;
  @Input() formControl_: FormControl;
  @Input() possibleValues: string[];
  @Input() aliasMap: { [key: string]: string } = {};
  @Input() status: NbComponentStatus = 'basic';
  @Input() size: NbComponentSize = 'medium';
  @Input() shape: NbComponentShape = 'rectangle';
  @Input() placeholder: string | null = null;
  @Input() disabled: boolean = false;
  @Output() valueChanges = new EventEmitter<string>();

  internalFormControl: FormControl = new FormControl('');
  filteredOptions: string[] = [];
  filteredControlOptions$: Observable<string[]>;
  reverseAliasMap = {};
  constructor() {
    super();
  }
  ngOnInit(): void {
    if (!this.formControl_) this.formControl_ = new FormControl('');
    this.formControl_.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((value) => {
          if (this.possibleValues.indexOf(value) !== -1) this.value = value;
          this.internalFormControl.value !== value
            ? this.internalFormControl.setValue(this.aliasMap[value])
            : null;
        }),
      )
      .subscribe();
    this.subscribeToInputChanges();
    if (this.possibleValues.length) {
      this.initializeOptionsToFilter();
    }
    // Disable the form control if disabled is true
    this.disabled
      ? this.internalFormControl.disable()
      : this.internalFormControl.enable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.possibleValues && changes.possibleValues.currentValue.length) {
      this.initializeOptionsToFilter();
    }
    setTimeout(() => {
      this.internalFormControl.setValue(
        this.aliasMap[this.value] || this.aliasMap[this.formControl_.value],
      );
      // Disable the form control if disabled is true
      this.disabled
        ? this.internalFormControl.disable()
        : this.internalFormControl.enable();
    }, 0);
  }

  private initializeOptionsToFilter() {
    if (!this.formControl_) this.formControl_ = new FormControl('');
    if (!this.possibleValues.length) return;
    this.possibleValues.forEach((value) => {
      if (!this.aliasMap[value]) this.aliasMap[value] = value;
    });
    this.reverseAliasMap = Object.keys(this.aliasMap).reduce((acc, key) => {
      acc[this.aliasMap[key]] = key;
      return acc;
    }, {});
    this.filteredOptions = this.possibleValues;
    let value = this.value || this.formControl_.value;
    this.internalFormControl.setValue(this.aliasMap[value]);
  }
  public createAutoCompleteDisplayFn() {
    // Item is the value returned from the autocomplete
    let displayFunction = (item: any, cd = this.aliasMap) => {
      if (!item) return '';
      if (!cd) return item;
      return cd[item] || item;
    };
    return displayFunction;
  }
  private filter(value: string): string[] {
    if (!value) {
      return this.filteredOptions;
    }
    if (!this.filteredOptions) {
      return [];
    }
    const filterValue = value.toString().toLowerCase();
    return this.filteredOptions.filter((optionValue) => {
      return this.aliasMap[optionValue]
        .toString()
        .toLowerCase()
        .includes(filterValue);
    });
  }

  private subscribeToInputChanges() {
    this.filteredControlOptions$ = this.internalFormControl.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
      map((labelValue: string) => {
        let value = this.reverseAliasMap[labelValue];
        if (value !== undefined) {
          if (this.value !== value) {
            this.value = value;
            this.valueChanges.emit(value);
            this.formControl_.setValue(value);
          }
          return this.filter('');
        }
        return this.filter(labelValue);
      }),
    );
  }
  setValue(value: string) {
    if (this.value !== value && this.internalFormControl.value !== value)
      this.internalFormControl.setValue(value);
  }
}
