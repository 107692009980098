import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdatesService {
  constructor(updates: SwUpdate) {
    if (!updates.isEnabled) return;
    const updateInterval$ = interval(60 * 1000).pipe(startWith(0));

    updateInterval$.subscribe(() => {
      updates.checkForUpdate(); //.then(() => console.log('checked for update'));
    });
  }
}
