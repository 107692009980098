import { Injectable } from '@angular/core';
import { IAppEventObject, IAppEvents } from '@interfaces';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * This service is used to emit app-wide events and listen to app-wide events.
 * It uses AppEvent type objects to pass data in and out.
 */
@Injectable({
  providedIn: 'root',
})
export class EventQueueService {
  constructor() {}
  private eventBroker = new Subject<IAppEventObject>();

  /**
   * Subscribe to an app event. If intellisense does not show you what app events are registered when writing the parameter
   * you can check out IAppEvents interface. Any key of that interface is an app event type
   * @param eventType Required. The type of event you want to listen to
   * @returns Observable containing a payload of the type matching the event type
   * @usage
   * ```
   * export class ListeningComponent implements OnInit {
   *  constructor(private eventQueue: EventQueueService) {}
   *
   *   ngOnInit() {
   *     this.eventQueue.on("MY_EVENT_TYPE_WITH_NUMBER_PAYLOAD").subscribe(payload => this.handleEvent(payload));
   *   }
   *
   *   handleEvent(n: number) {
   *    // Do something *poke poke*
   *   }
   * }
   * ```
   */
  on<T extends keyof IAppEvents>(eventType: T): Observable<IAppEvents[T]> {
    return this.eventBroker.pipe(
      filter((e) => e.eventType === eventType),
      map((event) => event.payload as IAppEvents[T]),
    );
  }

  /**
   * Dispatch an event to any listeners throughout the app.If intellisense does not show you what app events are registered when
   * writing the parameter you can check out IAppEvents interface. Any key of that interface is an app eventType. The value of the
   * chosen key is the payload type.
   * @param event - Required. An AppEvent object containing the event type and payload.
   * @usageNotes
   * ```
   * export class EmittingComponent {
   *
   *   constructor(private eventQueue: EventQueueService) {}
   *
   *   onClick(n: number) {
   *    this.eventQueue.dispatch("MY_EVENT_TYPE_WITH_NUMBER_PAYLOAD", n));
   *   }
   * }
   * ```
   */
  public dispatch<T extends keyof IAppEvents>(event: T, payload: IAppEvents[T]): void {
    const eventObject: IAppEventObject = {
      eventType: event,
      payload,
    };
    this.eventBroker.next(eventObject);
  }

  public onAll(): Observable<IAppEventObject> {
    return this.eventBroker.asObservable();
  }
}
