//these are some config values for the webapp that get used in multiple compenents

import {
  IPandasFilter,
  IPandasFilterItemV2,
  IPandasFilterV2,
  ISimpleFilter,
} from '@interfaces';

export const defaultPandasFilter: IPandasFilter = {
  left_parentheses: [[]],
  right_parentheses: [[]],
  logic_operators: [],
  filter: [
    {
      index: 0,
      valid: '#ff3d71',
      column: '',
      comparison: '',
      not: false,
      value: '',
      force_datatype: 'none',
      grouping: {
        use_grouping: false,
        grouping_column: '',
        grouping_type: 'contains',
        custom_threshold: 1,
      },
      advanced_value: { value_type: undefined, value: undefined },
      use_advanced: false,
    },
  ],
};

export const defaultPandasFilterItemV2: IPandasFilterItemV2 = {
  index: 0,
  valid: '#ff3d71',
  column: '',
  comparison: '=',
  not_: false,
  value: '',
  forceDatatype: 'none',
  useGrouping: false,
  groupingColumn: '',
  groupingType: 'contains',
  useAdvanced: false,
  logicOperator: 'and',
};

export const defaultPandasFilterV2: IPandasFilterV2 = {
  parentheses: { left_parentheses: [[]], right_parentheses: [[]] },
  items: [defaultPandasFilterItemV2],
};

export const getDefaultPandasFilter = (): IPandasFilter => {
  return JSON.parse(JSON.stringify(defaultPandasFilter));
};

export const defaultSimpleFilter: ISimpleFilter = {
  id: null,
  items: [{ column: '', include: true, values: [], logicalOperator: 'and' }],
};

export const getDefaultSimpleFilter = (): ISimpleFilter => {
  return JSON.parse(JSON.stringify(defaultSimpleFilter));
};

export var defaultColumnOperation = {
  leftParentheses: [[]],
  rightParentheses: [[]],
  logicOperators: [],
  column_name: '',
  useGrouping: false,
  grouping: {
    groupingKey: null,
    groupingType: 'first_match',
    filter: undefined,
  },
  filter: [
    {
      index: 0,
      type: 'column',
      valid: '#ff3d71',
      column: '',
      useOffset: false,
      timezone: 'UTC',
    },
  ],
  type: null,
};
//{ left_parentheses: [[]], right_parentheses: [[]], logic_operators: [], dtype: 'float', filter: [{ index: 0, valid: 'red', column: '',  use_offset: false }] }
var timeIncrements = [
  'nanoseconds',
  'milliseconds',
  'seconds',
  'minutes',
  'hours',
  'days',
  'weeks',
];

let dateNumberConversions = [
  'quarter of year',
  'month of year',
  'week of year',
  'week of quarter',
  'week of month',
  'day of year',
  'day of quarter',
  'day of month',
  'day of week',
];
let datetimeNumberConversions = [
  ...dateNumberConversions,
  'hour of day',
  'minute of hour',
  'second of minute',
];
export var datatype_conversions = {
  boolean: {
    to_types: ['string', 'int'],
    int: null,
    string: null,
    allowed_operators: {
      float: ['+', '-', 'x', '÷', '^'],
      int: ['+', '-', 'x', '÷', '^'],
      boolean: ['+', '-', 'x', '÷', '^'],
      string: [],
      timedelta: ['x'],
      datetime: [],
      date: [],
    },
  },
  float: {
    to_types: ['int', 'boolean', 'string', 'timedelta', 'datetime', 'date'],
    int: null,
    boolean: null,
    string: null,
    datetime: timeIncrements,
    date: timeIncrements,
    timedelta: timeIncrements,

    allowed_operators: {
      float: ['+', '-', 'x', '÷', '^'],
      int: ['+', '-', 'x', '÷', '^'],
      boolean: ['+', '-', 'x', '÷', '^'],
      string: [],
      timedelta: ['x', '÷'],
      datetime: [],
      date: [],
    },
  },
  int: {
    to_types: ['float', 'boolean', 'string', 'timedelta', 'datetime', 'date'],
    float: null,
    boolean: null,
    string: null,
    datetime: timeIncrements,
    date: timeIncrements,
    timedelta: timeIncrements,
    allowed_operators: {
      float: ['+', '-', 'x', '÷', '^'],
      int: ['+', '-', 'x', '÷', '^'],
      boolean: ['+', '-', 'x', '÷', '^'],
      string: [],
      timedelta: ['x', '÷'],
      datetime: [],
      date: [],
    },
  },
  string: {
    to_types: ['int', 'float', 'boolean', 'timedelta', 'datetime', 'date'],
    int: null,
    float: null,
    boolean: null,
    timedelta: null,
    datetime: null,
    date: null,
    allowed_operators: {
      float: [],
      int: [],
      boolean: [],
      string: ['+'],
      timedelta: [],
      datetime: [],
      date: [],
    },
  },
  timedelta: {
    to_types: ['int', 'float', 'string'],
    int: timeIncrements,
    float: timeIncrements,
    string: null,
    allowed_operators: {
      float: ['x', '÷'],
      int: ['x', '÷'],
      boolean: ['x'],
      string: [],
      timedelta: ['+', '-'],
      datetime: ['+'],
      date: ['+'],
    },
  },
  datetime: {
    to_types: ['int', 'float', 'string', 'date'],
    int: datetimeNumberConversions,
    float: datetimeNumberConversions,
    string: [
      'None',
      'weekday',
      'month',
      'year',
      'month/year',
      'month/day',
      'month/day/year',
      'year/month/day',
      'day/month/year',
    ],
    date: null,
    allowed_operators: {
      float: [],
      int: [],
      boolean: [],
      string: [],
      timedelta: ['+', '-'],
      datetime: ['-'],
      date: ['-'],
    },
  },
  date: {
    to_types: ['int', 'float', 'string', 'datetime'],
    int: dateNumberConversions,
    float: dateNumberConversions,
    string: [
      'None',
      'weekday',
      'month',
      'year',
      'month/year',
      'month/day/year',
      'day/month/year',
    ],
    datetime: null,
    allowed_operators: {
      float: [],
      int: [],
      boolean: [],
      string: [],
      timedelta: ['+', '-'],
      datetime: ['-'],
      date: ['-'],
    },
  },
};

export const notificationRoutes: {
  [key: string]: { url: string; param: string; routeParam: boolean };
} = {
  'Broken Widget': { url: '/dash-v2', param: null, routeParam: false },
  // http://localhost:4200/data-streams?dataStreamType=table&dataStreamId=2015f187-e13e-4223-a368-b9b02f8b19cb&engineType=join-tables&sourceUuid=join-tables
  // 'Broken Joined Dataset': { url: 'data-streams?&dataStreamId=', param: null, routeParam: false },
  'Broken Integration Connection': {
    url: '/data-management/integrations/add-integration/',
    param: null,
    routeParam: true,
  },
  'Broken Modified Dataset': {
    url: '/data-management/modified-datasets',
    param: null,
    routeParam: false,
  },
};

export var timezones = [
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'America/Puerto_Rico',
  'UTC',
];

export var extra_cols = ['_transactioncurrencyid_value'];
