<nb-card>
  <nb-card-header>
    <div>{{ title }}</div>
    <div>
      <button nbButton status="danger" (click)="deleteFilter()">
        delete filter
      </button>
      <button nbButton status="basic" (click)="close(true)">
        discard changes
      </button>
      <button nbButton status="success" (click)="close(false)">save</button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- Advanced Filter -->
    <div class="filter-container" *ngIf="filter">
      <resplendent-row-filter-gui-v2
        [filterObject]="filter"
        [columns]="columns"
      ></resplendent-row-filter-gui-v2>
    </div>
    <div *ngIf="!filter" class="centered-row">
      <button nbButton status="primary" (click)="addFilter()">
        Add Filter
      </button>
    </div>
  </nb-card-body>
</nb-card>
