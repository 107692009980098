import { calcStringDateToDate } from '@helpers';
import { IColumnLink } from '@interfaces';
import { CalculationStateObject } from '@root/state/state-model-objects/calculation.state-model';

export function renderModalCellV2(
  row: { [key: string]: any },
  col: string,
  calculation: CalculationStateObject,
) {
  const calcData = calculation.getActiveData();
  const links = calcData.column_links;
  const modifierLinks = calcData?.extras?.links;
  const dtypes = calculation.getColumnDataTypes();
  // do value formatting
  let value;
  // if (
  //   row[col] === null ||
  //   row[col] === undefined ||
  //   row[col] === '' ||
  //   row[col] === 'None'
  // ) {
  //   return '<div style="color: #999999">NULL</div>';
  // }
  if (dtypes && dtypes[col] === 'datetime' && row[col]) {
    let date = calcStringDateToDate(row[col]);
    if (date) value = date.toLocaleString();
  } else value = row[col];
  // if there aren't links return the value
  const modifierLinkColName = `__link__.${col}`;
  const hasModifierLink =
    !!modifierLinks && !!modifierLinks[row[modifierLinkColName]];
  const noLinks = (!links || !links[col]) && !hasModifierLink;
  if (noLinks) {
    if (
      dtypes &&
      (dtypes[col] === 'float' || dtypes[col] === 'int') &&
      row[col]
    ) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }
    return value;
  }
  // if the column has a link build the link
  let linkItems: IColumnLink[] = [];
  if (hasModifierLink) {
    linkItems = modifierLinks[row[modifierLinkColName]];
  } else {
    linkItems = links[col];
  }
  let link = '';
  for (let item of linkItems) {
    const isFlatString = item.type === 'string';
    let dynamicValue;
    if (item.value == col) dynamicValue = value;
    else dynamicValue = row[item.value] === undefined ? '' : row[item.value];
    link += isFlatString ? item.value : dynamicValue;
  }
  return `<a href="${link}" target="_blank">${value}</a>`;
}
