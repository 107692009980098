import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import {
  AdvancedValueType,
  IDataStreamColumn,
  IPandasFilterItemV2,
} from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { StateService } from '@services';
import { RRule } from 'rrule';
import { map, takeUntil } from 'rxjs';
import { timezones } from '../../../custom_scripts/config';
import { TimeDelta } from '../../../custom_scripts/TimeDeltaStuff';
import { VirtualColumnValue } from '@root/interface-registry/data-modifier.interface';
import { Store } from '@ngxs/store';
import { IAppStateModel } from '@root/state/app.model';
import { FilterVariable } from '@root/state/app.actions';
import { betweenDatesPreview } from './helpers';

interface IAdvancedValueTypeLabel {
  value: AdvancedValueType;
  label: string;
}

type IAdvancedValueTypeDescription = {
  [K in AdvancedValueType]: {
    description: string;
    label: string;
  };
};

@Component({
  selector: 'resplendent-advanced-value-dialog-v2',
  templateUrl: './advanced-value-dialog.component.html',
  styleUrls: ['./advanced-value-dialog.component.scss'],
})
export class AdvancedValueV2DialogComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() filterItem: IPandasFilterItemV2 | VirtualColumnValue;
  @Input() columns: IDataStreamColumn[];
  @Input() allowedTypes: AdvancedValueType[] = [
    'datetime',
    'date',
    'timedelta',
    'column',
    'null',
    'boolean',
    'between_dates',
    'filter_variable',
  ];
  DateFormControl = new UntypedFormControl();

  alive = true;

  //value type options for advanced value
  ValueTypes: IAdvancedValueTypeLabel[] = [
    { value: 'between_dates', label: 'Between Dates' },
    { value: 'datetime', label: 'DateTime' },
    { value: 'date', label: 'Date' },
    { value: 'timedelta', label: 'Time Delta' },
    { value: 'column', label: 'Column' },
    { value: 'null', label: 'NULL' },
    { value: 'boolean', label: 'Boolean' },
    { value: 'filter_variable', label: 'Dynamic Filter Variable' },
    { value: 'filtered_timespan', label: 'Filtered Timespan' },
  ];
  dict = {};
  //descriptions for advanced value types
  ValueDescriptions: IAdvancedValueTypeDescription = {
    datetime: {
      description:
        'A specific point in time with sub-second precision. Use this if you want your count to include rows from a constant amount of time',
      label: 'DateTime',
    },
    date: {
      description:
        'A specific point in time with the lowest unit being a day. Use this if you want your count to include rows from the beginning of the specified day',
      label: 'Date',
    },
    timedelta: {
      description: 'An amount of time such as an hour or day',
      label: 'Time Delta',
    },
    column: {
      description:
        'Compare your column value with the value from another column',
      label: 'Column',
    },
    null: { description: 'NULL Value', label: 'NULL' },
    boolean: {
      description: 'A Boolean Value (True or False)',
      label: 'Boolean',
    },
    between_dates: {
      description:
        'Pick two dates that you want the columns value to be between.',
      label: 'Between Dates',
    },
    filter_variable: {
      description:
        'Use a dynamic filter variable to compare against the column value.',
      label: 'Dynamic Filter Variable',
    },
    filtered_timespan: {
      description:
        'A timedelta that is the difference between the start and end of the timespan that this calculation is being filtered with.',
      label: 'Filtered Timespan',
    },
  };

  availableTimezones = timezones;
  userTimezone: string;

  //these lists are used to generate second, minute, and hour dropdown selects
  listTo60 = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59,
  ];
  listTo24 = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  betweenDatesHourOptions = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 23.99,
  ];
  //these are the options for forcing datatypes for the column value type
  AvailableDatatypes = [
    { value: 'none', label: "None (don't force)" },
    { value: 'float', label: 'Number' },
    { value: 'int', label: 'Integer' },
    { value: 'string', label: 'String (Text)' },
    { value: 'datetime', label: 'DateTime' },
    { value: 'date', label: 'Date' },
  ];

  between_dates_options = [
    { value: 'none', label: 'No Date' },
    { value: 'day', label: 'Current Day' },
    { value: 'week', label: 'Current Week' },
    { value: 'month', label: 'Current Month' },
    { value: 'quarter', label: 'Current Quarter' },
    { value: 'year', label: 'Current Year' },
  ];
  between_dates_preview = '';

  //these are some assorted form variables
  Hour = '0';
  Minute = '0';
  Second = '0';
  TimeDeltaString = '';

  OffsetDict = { d: 0, h: 0, m: 0, s: 0 };
  NegativeOffset = true;
  OnlyBusinessDays = false;

  // datetime/date offset is in seconds
  ColumnOffsetDict = { float: 0, int: 0, string: '', datetime: 0, date: 0 };
  ColumnTimeOffsetDict = { d: 0, h: 0, m: 0, s: 0 };
  NegativeColumnOffset = false;
  ColumnTimeDeltaString = '';
  PreviousForcedDatatype = '';

  DateFormSubscription;

  current_rrule: RRule;

  filterVariableIdsToNames$ = this.store
    .select(
      (state) => (state.app as IAppStateModel).appState.filterVariableState,
    )
    .pipe(
      map((filterVariableState) => {
        const filterVariables = filterVariableState.filterVariables;
        let currentIds = [];
        if (
          document.location.href.includes('dash') &&
          filterVariableState.currentFilterVariableState
        ) {
          currentIds =
            filterVariableState.currentFilterVariableState.filterVariables.map(
              (filterVariable) => filterVariable.id,
            );
        }
        const idsToNames = filterVariables.reduce((acc, filterVariable) => {
          if (currentIds.includes(filterVariable.id)) {
            acc[filterVariable.id] = filterVariable.name + ' (In Dash)';
          } else {
            acc[filterVariable.id] = filterVariable.name;
          }
          return acc;
        }, {});
        return idsToNames;
      }),
    );
  filterVariableIds$ = this.store
    .select(
      (state) =>
        (state.app as IAppStateModel).appState.filterVariableState
          .filterVariables,
    )
    .pipe(
      map((filterVariables) => {
        return filterVariables.map((filterVariable) => filterVariable.id);
      }),
    );

  filterVariables$ = this.store.select(
    (state) =>
      (state.app as IAppStateModel).appState.filterVariableState
        .filterVariables,
  );

  constructor(
    private selfRef: NbDialogRef<AdvancedValueV2DialogComponent>,
    private stateService: StateService,
    private store: Store,
  ) {
    super();
  }

  ngOnInit() {
    let del_indexes = [];
    for (let i in this.ValueTypes) {
      if (this.allowedTypes.indexOf(this.ValueTypes[i].value) === -1)
        del_indexes.unshift(i);
    }
    for (let i of del_indexes) this.ValueTypes.splice(i, 1);

    // Check if only business days has been selected
    if (
      this.filterItem.advancedValue &&
      this.filterItem.advancedValue['only_business_day'] !== undefined
    ) {
      this.OnlyBusinessDays =
        this.filterItem.advancedValue['only_business_day'];
    }

    if (this.stateService.sessionVariables.user.timezone) {
      this.userTimezone = this.stateService.sessionVariables.user.timezone;
    } else {
      this.userTimezone = 'UTC';
    }
    // pre select the value type of the comparison in the select
    this.SelectValueType(this.filterItem.advancedValueType);

    //based on the datatype of the advanced value set preset the gui with the selected comparisons values
    if (
      this.filterItem.advancedValueType === 'datetime' ||
      this.filterItem.advancedValueType === 'date'
    ) {
      let date = Date.parse(this.filterItem.advancedValue['time']);
      if (!Number.isNaN(date)) {
        let DateObj = new Date(date);
        if (this.filterItem.advancedValueType === 'datetime') {
          this.Hour = DateObj.getHours() + '';
          this.Minute = DateObj.getMinutes() + '';
          this.Second = Math.round(DateObj.getSeconds()) + '';
        }
        this.DateFormControl.setValue(DateObj);
      }

      if (!isNaN(this.filterItem.advancedValue['offset'] as number)) {
        if (this.filterItem.advancedValue['offset'] < 0) {
          this.NegativeOffset = true;
        } else {
          this.NegativeOffset = false;
        }
        let timedelta = new TimeDelta(this.filterItem.advancedValue['offset']);
        this.OffsetDict.d = timedelta.flatDays;
        if (this.filterItem.advancedValueType === 'datetime') {
          this.OffsetDict.h = timedelta.hourOfDay;
          this.OffsetDict.m = timedelta.MinuteOfHour;
          this.OffsetDict.s = timedelta.SecondOfMinute;
        }

        this.TimeDeltaString = new TimeDelta(
          this.filterItem.advancedValue['offset'],
        ).toReadable();
      }

      if (this.filterItem.advancedValue.between_dates === undefined) {
        this.filterItem.advancedValue.between_dates = {
          start_unit: 'day',
          start_offset: 0,
          start_hour: 0,
          end_unit: 'day',
          end_offset: 0,
          end_hour: 23.99,
        };
        this.filterItem.advancedValue.beginning_or_end = 'beginning';
      }
    } else if (this.filterItem.advancedValueType == 'column') {
      this.PreviousForcedDatatype =
        this.filterItem.advancedValue['force_datatype'];
      if (this.filterItem.advancedValue['force_datatype'] != 'none') {
        this.ColumnOffsetDict[
          this.filterItem.advancedValue.force_datatype as any
        ] = this.filterItem.advancedValue['offset'];
        if (
          this.filterItem.advancedValue['force_datatype'] == 'datetime' ||
          this.filterItem.advancedValue['force_datatype'] == 'date'
        ) {
          if (this.filterItem.advancedValue['offset'] < 0) {
            this.NegativeColumnOffset = true;
          } else {
            this.NegativeColumnOffset = false;
          }

          let timedelta = new TimeDelta(
            this.filterItem.advancedValue['offset'],
          );

          this.ColumnTimeOffsetDict.d = timedelta.flatDays;
          if (this.filterItem.advancedValue['force_datatype'] == 'datetime') {
            this.ColumnTimeOffsetDict.h = timedelta.hourOfDay;
            this.ColumnTimeOffsetDict.m = timedelta.MinuteOfHour;
            this.ColumnTimeOffsetDict.s = timedelta.SecondOfMinute;
          }
        }
      }
    } else if (this.filterItem.advancedValueType == 'timedelta') {
      if (this.filterItem.advancedValue['offset'] < 0) {
        this.NegativeOffset = true;
      } else {
        this.NegativeOffset = false;
      }
      let timedelta = new TimeDelta(this.filterItem.advancedValue['offset']);
      this.OffsetDict.d = timedelta.flatDays;
      this.OffsetDict.h = timedelta.hourOfDay;
      this.OffsetDict.m = timedelta.MinuteOfHour;
      this.OffsetDict.s = timedelta.SecondOfMinute;
    }
    if (!this.filterItem.advancedValueType) {
      if (this.allowedTypes.includes('between_dates')) {
        this.filterItem.advancedValueType = 'between_dates';
      } else {
        this.filterItem.advancedValueType = this.allowedTypes[0];
      }
      this.SelectValueType(this.filterItem.advancedValueType);
    }

    this.DateFormSubscription = this.DateFormControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value) => {
        let date = Date.parse(value);
        if (!Number.isNaN(date)) {
          let DateObj = new Date();
          DateObj.setTime(date);
          DateObj.setHours(
            parseInt(this.Hour),
            parseInt(this.Minute),
            parseInt(this.Second),
          );
          this.filterItem.advancedValue['time'] = DateObj.toLocaleString();
        }
      });

    // If the advanced value doesn't have a timezone, set it to the user's timezone
    if (
      this.filterItem.advancedValue &&
      this.filterItem.advancedValue['timezone'] === undefined
    ) {
      this.filterItem.advancedValue['timezone'] = this.userTimezone;
      this.filterItem.advancedValue['value_timezone'] = this.userTimezone;
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  selectTimezone(timezone) {
    this.filterItem.advancedValue['timezone'] = timezone;
    if (this.filterItem.advancedValueType.includes('date')) {
      this.filterItem.advancedValue.between_dates.timezone = timezone;
    }
  }

  UpdateTime() {
    setTimeout(() => {
      let DateObj = new Date(this.filterItem.advancedValue['time']);
      DateObj.setHours(
        parseInt(this.Hour),
        parseInt(this.Minute),
        parseInt(this.Second),
      );
      this.filterItem.advancedValue['time'] = DateObj.toLocaleString();
    }, 50);
  }

  filterList(filter, list, listOfExceptions = []) {
    //this function filters the values of a list
    let FilteredList = [];
    for (let item of list) {
      try {
        if (
          (item + '').toLowerCase().includes(filter.toLowerCase()) ||
          listOfExceptions.indexOf(item) != -1
        ) {
          FilteredList.push(item);
        }
      } catch (err) {
        console.error(err);
      }
    }
    return FilteredList;
  }

  SelectValueType(ValueType: AdvancedValueType) {
    //this runs when the user selects a value type in the ValueType select, it sets the filterItem.advancedValue to an object appropriate to the selected datatype
    if (ValueType === 'datetime' || ValueType === 'date') {
      //time_type can be either current_time or static_time
      if (
        this.filterItem.advancedValue === undefined ||
        this.filterItem.advancedValue === null ||
        !('time_type' in this.filterItem?.advancedValue)
      ) {
        this.filterItem.advancedValue = {
          time_type: 'current_time',
          between_dates: {
            start_unit: 'day',
            start_offset: 0,
            start_hour: 0,
            end_unit: 'day',
            end_offset: 0,
            timezone: this.userTimezone,
            end_hour: 23.99,
          },
          beginning_or_end: 'beginning',
          time: undefined,
          offset: 0,
          timezone: this.userTimezone,
        };
      } else {
        this.DateFormControl.setValue(
          new Date(this.filterItem.advancedValue['time']),
        );
      }
    } else if (ValueType == 'column') {
      if (
        this.filterItem.advancedValue == undefined ||
        !('column' in this.filterItem.advancedValue)
      ) {
        this.filterItem.advancedValue = {
          column: undefined,
          force_datatype: 'none',
          offset: 0,
          use_offset: false,
          timezone: this.userTimezone,
        };
        this.ColumnTimeOffsetDict = { d: 0, h: 0, m: 0, s: 0 };
        this.SaveColumnOffset({ target: { value: '' } }, 'datetime', 'd');
      }
    } else if (ValueType == 'null') {
      this.filterItem.advancedValue = {};
    } else if (ValueType == 'boolean') {
      if (
        this.filterItem.advancedValue == undefined ||
        this.filterItem.advancedValue.value != false
      ) {
        this.filterItem.advancedValue = { value: false };
      }
    } else if (ValueType == 'timedelta') {
      if (
        this.filterItem.advancedValue == undefined ||
        this.filterItem.advancedValue.offset == undefined
      ) {
        this.filterItem.advancedValue = { offset: 0 };
      }
    } else if (ValueType == 'between_dates') {
      if (
        this.filterItem.advancedValue == undefined ||
        this.filterItem.advancedValue.start_unit == undefined
      ) {
        this.filterItem.advancedValue = {
          start_unit: 'none',
          start_offset: 0,
          start_hour: 0,
          end_unit: 'none',
          end_offset: 0,
          timezone: this.userTimezone,
          end_hour: 23.99,
        };
      } else {
        if (this.filterItem.advancedValue.start_hour == undefined) {
          this.filterItem.advancedValue.start_hour = 0;
        }
        if (this.filterItem.advancedValue.end_hour == undefined) {
          this.filterItem.advancedValue.end_hour = 23.99;
        }
      }
      this.generate_between_dates_preview();
    } else if (ValueType == 'filter_variable') {
      if (
        this.filterItem.advancedValue == undefined ||
        this.filterItem.advancedValue.variable_id == undefined
      ) {
        this.filterItem.advancedValue = {
          variable_id: null,
        };
      }
      this.store.dispatch(new FilterVariable.GetAll());
    }
  }

  selectForceDatatype(dtype) {
    // this runs when the user selects a forced datatype for their column value it presets the offset value and resets some forms
    if (this.PreviousForcedDatatype != dtype) {
      this.PreviousForcedDatatype = dtype;
      if (dtype == 'string') {
        this.filterItem.advancedValue['offset'] = '';
        this.ColumnTimeOffsetDict = { d: 0, h: 0, m: 0, s: 0 };
      } else {
        this.filterItem.advancedValue['offset'] = 0;
        this.ColumnTimeOffsetDict = { d: 0, h: 0, m: 0, s: 0 };
      }
    }
  }

  BusinessDaysClick(checked: boolean) {
    this.OnlyBusinessDays = checked;
    this.filterItem.advancedValue['only_business_day'] = checked;
  }

  NegativeClick() {
    // this runs when the user clicks the negative offset checkbox for the datetime and date datatypes
    // it turns the current value of the offset negative or positive depending on if the checkbox is checked or not
    setTimeout(() => {
      let seconds = 0;
      for (let timescale in this.OffsetDict) {
        seconds += new TimeDelta(
          parseInt(this.OffsetDict[timescale]),
          timescale,
        ).Seconds;
      }
      if (this.NegativeOffset) {
        seconds = -seconds;
      }
      this.TimeDeltaString = new TimeDelta(seconds).toReadable();
      this.filterItem.advancedValue['offset'] = seconds;
    }, 50);
  }

  NegativeColumnClick() {
    //this function does the same thing as the one above but for time offsets with the columm value selected
    setTimeout(() => {
      let seconds = 0;
      for (let timescale in this.ColumnTimeOffsetDict) {
        seconds += new TimeDelta(
          parseInt(this.ColumnTimeOffsetDict[timescale]),
          timescale,
        ).Seconds;
      }
      if (this.NegativeColumnOffset) {
        seconds = -seconds;
      }
      this.ColumnTimeDeltaString = new TimeDelta(seconds).toReadable();
      this.filterItem.advancedValue['offset'] = seconds;
    }, 50);
  }

  SaveOffset(event, timeScale) {
    //this saves the offset value in seconds from the time offset form for the datetime and date Value Types as well as updates the readable label for the offset
    event.target.value = this.numberEntryFormat(event.target.value);

    if (event.target.value != '') {
      this.OffsetDict[timeScale] = parseInt(event.target.value);
    } else {
      this.OffsetDict[timeScale] = 0;
    }

    let seconds = 0;
    for (let timescale in this.OffsetDict) {
      seconds += new TimeDelta(parseInt(this.OffsetDict[timescale]), timescale)
        .Seconds;
    }
    if (this.NegativeOffset) {
      seconds = -seconds;
    }
    this.TimeDeltaString = new TimeDelta(seconds).toReadable();
    this.filterItem.advancedValue['offset'] = seconds;
  }

  SaveColumnOffset(event, type, timeScale = 'none') {
    // this saves the offset for column values
    if (type == 'float') {
      event.target.value = this.numberEntryFormat(event.target.value, true);

      if (event.target.value != '') {
        this.filterItem.advancedValue['offset'] = parseFloat(
          event.target.value,
        );
      } else {
        this.filterItem.advancedValue['offset'] = 0;
      }
      this.ColumnOffsetDict[type] = event.target.value;
    } else if (type == 'int') {
      event.target.value = this.numberEntryFormat(event.target.value);

      if (event.target.value != '') {
        this.filterItem.advancedValue['offset'] = parseInt(event.target.value);
      } else {
        this.filterItem.advancedValue['offset'] = 0;
      }
      this.ColumnOffsetDict[type] = event.target.value;
    } else if (type == 'string') {
      this.filterItem.advancedValue['offset'] = event.target.value;
      this.ColumnOffsetDict[type] = event.target.value;
    } else if (type == 'datetime' || type == 'date') {
      event.target.value = this.numberEntryFormat(event.target.value);
      if (event.target.value != '') {
        this.ColumnTimeOffsetDict[timeScale] = parseInt(event.target.value);
      } else {
        this.ColumnTimeOffsetDict[timeScale] = 0;
      }

      let seconds = 0;
      for (let timescale in this.ColumnTimeOffsetDict) {
        seconds += new TimeDelta(
          parseInt(this.ColumnTimeOffsetDict[timescale]),
          timescale,
        ).Seconds;
      }
      if (this.NegativeColumnOffset) {
        seconds = -seconds;
      }
      this.ColumnTimeDeltaString = new TimeDelta(seconds).toReadable();
      this.filterItem.advancedValue['offset'] = seconds;
      this.ColumnOffsetDict[type] = seconds;
    }
  }
  save_between_dates_offset(event, is_start) {
    let value = this.numberEntryFormat(event.target.value);
    if (value == '' || value == '-') {
      value = 0;
    } else {
      value = parseFloat(value);
    }
    event.target.value = value;
    if (is_start) {
      this.filterItem.advancedValue.start_offset = value;
    } else {
      this.filterItem.advancedValue.end_offset = value;
    }
  }

  numberEntryFormat(string, allowDecimal = false) {
    //this function is used for a bunch of the number entries for custom validation
    let alreadyAllowedDecimal = false;
    let charList = string.split('');
    let indexToDeleteList = [];
    for (let charIndex in charList) {
      if (
        (isNaN(charList[charIndex]) && charList[charIndex] != '-') ||
        (charList[charIndex] == '-' && parseInt(charIndex) != 0)
      ) {
        if (
          allowDecimal &&
          charList[charIndex] == '.' &&
          !alreadyAllowedDecimal
        ) {
          alreadyAllowedDecimal = true;
        } else {
          indexToDeleteList.push(charIndex);
        }
      }
    }
    indexToDeleteList.reverse();
    for (let index of indexToDeleteList) {
      charList.splice(index, 1);
    }
    return charList.join('');
  }
  generate_between_dates_preview() {
    this.between_dates_preview = betweenDatesPreview(
      this.filterItem.advancedValue,
    );
  }
  setDatetimeBetweenDatesOffset(event) {
    let value = this.numberEntryFormat(event.target.value);
    if (!(value == '' || value == '-')) {
      value = parseFloat(value);
      this.filterItem.advancedValue.between_dates.start_offset = value;
      this.filterItem.advancedValue.between_dates.end_offset = value;
    }
  }

  CloseDialog() {
    this.selfRef.close();
  }
  showValue(value) {
    if (typeof value === 'object') {
      return value.label;
    }
    return value;
  }
}
