const defaultColorsSource = [
  '#0095FF',
  '#00D68F',
  '#fafa52',
  '#FFAA00',
  '#FF3D71',
  '#9649CB',
  '#2E4052',
  '#E9724C',
  '#08605F',
  '#F5D547',
  '#2180c0',
  '#ba1277',
  '#3326e3',
  '#1ec829',
  '#c12115',
  '#1bc598',
  '#fac942',
  '#b32ae5',
  '#98db33',
  '#1984e1',
  '#dd318d',
  '#3a24cc',
  '#26ed41',
  '#dd3f27',
  '#40edcb',
  '#f5d866',
  '#bf45de',
  '#61a711',
  '#3887e0',
  '#eb4291',
  '#3613c3',
  '#1bbb3b',
  '#bd3614',
  '#1cb09d',
  '#fae242',
  '#c416df',
  '#8cde45',
  '#4588ed',
  '#b21f5a',
  '#734ae3',
  '#14db49',
  '#e2683c',
  '#0fa99f',
  '#f1e432',
  '#d648e0',
  '#57be13',
  '#1745a1',
  '#ea437b',
  '#420eaa',
  '#49df7b',
  '#fc8c54',
  '#22dddd',
  '#a711a7',
  '#71de3b',
  '#1342c3',
  '#e03865',
  '#8a4de5',
  '#1aea6d',
  '#f28136',
  '#15c4d1',
  '#d4de3f',
  '#dd22d0',
  '#71ef43',
  '#1e41cc',
  '#cb1036',
  '#8933eb',
  '#1bb161',
  '#fca04f',
  '#1ba1b6',
  '#9bb011',
  '#ce12b5',
  '#57de35',
  '#334beb',
  '#ae192d',
  '#6d1fb2',
  '#15cb76',
  '#ef9c3e',
  '#34c5ea',
  '#aed025',
  '#af1891',
  '#2abd14',
  '#1b25bb',
  '#ce121f',
  '#a540e7',
  '#4be2a6',
  '#fdb344',
  '#1c90ba',
  '#95c219',
  '#ef39be',
  '#2ccf20',
];

export type DefaultColorOverload = {
  (): string[];
  (param1: number): string;
  (param1: number, param2?: number): string[];
};

/**
 * Get default chart colors. Function has 3 overloads:
 * - No params - returns the full array of colors
 * - Index - returns the default color at a matching index if the input is a single number
 * - Range - returns a range of default colors if input contains 2 numbers. The returned array will contain the color at
 * the start index only once but will contain a repeated sequence of all other colors. The value at the start index is included
 * but the end index value is excluded ((12,22) => 10 colors)
 */
export const getDefaultChartColors: DefaultColorOverload = (
  param1?: number,
  param2?: number,
): any => {
  const inputIsAll = param1 === undefined && param2 === undefined;
  const inputIsIndex = typeof param1 === 'number' && param2 === undefined;
  const inputIsRange = typeof param1 === 'number' && typeof param2 === 'number';

  if (!inputIsAll && !inputIsIndex && !inputIsRange) {
    throw new Error(
      'Error, wrong input format, please call the function without an argument, with 1 number arg or 2 number args',
    );
  }

  if (inputIsAll) {
    return defaultColorsSource;
  }
  if (inputIsIndex) {
    const index = param1;
    return defaultColorsSource[index % defaultColorsSource.length];
  }
  if (inputIsRange) {
    const start = param1;
    const end = param2;
    const returnColors = [];
    if (end === 0) return [];
    if (start >= end)
      throw new Error(
        'TO value has to be greater than FROM value for range input. DUH!',
      );
    const startMod = start % (defaultColorsSource.length - 1);
    // Set the first color of the chart
    returnColors.push(defaultColorsSource[startMod]);
    // Make an array with all colors except the first one
    const colorsWithoutFirst = [...defaultColorsSource];
    colorsWithoutFirst.splice(startMod, 1);

    // Go round and round the colorsWithoutFirst array and add the colors
    const lapLength = colorsWithoutFirst.length;
    for (let i = start; i < end - 1; i++) {
      const lapPositionIndex = i % lapLength;
      returnColors.push(colorsWithoutFirst[lapPositionIndex]);
    }
    return returnColors;
  }
};
