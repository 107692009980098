<nb-card class="card-thing" *ngIf="filterItem">
  <nb-card-header>
    <h5>
      <nb-checkbox [(checked)]="filterItem.useGrouping"> Use Grouping </nb-checkbox>
    </h5>
  </nb-card-header>
  <nb-card-body class="container-fluid cardbody">
    <div *ngIf="!filterItem.useGrouping" class="disabledDiv"></div>
    <div class="row">
      <nb-card class="col-sm subCard dark-card">
        <nb-card-header> Background on Grouping </nb-card-header>
        <nb-card-body>
          When using grouping rows will be grouped by a common value in the specified grouping column.
        </nb-card-body>
      </nb-card>
    </div>
    <div class="row">
      <nb-card class="col-sm subCard dark-card">
        <nb-card-header> Grouping Column </nb-card-header>
        <nb-card-body>
          <nb-select [(selected)]="filterItem.groupingColumn" class="dropdown">
            <nb-option *ngFor="let col of columns" [value]="col.name">
              {{ col.name }}
            </nb-option>
          </nb-select>
        </nb-card-body>
      </nb-card>

      <nb-card class="col-sm subCard dark-card">
        <nb-card-header> Grouping Type </nb-card-header>
        <nb-card-body>
          <nb-select [(selected)]="filterItem.groupingType" class="dropdown">
            <nb-option *ngFor="let type of groupingTypes" [value]="type.value">
              {{ type.label }}
            </nb-option>
          </nb-select>
        </nb-card-body>
      </nb-card>
    </div>

    <div class="row">
      <nb-card class="col-sm subCard dark-card">
        <nb-card-header> Description </nb-card-header>
        <nb-card-body>
          {{ descriptionDict[filterItem.groupingType] }}
        </nb-card-body>
      </nb-card>
    </div>
  </nb-card-body>
</nb-card>
