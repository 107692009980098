<nb-card class="component-container">
  <nb-card-header>
    <h6>{{ TitleText }}</h6>
  </nb-card-header>
  <nb-card-body>
    <p *ngIf="ContentText">{{ ContentText }}</p>
    <div class="button-container">
      <button nbButton [status]="ActionType === 'positive' ? 'basic' : 'primary'" (click)="Cancel()">
        {{ CancelText }}
      </button>
      <button nbButton [status]="ActionType === 'positive' ? 'primary' : 'basic'" (click)="Confirm()">
        {{ ConfirmText }}
      </button>
    </div>
  </nb-card-body>
</nb-card>
