import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, inject } from '@angular/core';
import { BaseComponent } from '@base-component';
import { IDataStreamColumn } from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { BehaviorSubject, takeUntil } from 'rxjs';
@Component({
  selector: 'resplendent-column-editor',
  templateUrl: './column-editor.component.html',
  styleUrls: ['./column-editor.component.scss'],
})
export class ColumnEditorComponent extends BaseComponent implements OnInit {
  @Input() public columnDetails: IDataStreamColumn[]; // all of the columns in the data stream
  @Input() public selectedColumns: string[]; // the columns that are currently selected
  @Input() public excludeMode: boolean = false; // whether the columns field excludes or includes the selected columns

  private selectedColumnsSource$ = new BehaviorSubject<string[]>([]);
  public selectedColumns$ = this.selectedColumnsSource$.asObservable();
  private excludeModeSource$ = new BehaviorSubject<boolean>(false);
  public excludeMode$ = this.excludeModeSource$.asObservable();

  public availableColumns: string[]; // the columns that are not currently selected
  public filteredAvailableColumns: string[]; // the columns that are not currently selected and match the search term
  public columnInfo: { [key: string]: IDataStreamColumn };
  value = '';
  private dialogRef = inject(NbDialogRef<ColumnEditorComponent>);

  ngOnInit(): void {
    this.selectedColumnsSource$.next(this.selectedColumns);
    this.excludeModeSource$.next(this.excludeMode ?? false);

    this.columnInfo = this.columnDetails.reduce((acc, col) => {
      acc[col.name] = col;
      return acc;
    }, {});
    this.columnDetails.sort((a, b) =>
      (a.alias || a.name) > (b.alias || b.name) ? 1 : -1,
    );
    this.availableColumns = this.columnDetails
      .map((col) => col.name)
      .filter((col) => !this.selectedColumns.includes(col));
    this.filterList();
    this.dialogRef.onBackdropClick
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.cancel());
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.filterList();
  }
  toggleAll(checked) {
    if (checked) {
      const selectedColumns = this.selectedColumnsSource$.value;
      for (const col of this.columnDetails) {
        if (!selectedColumns.includes(col.name)) {
          selectedColumns.push(col.name);
        }
      }
      this.selectedColumnsSource$.next(selectedColumns);
    } else {
      this.selectedColumnsSource$.next([]);
    }
    this.filterList();
  }
  filterList(value?: string) {
    if (value !== undefined) {
      this.value = value.toLowerCase();
    }
    this.availableColumns = this.columnDetails
      .map((col) => col.name)
      .filter((col) => !this.selectedColumnsSource$.value.includes(col));
    if (!this.value) {
      this.filteredAvailableColumns = [...this.availableColumns];
      return;
    }
    this.filteredAvailableColumns = this.availableColumns.filter((col) =>
      (this.columnInfo[col].alias || this.columnInfo[col].name)
        .toLowerCase()
        .includes(this.value),
    );
  }

  toggleMode(mode: boolean) {
    this.excludeModeSource$.next(mode);
    this.filterList();
  }

  saveAndClose() {
    this.dialogRef.close({
      columns: [...this.selectedColumnsSource$.value],
      excludeMode: this.excludeModeSource$.value,
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
