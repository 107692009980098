import { Component, Input, OnInit, inject } from '@angular/core';
import { IColumnInfo, IPandasFilterV2 } from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { defaultPandasFilterV2 } from '@root/custom_scripts/config';
import deepcopy from 'deepcopy';

@Component({
  selector: 'resplendent-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  dialogRef = inject(NbDialogRef<FilterDialogComponent>);
  @Input() title: string;
  @Input() filterInput: IPandasFilterV2;
  @Input() columns: IColumnInfo[];

  filter: IPandasFilterV2;
  ngOnInit(): void {
    if (this.filterInput) {
      this.filter = deepcopy(this.filterInput);
    }
  }
  addFilter() {
    this.filter = deepcopy(defaultPandasFilterV2);
  }
  close(discard: boolean) {
    if (discard) this.dialogRef.close(undefined);
    this.dialogRef.close(this.filter);
  }
  deleteFilter() {
    this.dialogRef.close(null);
  }
}
