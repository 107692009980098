import { Injectable } from '@angular/core';
import { calcStringDateToDate, downloadFromUrl } from '@helpers';
import { INotification, ISessionVariables } from '@interfaces';
import {
  NbGlobalPhysicalPosition,
  NbThemeService,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import { notificationRoutes } from '@root/custom_scripts/config';
import { AuthService } from '@services';
import { AppComponent } from 'app/app.component';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import {
  ItemsPerTier,
  ITierLimits,
  ThemeOption,
} from '../interface-registry/general.interface';
import { EventQueueService } from './event-queue.service';
import { WebsocketService } from './websocket.service';
import { Store } from '@ngxs/store';
import { App } from '@root/state/app.actions';

/*
  This service is for holding the state of the website, user information, and caching information from the backend
*/
@Injectable({
  providedIn: 'root',
})
export class StateService {
  private sessionVariablesSource$ = new BehaviorSubject<ISessionVariables>(
    this.resetState(),
  );
  public sessionVariables$ = this.sessionVariablesSource$.asObservable();

  private userPrefsInited = false;

  public get sessionVariables() {
    return this.sessionVariablesSource$.getValue();
  }

  constructor(
    private eventQueue: EventQueueService,
    private authService: AuthService,
    private websocketService: WebsocketService,
    private deviceDetector: DeviceDetectorService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private store: Store,
  ) {
    this.init();
  }

  private init() {
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
    console.log('StateService register shit');
    this.resetState();
    this.themeSetup();
    this.eventQueue.on('AUTHENTICATION_COMPLETE').subscribe((payload) => {
      this.setSessionVariables({
        ...this.sessionVariables,
        user: payload,
        isSharedDash: false,
      });
      if (payload.exp < Date.now() / 1000) {
        this.eventQueue.dispatch('FORCE_SIGN_OUT', 'Your token has expired');
        return;
      }
      if (payload.token_type === 'single_request_token') return;
      if (payload.is_manual_token_refresh && this.userPrefsInited) return;
      if (payload.is_reseller || payload.is_giga_admin)
        this.store.dispatch(new App.GetImpersonationOptions());
      this.loadUserPreferences();
      this.loadNotifications();
      this.userPrefsInited = true;
    });

    this.eventQueue.on('SHOW_TOAST').subscribe((opts) => {
      let { duration, status, position, message, title } = opts;
      if (!duration) duration = 5000;
      if (!status) status = 'basic';
      if (!position) position = NbGlobalPhysicalPosition.TOP_LEFT;

      const toastrConfig: Partial<NbToastrConfig> = {
        duration,
        status,
        position,
        limit: 5,
      };

      this.toastrService.show(message, title, toastrConfig);
    });

    this.eventQueue
      .on('CHANGE_IS_SHARED_DASH_FLAG')
      .subscribe((isSharedDash) => {
        this.sessionVariables.isSharedDash = isSharedDash;
      });

    this.eventQueue.on('SET_THEME').subscribe((theme) => this.setTheme(theme));
    this.eventQueue
      .on('DOWNLOAD_BIG_BOI_TABLE_V2')
      .pipe(tap((id) => this.downloadTableV2(id)))
      .subscribe();
  }

  private themeSetup() {
    let theme = localStorage.getItem('theme');
    if (!!theme) {
      this.sessionVariables.userPreferences.theme = theme;
      this.themeService.changeTheme(theme);
    }
    this.themeService.onThemeChange().subscribe((newTheme) => {
      localStorage.setItem('theme', newTheme.name);
    });
  }

  async getTableDownloadUrl(backendCalcId: string): Promise<string> {
    const { success, res } = await this.websocketService.asyncRequest(
      'GET_FULL_MODAL_DATA',
      backendCalcId,
    );
    if (success && res) {
      return res;
    }
    return null;
  }
  public async downloadTableV2(target: { calcId: string; label: string }) {
    const urlId = await this.getTableDownloadUrl(target.calcId);
    if (!urlId) return;
    const url = `${environment.backendUrl}/modal-data/${urlId}/${target.label}`;
    await downloadFromUrl(url, target.label + '.csv');
  }

  public getChristmasSpirit() {
    return localStorage.getItem('christmasSpirit');
  }

  public setChristmasSpirit(value: boolean) {
    return localStorage.setItem('christmasSpirit', value.toString());
  }

  public isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  public userTokenType() {
    return this.sessionVariables.user.token_type;
  }

  public isAdmin() {
    return !!this.sessionVariables.user.permissions.admin;
  }
  public isGigaAdmin() {
    return !!this.sessionVariables.user.is_giga_admin;
  }

  public setSessionVariables(sessionVariables: ISessionVariables) {
    this.authService.setSessionVars(sessionVariables);
    // Update the session variables observable
    this.sessionVariablesSource$.next(sessionVariables);
  }

  public getSessionVariables() {
    return this.sessionVariables;
  }

  private setTheme(theme: ThemeOption) {
    this.themeService.changeTheme(theme.value);
    this.websocketService.asyncRequest('SAVE_THEME', { theme: theme.value });
  }

  public resetState() {
    const sessionVariables = {
      user: { permissions: {} },
      editor_uuid: v4(),
      loadedPreferences: false,
      userPreferences: { avatar: environment.defaultAvatar, theme: 'default' },
      sideMenuOpen: false,
      RecentDashboards: [],
      notifications: [],
    } as ISessionVariables;
    if (this.sessionVariables$) {
      this.sessionVariablesSource$.next(sessionVariables);
    }
    return sessionVariables;
  }

  public saveRecentDashboardId(dashId: string) {
    const index = this.sessionVariables.RecentDashboards.indexOf(dashId);
    const hasAlready = index > -1;
    if (hasAlready) {
      this.sessionVariables.RecentDashboards.splice(index, 1);
    }
    this.sessionVariables.RecentDashboards.unshift(dashId);
    this.setSessionVariables(this.sessionVariables);
  }

  public isBigDevice() {
    return this.deviceDetector.isDesktop() || this.deviceDetector.isTablet();
  }
  async loadNotifications() {
    const { res, success } = await this.websocketService.asyncRequest(
      'GET_NOTIFICATIONS',
    );
    if (success) {
      this.setSessionVariables({
        ...this.sessionVariables,
        notifications: this.process_notifications(res),
      });
    }
    this.eventQueue.on('NEW_NOTIFICATIONS').subscribe((notifications) => {
      this.update_notifications(notifications.res);
    });
    this.eventQueue.on('NOTIFICATIONS_DELETED').subscribe((notifications) => {
      this.sessionVariables.notifications =
        this.sessionVariables.notifications.filter(
          (n) => !notifications.res.includes(n.id),
        );
    });
  }
  process_notifications(notifications: INotification[]) {
    notifications = notifications.map((notification) => {
      notification.timestamp = new Date(notification.last_updated + 'Z');
      notification.epoch_timestamp = notification.timestamp.getTime();
      if (
        notificationRoutes[notification.title] &&
        notificationRoutes[notification.title].param
      ) {
        notification.params = {
          [notificationRoutes[notification.title].param]:
            notification.related_id,
        };
      } else {
        notification.params = {};
      }
      return notification;
    });
    // Sort the notifications by last_updated
    notifications.sort((a, b) => {
      return b.last_updated > a.last_updated ? 1 : -1;
    });
    return notifications;
  }
  update_notifications(new_notifications: INotification[]) {
    new_notifications = this.process_notifications(new_notifications);
    let notifications = this.sessionVariables.notifications;
    let notifIndexMap = {};
    notifications.forEach((notification, index) => {
      notifIndexMap[notification.id] = index;
    });
    new_notifications.forEach((notification) => {
      if (notifIndexMap[notification.id] !== undefined) {
        notifications[notifIndexMap[notification.id]] = notification;
      } else {
        notifications.unshift(notification);
      }
    });
    // sort the notifications by last_updated
    notifications.sort((a, b) => {
      return b.last_updated > a.last_updated ? 1 : -1;
    });
    this.setSessionVariables({ ...this.sessionVariables, notifications });
  }
  public async loadUserPreferences() {
    let { res, success } = await this.websocketService.asyncRequest(
      'GET_USER_PREFERENCES',
    );
    if (success) {
      if (res.profile_picture !== null) {
        this.sessionVariables.userPreferences.avatar = res.profile_picture;
      }
      this.themeService.changeTheme(res.user_preferences.theme);
      this.sessionVariables.FavoriteDashboards =
        res.user_preferences.favorite_dashboards;
      this.sessionVariables.RecentDashboards =
        res.user_preferences.recent_dashboards;
      this.sessionVariables.loadedPreferences = true;
      this.eventQueue.dispatch('PREFERENCES_ARE_LOADED', null);
      this.setSessionVariables(this.sessionVariables);
    }
  }
  public checkPrefsForDeleted() {
    // remove deleted dashboards from the recent and favorite lists
    let deleted_dashboards = [];
    let deleted_indexes = [];
    const sessionVars = this.getSessionVariables();
    let favs = sessionVars.FavoriteDashboards;
    let recents = sessionVars.RecentDashboards;
    const dashIdList = sessionVars.dashboardIdList;
    for (let i in favs) {
      if (dashIdList.indexOf(favs[i]) == -1) {
        deleted_dashboards.push(favs[i]);
        deleted_indexes.unshift(i);
      }
    }
    for (let i of deleted_indexes) {
      sessionVars.FavoriteDashboards.splice(i, 1);
    }
    deleted_indexes = [];
    for (let i in recents) {
      if (dashIdList.indexOf(recents[i]) == -1) {
        deleted_dashboards.push(recents[i]);
        deleted_indexes.unshift(i);
      }
    }
    for (let i of deleted_indexes) {
      sessionVars.RecentDashboards.splice(i, 1);
    }
    // 5e0e415b-a54c-4b4e-9f62-e95c8b8b8385
    // this.websocketService.asyncRequest('REMOVE_DASHBOARD_FROM_PREFS', deleted_dashboards);
  }

  public removeRecentDash(dashId: string) {
    const sessionVars = this.sessionVariables;
    const recentDashboards = sessionVars.RecentDashboards;
    const exists = recentDashboards.some((id) => id === dashId);
    if (exists) {
      sessionVars.RecentDashboards = recentDashboards.filter(
        (id) => id !== dashId,
      );
      this.sessionVariablesSource$.next(sessionVars);
    }
  }

  public setRecentDashboards(
    newRecentDashboardsState: string[],
    newDashId: string,
  ) {
    const sessionVars = this.sessionVariables;
    const oldRecentDashboards = sessionVars.RecentDashboards;

    // We want to refresh the list only if a new item is added to the list
    // This prevents the recent dash list being shuffled while the user is using it for navigation
    const dashAlreadyRecent = oldRecentDashboards.some(
      (id) => id === newDashId,
    );
    if (!dashAlreadyRecent) {
      sessionVars.RecentDashboards = newRecentDashboardsState;
      this.sessionVariablesSource$.next(sessionVars);
    }
  }

  public setFeatureItemLimits(limits: ITierLimits) {
    const vars = this.sessionVariablesSource$.getValue();
    vars.tierLimits = limits;
    this.sessionVariablesSource$.next(vars);
  }

  public setTierInfo(limits: ItemsPerTier) {
    const vars = this.sessionVariablesSource$.getValue();
    vars.tierInfo = limits;
    this.sessionVariablesSource$.next(vars);
  }

  public isEnabled(id: string) {
    let item;
    const limits = this.sessionVariables.tierLimits;
    item = limits.items.find((x) => x.id === id);
    if (item === undefined) return false;
    return item;
  }

  public currentFeatureItemCount(type: 'dashboard' | 'widget' | 'user') {
    const currentItems = this.sessionVariables.tierLimits.items.filter(
      (x) => x.type === type,
    );
    return currentItems.length;
  }

  public featureSlotsLeft(type: 'dashboard' | 'widget' | 'user') {
    const info = this.sessionVariables.tierInfo;
    const tier = this.sessionVariables.user.tier;
    const limit = info[tier][type];
    const currentCount = this.currentFeatureItemCount(type);
    const diff = limit - currentCount;
    return diff > 0 ? diff : 0;
  }
}
