import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IForceDataTypeType } from '@interfaces';

@Component({
  selector: 'resplendent-force-datatype-dialog-v2',
  templateUrl: './force-datatype-dialog.component.html',
  styleUrls: ['./force-datatype-dialog.component.scss'],
})
export class ForceDatatypeV2DialogComponent {
  AvailableDatatypes = [
    { value: 'none', label: "None (Don't force)" },
    { value: 'float', label: 'Float (Number with decimal precision)' },
    { value: 'int', label: 'Integer (Whole number)' },
    { value: 'string', label: 'String (Text)' },
  ];
  constructor() {}
  @Input() value: IForceDataTypeType;

  @Output() valueChange = new EventEmitter<IForceDataTypeType>();
}
