<div
  #ComparisonContainer
  class="comparison-container"
  *ngIf="filterObject !== undefined"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <!-- main filter column -->
  <div *ngFor="let filterItem of filterObject.items; index as filterIndex" class="filter-container">
    <!-- and/or select -->
    <nb-select *ngIf="filterItem.index !== 0" placeholder="Union Type" [(selected)]="filterItem.logicOperator">
      <nb-option value="and">and</nb-option>
      <nb-option value="or">or</nb-option>
    </nb-select>
    <div
      class="parentheses-container"
      *ngIf="
        filterObject.parentheses.left_parentheses[filterItem.index] !== undefined &&
        filterObject.parentheses.left_parentheses[filterItem.index].length > 0
      "
    >
      <div
        *ngFor="let uuid of filterObject.parentheses.left_parentheses[filterItem.index]; index as i"
        class="parentheses"
        [style.background]="colorToParenthesesMap[uuid]"
        (mouseenter)="highlightRelatedParentheses(uuid, true)"
        (mouseleave)="highlightRelatedParentheses(uuid, false)"
      >
        <button
          nbButton
          ghost
          size="small"
          [disabled]="filterItem.index === 0"
          (click)="moveParentheses(filterItem, 'left', 'left_parentheses')"
          nbTooltip="Move parentheses up"
        >
          <nb-icon icon="arrow-up-outline"></nb-icon>
        </button>

        <button nbButton ghost size="small">
          <nb-icon (click)="deleteParentheses(uuid)" icon="close-outline"></nb-icon>
        </button>
        <button
          nbButton
          ghost
          size="small"
          [disabled]="filterItem.index === filterObject.items.length - 1"
          (click)="moveParentheses(filterItem, 'right', 'left_parentheses')"
          nbTooltip="Move parentheses down"
        >
          <nb-icon icon="arrow-down-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div class="filter-box" cdkDrag>
      <!-- filter item box -->
      <div
        class="data-filter"
        [style.outline]="'1px solid ' + filterItem.valid"
        *ngIf="filterItemBeingDragged !== filterItem && filterItem !== undefined"
      >
        <div class="options-row">
          <div class="options-button-cluster">
            <button nbButton status="basic" (click)="addParentheses(filterItem.index)" nbTooltip="Group Filters">
              <nb-icon icon="code"></nb-icon>
            </button>

            <button
              nbButton
              [status]="filterItem.useGrouping ? 'success' : 'basic'"
              (click)="openGroupingOptionsDialog(filterItem)"
              nbTooltip="Grouping Options"
            >
              <nb-icon icon="layers-outline"></nb-icon>
            </button>
            <button
              nbButton
              status="basic"
              (click)="openForceDatatypeDialog(filterItem)"
              [disabled]="filterItem.useAdvanced"
              nbTooltip="Force Datatype"
            >
              <nb-icon icon="text-outline"></nb-icon>
            </button>
            <button nbButton status="basic" (click)="copyFilterItem(filterItem)" nbTooltip="Copy Filter Item">
              <nb-icon icon="copy-outline"></nb-icon>
            </button>
          </div>
          <div class="options-button-cluster">
            <nb-icon class="move-icon" icon="move-outline" cdkDragHandle></nb-icon>
            <button
              nbButton
              ghost
              (click)="deleteComparison(filterItem.index)"
              [disabled]="filterObject.items.length === 1"
              status="danger"
            >
              <nb-icon icon="close-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div class="filter-drop-downs">
          <div class="label-and-select">
            <div *ngIf="columnsMap[filterItem.column] && columnsMap[filterItem.column]?.dataType; else noType">
              Column Type: <strong>{{ columnsMap[filterItem.column]?.dataType | titlecase }}</strong>
            </div>
            <ng-template #noType>
              <div>Column</div>
            </ng-template>
            <resplendent-column-autocomplete
              [columns]="columns"
              [(selectedColumn)]="filterItem.column"
              (selectedColumnChange)="validateFilter(); filterItem.value = ''; filterItem.useAdvanced = false"
            ></resplendent-column-autocomplete>
          </div>

          <div class="label-and-select">
            <nb-checkbox [(checked)]="filterItem.not_" (checkedChange)="validateFilter()"> Not </nb-checkbox>
            <nb-select
              *ngIf="
                !(
                  filterItem.useAdvanced === true &&
                  (AdvancedValuePreviews[filterItem.index] === 'NULL' ||
                    AdvancedValuePreviews[filterItem.index] === 'true' ||
                    AdvancedValuePreviews[filterItem.index] === 'false' ||
                    filterItem.advancedValueType === 'between_dates')
                );
                else nullOptions
              "
              [(selected)]="filterItem['comparison']"
              (selectedChange)="validateFilter()"
            >
              <nb-option *ngFor="let Operator of OperatorsList" [value]="Operator">
                {{ Operator }}
              </nb-option>
            </nb-select>

            <ng-template #nullOptions>
              <nb-select [(selected)]="filterItem['comparison']" (selectedChange)="validateFilter()">
                <nb-option value="=">=</nb-option>
              </nb-select>
            </ng-template>
          </div>
          <div class="label-and-select">
            <nb-checkbox
              [(checked)]="filterItem.useAdvanced"
              (click)="filterItem.useAdvanced ? null : openAdvancedValueDialog(filterItem)"
            >
              Use Advanced
            </nb-checkbox>
            <resplendent-value-autocomplete-v2
              *ngIf="!filterItem.useAdvanced; else advancedOptionsButton"
              [value]="filterItem.value"
              [column]="columnsMap[filterItem.column]"
              (valueChanges)="filterItem.value = $event; validateFilter()"
            ></resplendent-value-autocomplete-v2>

            <ng-template #advancedOptionsButton>
              <div (click)="openAdvancedValueDialog(filterItem)" class="advanced-value-div wrapperDiv">
                <input
                  nbInput
                  [type]="
                    (columnsMap[filterItem.column]?.dataType === 'float' ||
                      columnsMap[filterItem.column]?.dataType === 'int') &&
                    !filterItem.useAdvanced
                      ? 'number'
                      : 'text'
                  "
                  [value]="
                    AdvancedValuePreviews[filterItem.index] !== undefined ? AdvancedValuePreviews[filterItem.index] : ''
                  "
                  disabled
                />
                <div
                  class="overlayDiv"
                  *ngIf="
                    AdvancedValuePreviews[filterItem.index] !== undefined &&
                      AdvancedValuePreviews[filterItem.index].length > 28;
                    else noTooltip
                  "
                  [nbTooltip]="AdvancedValuePreviews[filterItem.index]"
                ></div>
                <ng-template #noTooltip>
                  <div class="overlayDiv"></div>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="parentheses-container"
      *ngIf="
        filterObject.parentheses.right_parentheses[filterItem.index] !== undefined &&
        filterObject.parentheses.right_parentheses[filterItem.index].length > 0
      "
    >
      <div
        *ngFor="let uuid of filterObject.parentheses.right_parentheses[filterItem.index].reverse(); index as i"
        class="parentheses"
        [style.background]="colorToParenthesesMap[uuid]"
        (mouseenter)="highlightRelatedParentheses(uuid, true)"
        (mouseleave)="highlightRelatedParentheses(uuid, false)"
      >
        <button
          nbButton
          ghost
          size="small"
          [disabled]="filterItem.index === 0"
          (click)="moveParentheses(filterItem, 'left', 'right_parentheses')"
          nbTooltip="Move parentheses up"
        >
          <nb-icon icon="arrow-up-outline"></nb-icon>
        </button>

        <button nbButton ghost size="small">
          <nb-icon (click)="deleteParentheses(uuid)" icon="close-outline"></nb-icon>
        </button>
        <button
          nbButton
          ghost
          size="small"
          [disabled]="filterItem.index === filterObject.items.length - 1"
          (click)="moveParentheses(filterItem, 'right', 'right_parentheses')"
          nbTooltip="Move parentheses down"
        >
          <nb-icon icon="arrow-down-outline"></nb-icon>
        </button>
      </div>
    </div>

    <!-- add filter item button -->
    <div class="add-button-container">
      <button *ngIf="filterItem.index === filterObject.items.length - 1" nbButton ghost (click)="addComparison()">
        <nb-icon icon="plus-outline"></nb-icon>
        Add Condition
      </button>
    </div>
  </div>
</div>
