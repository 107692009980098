    <nb-card class='text-card' (click)='closeDialog()'>
        
        <nb-card-body>
            <h4>Uh Oh! You're not connected :(</h4>
            <h6>We're trying to connect you right now!</h6>
            <p>If you aren't reconnected after a few seconds make sure that you're connected to the internet.</p>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </nb-card-body>
        
    </nb-card>
