import { IntegrationsType } from '@pages/data-management/components/integrations/components/add-integration/integration-fields';
import { ColDef } from 'ag-grid-community';
import { GridsterItem } from 'angular-gridster2';
import {
  IColumnLinks,
  IGetCalculationDataResponse,
  IGetFilterVariableStateResponse,
  IPandasFilterV2,
  ISimpleFilter,
  ITrendFreq,
} from './data-service.interface';
import { IBooleanMap, IStringMap } from './general.interface';
import { VirtualColumnBase } from './data-modifier.interface';

export type CalculationType = 'standard' | 'snapshot';
export type WidgetType =
  | 'bar'
  | 'pie'
  | 'line'
  | 'table'
  | 'doughnut'
  | 'number'
  | 'iFrame'
  | 'image'
  | 'staticString'
  | 'dynamicString'
  | 'matrix'
  | 'gauge';
export type DataStreamType = 'dataModifier' | 'table';
export type CalculationOperationType =
  | 'live'
  | 'sum'
  | 'average'
  | 'custom'
  | 'median'
  | 'max'
  | 'min'
  | 'percentage'
  | 'mode'
  | 'range';
export type SortingType =
  | 'default'
  | 'reverse'
  | 'ascending'
  | 'descending'
  | 'dowMonthAsc'
  | 'dowMonthDesc';
export type GroupingType =
  | 'none'
  | 'stackByLabel'
  | 'stackByCalculation'
  | 'groupByLabel'
  | 'groupByCalculation';
export type ColorType = 'colors' | 'thresholds';
export type CalcDataSource = 'rawCalcDataDict' | 'dataUpdate' | 'getCalcData';
export type DashDisplayState =
  | 'dashboard'
  | 'positionEditor'
  | 'widgetEditor'
  | 'filterViewsEditor'
  | 'editTitle'
  | 'dashConfig'
  | 'filterVariableConfig';
export type ColumnDataType =
  | 'string'
  | 'float'
  | 'datetime'
  | 'int'
  | 'timedelta'
  | 'date'
  | 'boolean'
  | 'bool';
export type StdCalcTimeIncrement =
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';
export type AppTheme = 'default' | 'dark' | 'cosmic';
export type CalcType = 'standard' | 'snapshot';
export type CalcDataType = 'number' | 'timedelta';
export interface IColumnValues {
  [key: string /** column name */]: string[] | boolean[] | number[];
}

export interface IWidgetConfigOption<T = undefined> {
  // For on/off options
  toggled: boolean;
  // For any more complex options
  value?: T;
}

export interface IGetDashboardV2Response {
  dashboard: IStorableDashV2;
  filterVariables: IGetFilterVariableStateResponse;
}
export interface IGetSharedDashboardV2Response extends IGetDashboardV2Response {
  theme: AppTheme;
  showModalData: boolean;
}
export interface IListDashboardsV2Response {
  dashboards: IStorableDashV2[];
}

export interface ICreateDashboardV2Response {
  id: string;
}

export interface IDeleteDashboardV2Response {
  id: string;
}

export interface ICopyDashboardV2Response {
  id: string;
}

export interface ICreateDashboardV2Request {
  title: string;
  description: string;
}

export interface ISaveDashPositionsRequestPayload {
  [widgetId: string]: GridsterItem;
}

export interface IAddFilterViewRequestPayload {
  filterView: IFilterView;
}

export interface IUpdateFilterViewRequestPayload {
  filterView: IFilterView;
}

export interface IDeleteFilterViewRequestPayload {
  filterViewId: string;
}

export interface ICreateWidgetRequestPayload {
  widget: IStorableWidgetV2;
  dashboardId: string;
}

export interface ICreateWidgetCopyRequestPayload {
  dashboard_id: string;
  widget_id: string;
  gridster_item: GridsterItem;
  new_group: boolean;
  title: string;
}

export interface IUpdateWidgetsPositionsRequestPayload {
  items: { [widgetId: string]: GridsterItem };
}

export interface IGetWidgetRequestPayload {
  widgetId: string;
}

export interface IGetWidgetResponse {
  widget: IStorableWidgetV2;
}

export interface IUpdateWidgetRequestPayload {
  widget: IStorableWidgetV2;
  dashboardId: string;
}

export interface IDeleteWidgetRequestPayload {
  widgetId: string;
}

export interface IRefreshCompanyDashboardRequest {
  id: string;
}
export interface IRefreshCompanyDashboardResponse {
  id: string;
}

/**
 * A threshold is a given value that the chart cares about, when enabled, if the value of the chart data
 * passes the threshold number, the chart bar/line/slice gets colored with the threshold color.
 * The indexList contains pointers that point to active thresholds in the IThresholds.custom array. If an index is
 * present then the IThreshold object at that index will be consumed, much like your mom consumes half the Thanksgiving table in one sitting :)
 */
export interface IThresholdsV2 {
  bottom: string;
  custom: IThresholdV2[];
  snooze: number; // in seconds
  allowMute: boolean;
}

export type thresholdSoundComparators = 'lte' | 'gte' | 'eq';
export interface IThresholdV2 {
  value: number;
  color: string;
  soundId: string;
  comparator: thresholdSoundComparators;
  repeatSound: boolean;
  repeatInterval: number;
}

export interface IWidgetConfig {
  id: string;
  widgetGroupId: string;
  xAxis: IWidgetConfigOption;
  yAxis: IWidgetConfigOption;
  legend: IWidgetConfigOption;
  legendPosition: 'top' | 'bottom' | 'left' | 'right';
  overlayCurrentNumber: boolean;
  dataLabels: IWidgetConfigOption;
  percentSuffix: IWidgetConfigOption;
  dollarPrefix: IWidgetConfigOption;
  abbreviateNumbers: IWidgetConfigOption;
  showHistoricalData: IWidgetConfigOption;
  yMax: IWidgetConfigOption<number>;
  yMin: IWidgetConfigOption<number>;
  xLabel: string | null;
  yLabel: string | null;
  fontSize: number;
  colorType: IWidgetConfigOption<ColorType>;
  sorting: IWidgetConfigOption<SortingType>;
  groupBySorting: SortingType;
  grouping: IWidgetConfigOption<GroupingType>;
  showArea: boolean;
  showThresholdMarkers: boolean;
  comparePoints: boolean;
  comparisonType: null | 'previous' | 'custom';
  comparisonCustomValue: null | number;
  comparisonMethod: null | 'percent' | 'difference';
  comparisonOnlyShowInLabel: boolean;
  stringValueSize: IWidgetConfigOption<number>;
  timeIncrement: IWidgetConfigOption<StdCalcTimeIncrement>;
  dailySnapshotTimeCutoff: string;
  timezone: string;
  rounding: number | null;
  showWidgetTitle: boolean;
  modalDataSortColumn: string;
  modalDataSortAscending: boolean;
  cumulativeXAxis: boolean;
  xAxisBaseline: string;
  xAxisLabelRotation: number;
  pieRadius: number;
  pieInnerRadius: number;
  maxGaugeValue: number;
  minGaugeValue: number;
  yAxisLogScale: boolean;
  logScaleBase: number;
  calculationOrder: string[];
  valueLimit: number | null;
  barGap: number;
  hideZeroLabels: boolean;
  showStackTotal: boolean;
  pinnedColumns: string[];
  matrixMissingValue: string;
}
export type IEzConfigMap = {
  [key in keyof IWidgetConfig]: boolean;
};
export interface IDashboardConfig {
  currently: 'nothing';
}
export interface IFilterView extends IFilterViewDetails {
  filterViewItems: IFilterViewItem[];
}

export interface IFilterViewDetails {
  id: string;
  dashboardId: string;
  label: string;
  description: string;
}
export interface IFilterViewItem {
  id: string;
  filterViewId: string;
  tableId: string;
  dataModifierId: string;
  label: string;
  description: string;
  filter: IFilter;
  widgetsAndChildFilters: IFilterViewItemWidgetRelationship[];
}

export interface IFilterViewItemWidgetRelationship {
  id: number;
  widgetId: string;
  filterViewItemId: string;
  childFilters: IChildFilterV2[];
}

export interface IFilterViewState extends IFilterView {
  isEditing: boolean;
  isLoading: boolean;
  activeFilterViewItem: IFilterViewItem | null;
}

export interface IFetchFilterViewsResponse {
  filterViews: IFilterView[];
}

export interface IChildFilterV2 {
  id: string;
  customerUuid: string;
  fkCalculationUuid: string;
  fkFilterId: string;
  fkFviwcRelationshipId: number;
}

export interface IFilter {
  id?: string;
  filterViewItemId?: string;
  advancedFilter: boolean;
  pandasFilter?: IPandasFilterV2;
  simpleFilter?: ISimpleFilter;
  childFilters?: IChildFilterV2[];
}

export interface ICreateFilterViewRequestPayload {
  dashboardId: string;
  filterView?: IFilterView;
}

export interface IStorableDashV2 {
  id: string;
  title: string;
  description: string;
  featureItemEnabled: string;
  activeFilterViewId: string | null;
  userFilterViewState: {
    [userId: string]: string;
  } | null;
  lastEdited: number;
  lastViewed: number;
  createdBy: string;
  lastEditedBy: string;
  maxFontSize: number;
  customLabelsAndColors: ICustomLabelAndColor | null;
  widgetTitleFontSize: number;
  dashboardGroups: string[];
  dashboardFolder: string | null;
  filterViews?: { [filterViewId: string]: IFilterView };
  widgets?: IStorableWidgetV2[];
  version: number;
}

export interface ICustomLabelAndColor {
  [labelName: string]: string; // The key is the label name, the value is the color
}
export interface IStorableWidgetV2 {
  id: string;
  widgetType: WidgetType;
  gridsterItem: GridsterItem;
  title: string;
  description: string;
  groupId: string; // The pointer to all linked copies of this widget
  config: IWidgetConfig;
  iFrameUrl?: string;
  imageUrl?: string;
  staticString?: string;
  dynamicStringColumn?: string;
  thresholds: IThresholdsV2;
  colors: IWidgetColors;
  calculations: ICalculationV2Map;
  allowedCalculationsType: CalculationType | 'none';
  dashboardV2Id: string;
  featureItemEnabled: boolean;
}

export interface IWidgetColors {
  [calculationId: string]: ICalculationColor;
}

export interface ICalculationColor {
  color: string;
  useDefault: boolean;
  secondAxisColors?: {
    [category: string]: string;
  };
}

export interface IWidgetColorMap {
  // Each calculation either has a single color, or a color for each item in the calculation
  // depending on the context
  [calcId: string]: IStringMap | string;
}

/** Dumb color map stores only the first color of dictionary calcs as a string */
export interface IWidgetColorMapDumb {
  [calcId: string]: string;
}

export type WidgetStep = 'widgetType' | 'calculationsType' | 'config';

export interface IStorableWidgetMap {
  [widgetId: string]: IStorableWidgetV2;
}

export interface IDataStream {
  id: string;
  type: DataStreamType;
  title?: string;
  columnDetails?: IDataStreamColumn[];
  // Unpacked values from columnDetails. The lists are sorted the same
  columnNames?: string[];
  columnDataTypes?: IStringMap;
  columnValues?: IColumnValues;
  tableUserLabel?: string; // The user label of the table this data stream, which is the same as the title if it's a table
  ///// REQUIRED FOR TABLES /////
  tableName?: string;
  tableUuid?: string;
  sourceUserLabel?: string;
  engine?: IntegrationsType;
  engineTitle?: string;
  sourceUuid?: string;
  ///// END REQUIRED FOR TABLES /////
}
export interface IDataStreamMap {
  [id: string]: IDataStream;
}

export interface IDataStreamColumn {
  name: string;
  dataType: ColumnDataType;
  possibleValues?: string[];
  alias?: string;
}
export interface IDataStreamColumnMap {
  [dataStreamId: string]: IDataStreamColumn[];
}

export interface IDataStreamConfig {
  id: string;
  type: 'table' | 'dataModifier';
  title: string;
  xAxis: string;
  yAxis: string;
  groupBy: string;
  filter: null; // Add later on
  columnsSelected: IBooleanMap;
}

export interface IGetCalculationPreviewsRequest {
  calculations: ICalculationV2[];
  widgetConfig: IWidgetConfig;
}
export interface IGetCalculationPreviewsResponse {
  results: {
    [calculationId: string]: IGetCalculationDataResponse;
  };
}

export interface IAddFVIWCRequest {
  fviwcRelationship: IFilterViewItemWidgetRelationship;
}

export interface IAddFVIWCResponse {
  fviwcRelationship: IFilterViewItemWidgetRelationship;
  previewData: { [calculationId: string]: IGetCalculationDataResponse };
}

export interface IDeleteFVIWCRequest {
  fviwcRelationshipId: number;
}

export interface IDeleteFVIWCResponse {
  fviwcRelationshipId: number;
}

export interface ICalculationV2 {
  id: string;
  dataStreamId: string;
  dataStreamType: DataStreamType;
  widgetGroupId: string;
  name: string;
  calculationType: string;
  calculationOperation: CalculationOperationType;
  calculationColumn?: string;
  useFilter: boolean;
  filter: IFilter;
  includedColumns: string[];
  excludeColumnsMode: boolean;
  percentFilter: IPandasFilterV2 | null;

  // standard only
  xAxis?: string;
  xAxis2?: string;
  delimiter2?: string;
  // groupingCols?: string[];

  // snapshot only
  trendFreq?: ITrendFreq;
  delimiterEnabled?: boolean;
  delimiter?: string;
  groupBy?: string;
  dataModifier?: IDataModifier;
  clientTable?: IClientTable;
  useCustomCalculationColumn?: boolean;
  customCalculationColumn?: VirtualColumnBase;
}

export interface IDataModifier {
  customerUuid: string;
  dataUuid: string;
  tableUuid: string;
  datasetName: string;
  broken: boolean;
  defaultTableFilter: boolean;
  calculationTemplates?: ICalculationV2[];
  /** Large object, not really used on front end, don't wanna build interfaces */
  dataFilter: any;
  columnInfo?: IColumnInfo[];
}

export interface IClientTable {
  pkTableUuid: string;
  tableName?: string;
  userLabel?: string;
  tableInfo?: string;
  fkSourceUuid: string;
  syncStatus?: number;
  customerUuid: string;
  crawlerStep?: number;
  crawlerStepInfo?: string;
  parent?: string;
  columnInfo?: IColumnInfo[];
}

export interface IColumnInfo {
  alias?: string;
  name: string;
  dataType: ColumnDataType;
  possibleValues?: string[];
}

export interface ICalculationV2Map {
  [calcId: string]: ICalculationV2;
}

export interface ICalculationOperation {
  label: string;
  val: CalculationOperationType;
  message: string;
  requiresComputationColumn: boolean;
}

export type IGeneralMap<K extends string | number | symbol, V> = {
  [key in K]: V;
};

export interface IGetDataStreams {
  widgetIds?: string[];
  dataStreamIds?: string[];
}

export interface IWidgetClickTargetV2 {
  // Idk, moved it here, will definitely be used in the future
  widgetId: string;
  forceWithEditorOpen?: boolean;
  calcTarget?: {
    label: string;
    frontEndCalcId: string;
  };
  xValue?: string | number;
}

export interface ICalculationStatusV2 {
  id: string;
  calcDataId: string;
  parentDataId: string;
  latestDataUpdate: number;
  isFilterData: boolean;
  isDead: boolean;
  title: string;
  updateInterval: number;
  updateIntervalTooltip: string;
  color: StatusDotColor;
}

export interface ITableModalDataV2 {
  dataTitles: string[];
  data: IStringMap[];
  delimiter: string;
  agColumnDefs: ColDef[];
  columnNameMap?: IStringMap;
  subtitle: string;
  color?: string;
  links?: IColumnLinks;
  modifierLinks?: IColumnLinks;
  stack: number;
  segregationColumn?: string;
  colorMap?: { [columnName: string]: string };
  isSplitStack?: boolean;
  calcId: string;
  xAxisDataType: ColumnDataType;
  isFirstCategory?: boolean;
}

export interface IAutoResizeString {
  textColor: string;
  text: string;
  fontSize: number;
  containerHeight?: number;
}

export interface IDashboardInfo {
  title: string;
  description: string;
  id: string;
  dashboardFolder?: string;
  dashboardGroupNames?: string[];
  createdBy: string;
}

export enum StatusDotColor {
  green = '#6FC896',
  orange = '#FFAA02',
  red = '#FF3D71',
  // 'green' | 'orange' | 'red'
}
