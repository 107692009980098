import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import { IDataStreamColumn } from '@interfaces';
import {
  BehaviorSubject,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';

@Component({
  selector: 'resplendent-value-autocomplete-v2',
  templateUrl: './value-autocomplete.component.html',
  styleUrls: ['./value-autocomplete.component.scss'],
})
export class ValueAutocompleteV2Component
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() value: string;
  @Input() column: IDataStreamColumn;
  @Output() valueChanges = new EventEmitter<string>();

  filteredOptions: string[] = [];
  filteredControlOptionsSource$ = new BehaviorSubject<string[]>([]);
  filteredControlOptions$: Observable<string[]> =
    this.filteredControlOptionsSource$.asObservable();
  inputFormControl: FormControl = new FormControl('');
  setInitialValue = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.inputFormControl.setValue(this.value);

    this.filteredOptions = this.column?.possibleValues || [];
    this.filteredControlOptionsSource$.next(this.filteredOptions);
    this.subscribeToInputChanges();
  }

  private filter(value: string): string[] {
    if (!value) {
      return this.filteredOptions;
    }

    if (!this.filteredOptions) {
      return [];
    }
    const filterValue = value.toString().toLowerCase();
    const filtered = (this.filteredOptions as string[]).filter(
      (optionValue) => {
        if (optionValue === null) {
          return false;
        }
        return optionValue.toString().toLowerCase().includes(filterValue);
      },
    );
    return filtered as string[];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.column &&
      changes.column.currentValue !== changes.column.previousValue
    ) {
      this.setInitialValue = true;
      this.filteredOptions = this.column.possibleValues;
      this.filteredControlOptionsSource$.next(this.filteredOptions);
      if (this.inputFormControl.value) {
        this.inputFormControl.setValue(this.inputFormControl.value);
      }
    }
  }

  private subscribeToInputChanges() {
    this.inputFormControl.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        startWith(this.value),
        map((value) => (value === null ? '' : value)),
        tap((value) => {
          if (this.setInitialValue) {
            this.setInitialValue = false;
            return;
          }
          this.filteredControlOptionsSource$.next(this.filter(value));
        }),
        debounceTime(300),
        tap((value) => {
          this.valueChanges.emit(value.toString());
        }),
      )
      .subscribe();
  }
}
