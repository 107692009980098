<nb-card class="datatype-card">
  <nb-card-header>
    <h5>Force a Datatype</h5>
  </nb-card-header>
  <nb-card-body>
    <nb-select [(selected)]="value" fullWidth (selectedChange)="valueChange.emit($event)">
      <nb-option *ngFor="let dtype of AvailableDatatypes" [value]="dtype.value">
        {{ dtype.label }}
      </nb-option>
    </nb-select>
    <p></p>
  </nb-card-body>
</nb-card>
