import { Component, Input } from '@angular/core';
import { IDataStreamColumn, IPandasFilterItemV2 } from '@interfaces';

@Component({
  selector: 'resplendent-count-grouping-dialog-v2',
  templateUrl: './count-grouping-dialog.component.html',
  styleUrls: ['./count-grouping-dialog.component.scss'],
})
export class CountGroupingV2DialogComponent {
  groupingTypes = [
    { value: 'contains', label: 'if the group contains' },
    { value: 'not_contains', label: "if the group doesn't contain" },
  ];

  descriptionDict = {
    contains:
      'if a group contains at least 1 row that fulfills the comparison then this comparison will include all rows in the group, otherwise it will exclude all rows in the group',
    not_contains:
      'if a group does not contain any rows that fulfill the comparison then this comparison will include all rows in the group, otherwise it will exclude all rows in the group',
  };

  constructor() {}
  @Input() filterItem: IPandasFilterItemV2;
  @Input() columns: IDataStreamColumn[];

  numberEntryFormat(string, allowDecimal = false) {
    //this function is used for a bunch of the number entries for custom validation (modified for no negative values)
    let alreadyAllowedDecimal = false;
    let charList = string.split('');
    let indexToDeleteList = [];
    for (let charIndex in charList) {
      if (isNaN(charList[charIndex])) {
        if (allowDecimal && charList[charIndex] == '.' && !alreadyAllowedDecimal) {
          alreadyAllowedDecimal = true;
        } else {
          indexToDeleteList.push(charIndex);
        }
      }
    }
    indexToDeleteList.reverse();
    for (let index of indexToDeleteList) {
      charList.splice(index, 1);
    }
    return charList.join('');
  }
}
