import {
  DashDisplayState,
  DataStreamType,
  IAddFVIWCResponse,
  ICalculationDataUpdate,
  ICreateWidgetRequestPayload,
  IDataStreamColumnMap,
  IDataStreamMap,
  IFilterView,
  IFilterViewItem,
  IFilterViewItemWidgetRelationship,
  IGetCalculationDataResponse,
  IGetCalculationPreviewsResponse,
  IGetDashboardV2Response,
  IGetSharedDashboardV2Response,
  IGetWidgetResponse,
  IIntegrationInfo,
  IListOfItemsForDataStreamDeletion,
  IStorableWidgetV2,
  IWidgetHighlightMap,
  IDashV2State,
  IBaseTemplate,
  IUseTemplateRequest,
  ITag,
  ISoundResponse,
  IFilterVariable,
  IFilterVariableStateMap,
  IFilterVariableBackendState,
  IPublicLink,
  IGetFilterVariableStateResponse,
  IDashboardGroup,
  IDashboardFolder,
} from '@interfaces';
import { NbDialogService } from '@nebular/theme';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';

export namespace App {
  export class FetchDataStreams {
    static readonly type = '[App] Async Fetch Data Streams';
    constructor(
      public fetchColumnInfo: boolean,
      public widgetIds?: string[],
      public refreshAllDataStreams: boolean = false,
      public loadPossibleValues: boolean = false,
      public shareToken?: string,
    ) {}
  }

  export class FetchDataStreamsSuccess {
    static readonly type = '[App] Fetch Data Streams Success';
    constructor(
      public fetchColumnInfo: boolean,
      public dataStreams: IDataStreamMap,
      public refreshAllDataStreams: boolean,
      public loadPossibleValues: boolean = false,
      public shareToken?: string,
    ) {}
  }

  export class FetchDataStreamsFail {
    static readonly type = '[App] Fetch Data Streams Fail';
    constructor(public error: Error) {}
  }
  export class GetFullModalData {
    static readonly type = '[App] Async Fetch full modal data';
    constructor(public calcId: string, public label: string) {}
  }

  export class GetFullModalDataSuccess {
    static readonly type = '[App] Fetch full modal data Success';
    constructor(public calcId: string, public data: Record<string, any>[]) {}
  }

  export class GetFullModalDataFail {
    static readonly type = '[App] Fetch full modal data Fail';
    constructor(public error: Error) {}
  }

  export class FetchDataStreamColumns {
    static readonly type = '[App] Async Fetch Data Stream Columns';
    constructor(
      public dataStreamIds: string[],
      public loadPossibleValues: boolean,
      public shareToken?: string,
    ) {}
  }

  export class FetchDataStreamColumnsSuccess {
    static readonly type = '[App] Fetch Data Stream Columns Success';
    constructor(
      public response: IDataStreamColumnMap,
      public loadPossibleValues: boolean,
    ) {}
  }

  export class FetchDataStreamColumnsFail {
    static readonly type = '[App] Fetch Data Stream Columns Fail';
    constructor(public error: Error) {}
  }

  export class FetchDataSources {
    static readonly type = '[App] Async Fetch Data Sources';
    constructor() {}
  }

  export class FetchDataSourcesSuccess {
    static readonly type = '[App] Fetch Data Sources Success';
    constructor(public dataSources: IIntegrationInfo[]) {}
  }

  export class FetchDataSourcesFail {
    static readonly type = '[App] Fetch Data Sources Fail';
    constructor(public error: Error) {}
  }

  export class DeleteDataStream {
    static readonly type = '[App] Async Delete Data Stream';
    constructor(
      public dataStreamId: string,
      public dataStreamType: DataStreamType,
    ) {}
  }

  export class DeleteDataStreamSuccess {
    static readonly type = '[App] Delete Data Stream Success';
    constructor(public dataStreamId: string) {}
  }

  export class DeleteDataStreamFail {
    static readonly type = '[App] Delete Data Stream Fail';
    constructor(public error: Error) {}
  }

  export class CopyDataStream {
    static readonly type = '[App] Async Copy Data Stream';
    constructor(
      public dataStreamId: string,
      public dataStreamType: DataStreamType,
    ) {}
  }

  export class CopyDataStreamSuccess {
    static readonly type = '[App] Copy Data Stream Success';
    constructor(public dataStreamId: string, public dataStreamType: string) {}
  }
  export class CopyDataStreamFail {
    static readonly type = '[App] Copy Data Stream Fail';
    constructor(public error: Error) {}
  }

  export class ResyncDataStream {
    static readonly type = '[App] Async Resync Data Stream';
    constructor(public dataStreamId: string) {}
  }

  export class ResyncDataStreamSuccess {
    static readonly type = '[App] Resync Data Stream Success';
    constructor(public dataStreamId: string) {}
  }

  export class ResyncDataStreamFail {
    static readonly type = '[App] Resync Data Stream Fail';
    constructor(public error: Error) {}
  }

  export class FetchListOfItemsForDataStreamDeletion {
    static readonly type =
      '[App] Async Fetch List Of Items For Data Stream Deletion';
    constructor(
      public dataStreamId: string,
      public dataStreamType: DataStreamType,
    ) {}
  }

  export class FetchListOfItemsForDataStreamDeletionSuccess {
    static readonly type =
      '[App] Fetch List Of Items For Data Stream Deletion Success';
    constructor(
      public dataStreamId: string,
      public dataStreamType: DataStreamType,
      public items: IListOfItemsForDataStreamDeletion,
    ) {}
  }

  export class FetchListOfItemsForDataStreamDeletionFail {
    static readonly type =
      '[App] Fetch List Of Items For Data Stream Deletion Fail';
    constructor(public error: Error) {}
  }

  export class SaveRecentDashboard {
    static readonly type = '[App] Async Save Recent Dashboard';
    constructor(public dashId?: string) {}
  }

  export class SaveRecentDashboardSuccess {
    static readonly type = '[App] Save Recent Dashboard Success';
    constructor(
      public recentDashboards: string[],
      public addedDashId: string,
    ) {}
  }

  export class SaveRecentDashboardFail {
    static readonly type = '[App] Save Recent Dashboard Fail';
    constructor(public error: Error) {}
  }

  export class RefreshUserPreferences {
    static readonly type = '[App] Async Refresh User Preferences';
  }

  export class ResetState {
    static readonly type = '[App] Reset State';
  }

  export class GetTemplates {
    static readonly type = '[App] Async Fetch List Of Templates';
    constructor() {}
  }

  export class GetTemplatesSuccess {
    static readonly type = '[App] Fetch List Of Templates Success';
    constructor(public templates: IBaseTemplate[]) {}
  }

  export class GetTemplatesFail {
    static readonly type = '[App] Fetch List Of Templates Fail';
    constructor(public error: Error) {}
  }
  export class UseTemplate {
    static readonly type = '[App] Async Use a Template';
    constructor(public request: IUseTemplateRequest) {}
  }

  export class UseTemplateSuccess {
    static readonly type = '[App] Use a Template Success';
    constructor(public id: string) {}
  }

  export class UseTemplateFail {
    static readonly type = '[App] Use a Template Fail';
    constructor(public error: Error) {}
  }

  export class GetTagOptions {
    static readonly type = '[App] Async Get Tags';
    constructor() {}
  }

  export class GetTagOptionsSuccess {
    static readonly type = '[App] Get Tags Success';
    constructor(public tags: ITag[]) {}
  }

  export class GetTagOptionsFail {
    static readonly type = '[App] Get Tags Fail';
    constructor(public error: Error) {}
  }

  export class DeleteTag {
    static readonly type = '[App] Async Delete Tag';
    constructor(public id: string) {}
  }

  export class DeleteTagSuccess {
    static readonly type = '[App] Delete Tag Success';
    constructor(public id: string) {}
  }

  export class DeleteTagFail {
    static readonly type = '[App] Delete Tag Fail';
    constructor(public error: Error) {}
  }

  export class GetSoundboard {
    static readonly type = '[App] Async Get Soundboard';
    constructor() {}
  }

  export class GetSoundboardSuccess {
    static readonly type = '[App] Get Soundboard Success';
    constructor(public sounds: ISoundResponse[]) {}
  }

  export class GetSoundboardFail {
    static readonly type = '[App] Get Soundboard Fail';
    constructor(public error: Error) {}
  }

  export class DeleteSound {
    static readonly type = '[App] Async Delete Sound';
    constructor(public id: string) {}
  }

  export class DeleteSoundSuccess {
    static readonly type = '[App] Delete Sound Success';
    constructor(public id: string) {}
  }

  export class DeleteSoundFail {
    static readonly type = '[App] Delete Sound Fail';
    constructor(public error: Error) {}
  }

  export class EnableFullScreen {
    static readonly type = '[App] Enable Full Screen';
    constructor() {}
  }

  export class DisableFullScreen {
    static readonly type = '[App] Disable Full Screen';
    constructor() {}
  }
  export class SetImpersonationCustomerId {
    static readonly type = '[App] Async Set Impersonation Customer Id';
    constructor(public id: string, public refresh: boolean) {}
  }
  export class GetImpersonationOptions {
    static readonly type = '[App] Async Get Impersonation Options';
    constructor() {}
  }

  export class GetImpersonationOptionsSuccess {
    static readonly type = '[App] Get Impersonation Options Success';
    constructor(public options: { id: string; name: string }[]) {}
  }

  export class GetImpersonationOptionsFail {
    static readonly type = '[App] Get Impersonation Options Fail';
    constructor(public error: Error) {}
  }
}

export namespace Dash {
  export class FetchDash {
    static readonly type = '[Dash] Async Fetch From Backend';
    constructor(public id: string) {}
  }

  export class FetchDashSuccess {
    static readonly type = '[Dash] Fetch From Backend Success';
    constructor(public res: IGetDashboardV2Response) {}
  }

  export class FetchDashFail {
    static readonly type = '[Dash] Fetch From Backend Fail';
    constructor(public error: any) {}
  }

  export class FetchSharedDash {
    static readonly type = '[Dash] Async Fetch Shared Dash';
    constructor(public token: string, public password?: string) {}
  }

  export class FetchSharedDashSuccess {
    static readonly type = '[Dash] Fetch Shared Dash Success';
    constructor(
      public res: IGetSharedDashboardV2Response,
      public token: string,
    ) {}
  }

  export class FetchSharedDashFail {
    static readonly type = '[Dash] Fetch Shared Dash Fail';
    constructor(public error: any) {}
  }

  export class CreateNewDash {
    static readonly type = '[Dash] Async Create New Dash';
    constructor(public title: string, public description: string) {}
  }

  export class CreateNewDashSuccess {
    static readonly type = '[Dash] Create New Dash Success';
    constructor(public dashId: string) {}
  }

  export class CreateNewDashFail {
    static readonly type = '[Dash] Create New Dash Fail';
    constructor(public error: Error) {}
  }

  export class DeleteDash {
    static readonly type = '[Dash] Async Delete Dash';
    constructor(public id: string) {}
  }

  export class DeleteDashSuccess {
    static readonly type = '[Dash] Delete Dash Success';
    constructor(public dashId: string) {}
  }

  export class DeleteDashFail {
    static readonly type = '[Dash] Delete Dash Fail';
    constructor(public error: Error) {}
  }

  export class CopyDash {
    static readonly type = '[Dash] Async Copy Dash';
    constructor(public dashId: string) {}
  }

  export class CopyDashSuccess {
    static readonly type = '[Dash] Copy Dash Success';
    constructor(public dashId: string) {}
  }

  export class CopyDashFail {
    static readonly type = '[Dash] Copy Dash Fail';
    constructor(public error: Error) {}
  }

  export class FetchDashboards {
    static readonly type = '[Dash] Async Fetch Dashboards';
  }

  export class SavePositions {
    static readonly type = '[Dash] Async Save Positions';
  }

  export class SavePositionsSuccess {
    static readonly type = '[Dash] Save Positions Success';
  }

  export class SavePositionsFail {
    static readonly type = '[Dash] Save Positions Fail';
    constructor(public error: any) {}
  }

  export class ReloadCurrentDash {
    static readonly type = '[Dash] Async Reload Current Dash';
  }

  export class ChangeDisplayState {
    static readonly type = '[Dash] Change Display State';
    constructor(public displayState: DashDisplayState) {}
  }

  export class StoreGridsterConfig {
    static readonly type = '[Dash] Store Gridster Config';
    constructor(public config: GridsterConfig) {}
  }

  export class ClearCurrentDash {
    static readonly type = '[Dash] Clear Current Dash';
  }

  export class RequestCompanyDashRefresh {
    static readonly type = '[Dash] Request Company Dash Refresh';
    constructor() {}
  }

  export class RequestCompanyDashRefreshSuccess {
    static readonly type = '[Dash] Request Company Dash Refresh Success';
    constructor(public id: string) {}
  }

  export class RequestCompanyDashRefreshFail {
    static readonly type = '[Dash] Request Company Dash Refresh Fail';
    constructor(public error: Error) {}
  }

  export class InitializeDashboardWidgetData {
    static readonly type = '[Dash] Initialize Dashboard Widget Data';
    constructor() {}
  }

  /**
   * @description This action is used to reinitialize the dashboard widgets.
   * Currently, this is used when the user change the dashboard filter view
   * using the filter view dropdown. That action is SwitchFilterView.
   */
  export class ReinitializeDashboardWidgets {
    static readonly type = '[Widget] Reinitialize Dashboard Widgets';
    constructor() {}
  }

  export class SetDashSettingsState {
    static readonly type = '[Dash] Set Dash Settings State';
    constructor(public dashSettingsState: Partial<IDashV2State>) {}
  }

  export class SettingsSave {
    static readonly type = '[Dash] Settings Save';
    constructor() {}
  }

  export class SettingsSaveSuccess {
    static readonly type = '[Dash] Settings Save Success';
    constructor() {}
  }

  export class SettingsSaveFail {
    static readonly type = '[Dash] Settings Save Fail';
    constructor(public error: Error) {}
  }

  export class GetDashboardGroupOptions {
    static readonly type = '[Dash] GetDashboardGroupOptions';
    constructor() {}
  }

  export class GetDashboardGroupOptionsSuccess {
    static readonly type = '[Dash] GetDashboardGroupOptions Success';
    constructor(public dashboardGroups: IDashboardGroup[]) {}
  }

  export class GetDashboardGroupOptionsFail {
    static readonly type = '[Dash] GetDashboardGroupOptions Fail';
    constructor(public error: Error) {}
  }

  export class CreateOrUpdateDashboardGroup {
    static readonly type = '[Dash] CreateOrUpdateDashboardGroup';
    constructor(public dashboard_group: IDashboardGroup) {}
  }

  export class CreateOrUpdateDashboardGroupSuccess {
    static readonly type = '[Dash] CreateOrUpdateDashboardGroup Success';
    constructor() {}
  }

  export class CreateOrUpdateDashboardGroupFail {
    static readonly type = '[Dash] CreateOrUpdateDashboardGroup Fail';
    constructor(public error: Error) {}
  }

  export class DeleteDashboardGroup {
    static readonly type = '[Dash] DeleteDashboardGroup';
    constructor(public group_id: string) {}
  }

  export class DeleteDashboardGroupSuccess {
    static readonly type = '[Dash] DeleteDashboardGroup Success';
    constructor() {}
  }

  export class DeleteDashboardGroupFail {
    static readonly type = '[Dash] DeleteDashboardGroup Fail';
    constructor(public error: Error) {}
  }

  export class GetDashboardFolders {
    static readonly type = '[Dash] GetDashboardFolders';
    constructor() {}
  }

  export class GetDashboardFoldersSuccess {
    static readonly type = '[Dash] GetDashboardFolders Success';
    constructor(public dashboardFolders: IDashboardFolder[]) {}
  }

  export class GetDashboardFoldersFail {
    static readonly type = '[Dash] GetDashboardFolders Fail';
    constructor(public error: Error) {}
  }

  export class CreateOrUpdateDashboardFolder {
    static readonly type = '[Dash] CreateOrUpdateDashboardFolder';
    constructor(public dashboardFolder: IDashboardFolder) {}
  }

  export class CreateOrUpdateDashboardFolderSuccess {
    static readonly type = '[Dash] CreateOrUpdateDashboardFolder Success';
    constructor(public dashboardFolder: IDashboardFolder) {}
  }

  export class CreateOrUpdateDashboardFolderFail {
    static readonly type = '[Dash] CreateOrUpdateDashboardFolder Fail';
    constructor(public error: Error) {}
  }

  export class DeleteDashboardFolder {
    static readonly type = '[Dash] DeleteDashboardFolder';
    constructor(public folderId: string) {}
  }

  export class DeleteDashboardFolderSuccess {
    static readonly type = '[Dash] DeleteDashboardFolder Success';
    constructor(public folderId: string) {}
  }

  export class DeleteDashboardFolderFail {
    static readonly type = '[Dash] DeleteDashboardFolder Fail';
    constructor(public error: Error) {}
  }
}

export namespace FilterViews {
  export class SwitchFilterView {
    static readonly type = '[Filter Views] Switch Filter View';
    constructor(public filterViewId: string | null) {}
  }

  export class SwitchFilterViewSuccess {
    static readonly type = '[Filter Views] Switch Filter View Success';
    constructor(public filterViewId: string | null) {}
  }

  export class SwitchFilterViewFail {
    static readonly type = '[Filter Views] Switch Filter View Fail';
    constructor(public error: Error) {}
  }

  export class CreateFilterView {
    static readonly type = '[Filter Views] Async Create Filter View';
    constructor(public filterView?: IFilterView) {}
  }

  export class CreateFilterViewSuccess {
    static readonly type = '[Filter Views] Create Filter View Success';
    constructor(public filterView: IFilterView) {}
  }

  export class CreateFilterViewFail {
    static readonly type = '[Filter Views] Create Filter View Fail';
    constructor(public error: Error) {}
  }

  export class FetchFilterViews {
    static readonly type = '[Filter Views] Async Fetch Filter Views';
    constructor(public dashId: string) {}
  }

  export class FetchFilterViewsSuccess {
    static readonly type = '[Filter Views] Fetch Filter Views Success';
    constructor(public filterViews: IFilterView[]) {}
  }

  export class FetchFilterViewsFail {
    static readonly type = '[Filter Views] Fetch Filter Views Fail';
    constructor(public error: Error) {}
  }

  export class UpdateFilterView {
    static readonly type = '[Filter Views] Async Update Filter View';
    constructor(public filterView: IFilterView) {}
  }

  export class UpdateFilterViewSuccess {
    static readonly type = '[Filter Views] Update Filter View Success';
    constructor(public filterView: IFilterView) {}
  }

  export class DeleteFilterView {
    static readonly type = '[Filter Views] Async Delete Filter View';
    constructor(public filterViewId: string) {}
  }

  export class DeleteFilterViewSuccess {
    static readonly type = '[Filter Views] Delete Filter View Success';
    constructor(public filterViewId: string) {}
  }

  export class DeleteFilterViewFail {
    static readonly type = '[Filter Views] Delete Filter View Fail';
    constructor(public error: Error) {}
  }

  export class DeleteFilterViewItem {
    static readonly type = '[Filter Views] Async Delete Filter View Item';
    constructor(public filterViewItemId: string) {}
  }

  export class DeleteFilterViewItemSuccess {
    static readonly type = '[Filter Views] Delete Filter View Item Success';
    constructor(public filterViewItemId: string) {}
  }

  export class DeleteFilterViewItemFail {
    static readonly type = '[Filter Views] Delete Filter View Item Fail';
    constructor(public error: Error) {}
  }

  export class UpdateFilterViewFail {
    static readonly type = '[Filter Views] Update Filter View Fail';
    constructor(public error: Error, public filterViewId) {}
  }

  export class UpdateFilterViewItem {
    static readonly type = '[Filter Views] Async Update Filter View Item';
    constructor(public filterViewItem: IFilterViewItem) {}
  }

  export class SetActiveFilterViewItem {
    static readonly type = '[Filter Views] Set Active Filter View Item';
    constructor(
      public filterViewId: string | null,
      public filterViewItemId: string | null,
    ) {}
  }

  export class AddFilterViewItemWidgetRelationship {
    static readonly type =
      '[Filter Views] Add Filter View Item Widget Relation';
    constructor(public fviwcRelationship: IFilterViewItemWidgetRelationship) {}
  }

  export class AddFilterViewItemWidgetRelationshipSuccess {
    static readonly type =
      '[Filter Views] Add Filter View Item Widget Relation Success';
    constructor(public fviwcRelationshipsAndPreviewData: IAddFVIWCResponse) {}
  }

  export class AddFilterViewItemWidgetRelationshipFail {
    static readonly type =
      '[Filter Views] Add Filter View Item Widget Relation Fail';
    constructor(public error: Error, public widgetId: string) {}
  }

  export class DeleteFilterViewItemWidgetRelationship {
    static readonly type =
      '[Filter Views] Delete Filter View Item Widget Relation';
    constructor(
      public filterViewWidgetRelationId: number,
      public widgetId: string,
    ) {}
  }

  export class DeleteFilterViewItemWidgetRelationshipSuccess {
    static readonly type =
      '[Filter Views] Delete Filter View Item Widget Relation Success';
    constructor(
      public filterViewWidgetRelationId: number,
      public widgetId: string,
    ) {}
  }

  export class DeleteFilterViewItemWidgetRelationshipFail {
    static readonly type =
      '[Filter Views] Delete Filter View Item Widget Relation Fail';
    constructor(public error: Error, public widgetId: string) {}
  }
}

export namespace WidgetEditor {
  export class Open {
    static readonly type = '[Widget Editor] Open';
    constructor(
      /**
       * Dialog service needs to be injected into an angular file using DI. Can't instantiate the service outside
       * angular but passing it as a param is fine.
       */
      public dialogService: NbDialogService,
      public widgetId: string,
      public dashId?: string,
      public gridsterItem?: GridsterItem,
    ) {}
  }

  export class Close {
    static readonly type = '[Widget Editor] Close';
    constructor() {}
  }

  export class OnClose {
    static readonly type = '[Widget Editor] On Close';
    constructor(
      public widgetId: string,
      public destroy: boolean,
      public updatingWidget: boolean,
    ) {}
  }

  export class SaveNewWidget {
    static readonly type = '[Widget Editor] Async Save New Widget';
    constructor(public widget: ICreateWidgetRequestPayload) {}
  }

  export class SaveNewWidgetSuccess {
    static readonly type = '[Widget Editor] Save New Widget Success';
    constructor(public widget: IGetWidgetResponse) {}
  }
  export class SaveNewWidgetFail {
    static readonly type = '[Widget Editor] Save New Widget Fail';
    constructor(public error: Error) {}
  }

  export class UpdateWidget {
    static readonly type = '[Widget Editor] Async Update Widget';
    constructor(public widgetId: string) {}
  }

  export class UpdateWidgetSuccess {
    static readonly type = '[Widget Editor] Update Widget Success';
    constructor(public widget: IStorableWidgetV2) {}
  }

  export class UpdateWidgetFail {
    static readonly type = '[Widget Editor] Update Widget Fail';
    constructor(public error: Error, public widgetId: string) {}
  }

  export class CreateWidget {
    static readonly type = '[Widget Editor] Async Create Widget';
    constructor(public widget: IStorableWidgetV2, public dashboardId: string) {}
  }

  export class CreateWidgetSuccess {
    static readonly type = '[Widget Editor] Create Widget Success';
    constructor(public widget: IGetWidgetResponse, public dashId: string) {}
  }

  export class CreateWidgetFail {
    static readonly type = '[Widget Editor] Create Widget Fail';
    constructor(public error: Error) {}
  }

  export class GetCalculationPreviews {
    static readonly type = '[Widget Editor] Async Get Calculation Previews';
    constructor(public widgetId: string) {}
  }

  export class GetCalculationPreviewsSuccess {
    static readonly type = '[Widget Editor] Get Calculation Previews Success';
    constructor(
      public res: IGetCalculationPreviewsResponse,
      public widgetId: string,
    ) {}
  }

  export class GetCalculationPreviewsFail {
    static readonly type = '[Widget Editor] Get Calculation Previews Fail';
    constructor(public error: Error, public widgetId: string) {}
  }
}

export namespace Widget {
  export class FetchWidget {
    static readonly type = '[Widget] Async Fetch Widget';
    constructor(public widgetId: string) {}
  }

  export class FetchWidgetSuccess {
    static readonly type = '[Widget] Fetch Widget Success';
    constructor(public widget: IStorableWidgetV2) {}
  }

  export class FetchWidgetFail {
    static readonly type = '[Widget] Fetch Widget Fail';
    constructor(public error: Error, public widgetId: string) {}
  }

  export class PositionChange {
    static readonly type = '[Widget] Widget Position Change';
    constructor(public widgetId: string, public position: GridsterItem) {}
  }

  export class DeleteWidget {
    static readonly type = '[Widget] Async Soft Delete';
    constructor(public widgetId: string) {}
  }

  export class DeleteWidgetSuccess {
    static readonly type = '[Widget] Soft Delete Success';
    constructor(public widgetId: string) {}
  }

  export class DeleteWidgetFail {
    static readonly type = '[Widget] Soft Delete Fail';
    constructor(public error: Error, public widgetId: string) {}
  }

  export class GetCalculationData {
    static readonly type = '[Widget] Async Get Calculation Data';
    constructor(
      public calculationIds: string[],
      public widgetIds: string[],
      public loading: boolean,
      public getModalData: boolean = false,
      public shareToken: string = undefined,
    ) {}
  }

  export class GetCalculationDataSuccess {
    static readonly type = '[Widget] Get Calculation Data Success';
    constructor(
      public widgetIds: string[],
      public res: IGetCalculationDataResponse[],
    ) {}
  }

  export class GetCalculationDataFail {
    static readonly type = '[Widget] Get Calculation Data Fail';
    constructor(public error: Error, public widgetIds: string[]) {}
  }

  export class SubscribeToCalculationDataUpdates {
    static readonly type = '[Widget] Subscribe To Calculation Data Updates';
    constructor(public calcDataIds: string[]) {}
  }

  export class UnsubscribeFromCalculationData {
    static readonly type = '[App] Async Unsubscribe From Calculation Data';
    constructor(public calculationIds: string[], public shareToken?: string) {}
  }

  export class UnsubscribeFromCalculationDataSuccess {
    static readonly type = '[App] Unsubscribe From Calculation Data Success';
    constructor(public calculationIds: string[]) {}
  }

  export class UnsubscribeFromCalculationDataFail {
    static readonly type = '[App] Unsubscribe From Calculation Data Fail';
    constructor(public error: Error) {}
  }

  export class Edit {
    static readonly type = '[Widget] Edit';
    constructor(
      public widgetId: string,
      public dialogService: NbDialogService,
    ) {}
  }

  export class CalcDataUpdate {
    static readonly type = '[Widget] Calc Data Update';
    constructor(
      public widgetId: string,
      public calcData: ICalculationDataUpdate,
    ) {}
  }

  export class SetWidget {
    static readonly type = '[Widget] Set Widget';
    constructor(public widget: IStorableWidgetV2) {}
  }

  export class CreateCopy {
    static readonly type = '[Widget] Async Create Copy';
    constructor(
      public widgetId: string,
      public dashId: string,
      public gridsterItem: GridsterItem,
      public title: string,
      public newGroup: boolean = false,
    ) {}
  }

  export class CreateCopySuccess {
    static readonly type = '[Widget] Create Copy Success';
    constructor(
      public widget: IStorableWidgetV2,
      public sourceWidgetId: string,
    ) {}
  }

  export class CreateCopyFail {
    static readonly type = '[Widget] Create Copy Fail';
    constructor(public error: Error, public widgetId: string) {}
  }

  export class SetWidgetHighlightState {
    static readonly type = '[Widget] Set Widget Highlight State';
    constructor(public widgetHighlighStates: IWidgetHighlightMap) {}
  }

  export class ToggleSelectedState {
    static readonly type = '[Widget] Toggle Selected State';
    constructor(public widgetId: string) {}
  }

  export class CreateTempCalcData {
    static readonly type = '[Widget] Async Create Temp Calc Data';
    constructor(public groupIds: string[]) {}
  }

  export class CreateTempCalcDataSuccess {
    static readonly type = '[Widget] Create Temp Calc Data Success';
    constructor(
      public calcPreviewData: IGetCalculationPreviewsResponse,
      public groupIds: string[],
    ) {}
  }

  export class CreateTempCalcDataFail {
    static readonly type = '[Widget] Create Temp Calc Data Fail';
    constructor(public error: Error, public groupIds: string[]) {}
  }
}

export namespace FilterVariable {
  export class Create {
    static readonly type = '[Filter Variable] Create Filter Variable';
    constructor(public filterVariable: IFilterVariable) {}
  }

  export class CreateSuccess {
    static readonly type = '[Filter Variable] Create Filter Variable Success';
    constructor(public filterVariable: IFilterVariable) {}
  }

  export class CreateFail {
    static readonly type = '[Filter Variable] Create Filter Variable Fail';
    constructor(public error: Error) {}
  }

  export class GetAll {
    static readonly type = '[Filter Variable] Async Get All Filter Variables';
  }

  export class GetAllSuccess {
    static readonly type = '[Filter Variable] Get All Filter Variables Success';
    constructor(public filterVariables: IFilterVariable[]) {}
  }

  export class GetAllFail {
    static readonly type = '[Filter Variable] Get All Filter Variables Fail';
    constructor(public error: Error) {}
  }

  export class Delete {
    static readonly type = '[Filter Variable] Async Delete Filter Variable';
    constructor(public filterVariableId: string) {}
  }

  export class DeleteSuccess {
    static readonly type = '[Filter Variable] Delete Filter Variable Success';
    constructor(public filterVariableId: string) {}
  }

  export class DeleteFail {
    static readonly type = '[Filter Variable] Delete Filter Variable Fail';
    constructor(public error: Error) {}
  }

  export class Update {
    static readonly type = '[Filter Variable] Async Update Filter Variable';
    constructor(public filterVariable: IFilterVariable) {}
  }

  export class UpdateSuccess {
    static readonly type = '[Filter Variable] Update Filter Variable Success';
    constructor(public filterVariable: IFilterVariable) {}
  }

  export class UpdateFail {
    static readonly type = '[Filter Variable] Update Filter Variable Fail';
    constructor(public error: Error) {}
  }

  export class SetFilterVariablesState {
    static readonly type = '[Filter Variable] Set Filter Variable State';
    constructor(
      public dashboardId: string,
      public filterVariableState: IFilterVariableStateMap,
      public linkId?: string,
      public defaultState: boolean = false,
      public isLinkBase: boolean = false,
    ) {}
  }

  export class SetFilterVariablesStateSuccess {
    static readonly type =
      '[Filter Variable] Set Filter Variable State Success';
    constructor(public filterVariableState: IFilterVariableBackendState) {}
  }

  export class SetFilterVariablesStateFail {
    static readonly type = '[Filter Variable] Set Filter Variable State Fail';
    constructor(public error: Error) {}
  }

  export class GetFilterVariablesState {
    static readonly type = '[Filter Variable] Async Get Filter Variable State';
    constructor(
      public dashboardId: string,
      public linkId?: string,
      public token?: string,
    ) {}
  }

  export class GetFilterVariablesStateSuccess {
    static readonly type =
      '[Filter Variable] Get Filter Variable State Success';
    constructor(
      public filterVariableResponse: IGetFilterVariableStateResponse,
    ) {}
  }

  export class GetFilterVariablesStateFail {
    static readonly type = '[Filter Variable] Get Filter Variable State Fail';
    constructor(public error: Error) {}
  }

  export class ResetFilterVariablesState {
    static readonly type = '[Filter Variable] Reset Filter Variable State';
    constructor(public dashboardId: string) {}
  }
}

export namespace ShareLink {
  export class GetAll {
    static readonly type = '[Share Link] Async Get All Share Links';
  }

  export class GetAllSuccess {
    static readonly type = '[Share Link] Get All Share Links Success';
    constructor(public shareLinks: IPublicLink[]) {}
  }

  export class GetAllFail {
    static readonly type = '[Share Link] Get All Share Links Fail';
    constructor(public error: Error) {}
  }

  export class Save {
    static readonly type = '[Share Link] Async Save Share Link';
    constructor(public shareLink: IPublicLink) {}
  }

  export class SaveSuccess {
    static readonly type = '[Share Link] Save Share Link Success';
    constructor(public shareLink: IPublicLink) {}
  }

  export class SaveFail {
    static readonly type = '[Share Link] Save Share Link Fail';
    constructor(public error: Error) {}
  }

  export class Copy {
    static readonly type = '[Share Link] Async Copy Share Link';
    constructor(public shareLinkId: string) {}
  }

  export class CopySuccess {
    static readonly type = '[Share Link] Copy Share Link Success';
    constructor(public shareLink: string) {}
  }

  export class CopyFail {
    static readonly type = '[Share Link] Copy Share Link Fail';
    constructor(public error: Error) {}
  }

  export class Delete {
    static readonly type = '[Share Link] Async Delete Share Link';
    constructor(public shareLinkId: string) {}
  }

  export class DeleteSuccess {
    static readonly type = '[Share Link] Delete Share Link Success';
    constructor(public shareLinkId: string) {}
  }

  export class DeleteFail {
    static readonly type = '[Share Link] Delete Share Link Fail';
    constructor(public error: Error) {}
  }
}
