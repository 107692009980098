import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ISessionVariables } from '@interfaces';
import {
  nbAuthCreateToken,
  NbAuthJWTToken,
  NbAuthService,
  NbTokenService,
} from '@nebular/auth';
import { Store } from '@ngxs/store';
import { App } from '@root/state/app.actions';
import { EventQueueService } from '@services';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private sessionVars: ISessionVariables;

  private isAuthenticatedMirror = false;
  private loggingOut = false;

  constructor(
    private nbAuth: NbAuthService,
    private eventQueue: EventQueueService,
    private http: HttpClient,
    private router: Router,
    private nbTokenService: NbTokenService,
    private store: Store,
  ) {
    this.init();
  }

  private init() {
    this.nbAuth.onTokenChange().subscribe((token) => {
      if (token.isValid()) {
        this.isAuthenticatedMirror = true;
      } else {
        this.isAuthenticatedMirror = false;
        // Force logout the user if the token is invalid
        // if (!this.loggingOut) {
        //   this.logOut(true);
        // }
      }
    });
    // Keep isAuthenticatedMirror value up to date with observable emissions
    this.nbAuth
      .onAuthenticationChange()
      .subscribe(
        (isAuthenticated) => (this.isAuthenticatedMirror = isAuthenticated),
      );
    this.eventQueue.on('REFRESH_STALE_TOKEN').subscribe((newTokenString) => {
      const token = nbAuthCreateToken(
        NbAuthJWTToken,
        newTokenString,
        'manualTokenRefresh',
      );
      this.nbTokenService.set(token);
    });
  }

  public logIn() {
    this.loggingOut = false;
    if (environment.production) {
      window.location.href = environment.landingPageUrl + '/login';
    } else {
      this.router.navigate(['/auth/sign-in']);
    }
  }

  public signUp(body: object): Promise<any> {
    return firstValueFrom(
      this.http.post(environment.backendUrl + '/sign-up/', body),
    );
  }

  public logOut(force: boolean = false): void {
    this.loggingOut = true;
    this.nbAuth.logout('email').subscribe(() => {
      this.store.dispatch(new App.ResetState());
      const logoutUrl = this.getLogoutUrl(force);
      if (environment.production) {
        window.location.href = logoutUrl;
      } else {
        this.router.navigate([logoutUrl]);
      }
    });
  }

  public getLogoutUrl(force: boolean): string {
    if (environment.production) {
      if (force) {
        return environment.landingPageUrl + '/login?force_logout';
      }
      return environment.landingPageUrl + '/logout';
    }
    return '/auth/sign-in';
  }

  public isAuthenticated(): boolean {
    return this.isAuthenticatedMirror;
  }

  public setSessionVars(vars: ISessionVariables) {
    this.sessionVars = vars;
  }

  public getFullToken(): string | undefined {
    return this.sessionVars?.user?.full_token;
  }

  public getTokenIsValid(): boolean | undefined {
    return this.sessionVars?.user?.is_valid;
  }

  public getEditorId(): string | undefined {
    return this.sessionVars?.editor_uuid;
  }

  public getUserId(): string {
    return this.sessionVars?.user.user_uuid;
  }

  public tokenIsExpired() {
    return this.sessionVars?.user.exp < Date.now() / 1000;
  }

  public getCompany() {
    return this.sessionVars?.user.company;
  }
}
