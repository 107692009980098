import { IDataStreamColumn } from '@interfaces';
import { datatype_conversions } from '@root/custom_scripts/config';
import {
  ItemListType,
  VirtualColumnBase,
} from '@root/interface-registry/data-modifier.interface';

export class NestedVirtualColumn {
  vcol: VirtualColumnBase;
  items: ItemListType[];
  editMode: boolean;
  constructor(
    vcol: VirtualColumnBase,
    operators: string[][],
    columns: IDataStreamColumn[],
    editMode: boolean,
  ) {
    this.editMode = editMode;
    this.vcol = vcol;
    this.vcol.logicOperators = this.vcol.filter.map(
      (item) => item.logicOperator,
    );
    this.items = vcol.filter;
    this.operators = operators;
    this.logicOperatorCounter = 0;
    this.dtypes = columns.reduce((acc, column) => {
      acc[column.name] = column.dataType;
      return acc;
    }, {});
  }
  logicOperatorCounter = 0;
  operators = [];
  dtypes = {};
  spotTypes = {};

  getNested() {
    let jsonString = '[';
    for (let item of this.items) {
      for (let parenthesis in this.vcol.leftParentheses[item.index]) {
        jsonString += '[';
      }

      jsonString += JSON.stringify(this.items[item.index]);
      for (let parenthesis in this.vcol.rightParentheses[item.index]) {
        jsonString += ']';
      }
      if (item.index != this.items.length - 1) {
        jsonString += ',';
      }
    }
    jsonString += ']';
    let filter = JSON.parse(jsonString);
    this.logicOperatorCounter = 0;

    return this.AddLogicOperators(filter, 0);
  }

  AddLogicOperators(Filters, recursion_level) {
    let dictFilter;
    if (recursion_level == 0) {
      dictFilter = {
        leftParentheses: this.vcol.leftParentheses,
        rightParentheses: this.vcol.rightParentheses,
        logicOperators: [],
        filter: [],
        useGrouping: this.vcol.useGrouping,
        grouping: this.vcol.grouping,
        onlyBusinessDays: this.vcol.onlyBusinessDays,
      };
    } else {
      dictFilter = { logicOperators: [], filter: [] };
    }

    for (let filter_index in Filters) {
      if ('type' in Filters[filter_index]) {
        dictFilter.filter.push(Filters[filter_index]);
      } else {
        dictFilter.filter.push(
          this.AddLogicOperators(Filters[filter_index], recursion_level + 1),
        );
      }
      if (parseInt(filter_index) < Filters.length - 1) {
        dictFilter.logicOperators.push(
          this.vcol.logicOperators[this.logicOperatorCounter],
        );
        this.logicOperatorCounter++;
      }
    }

    return dictFilter;
  }

  getTypes() {
    let { col_type } = this.SetInputTypes(this.getNested(), 0);
    return {
      types: this.spotTypes,
      operators: this.operators,
      colType: col_type,
    };
  }

  SetInputTypes(col_obj, index) {
    let current_type = null;
    let items = col_obj.filter as ItemListType[];
    for (let col of items) {
      let col_type = null;
      let prev_current_type = current_type;
      if (col.index == undefined) {
        let info = this.SetInputTypes(col, index);
        current_type = this.proc_type(
          current_type,
          prev_current_type,
          info.col_type,
          index,
        );
        index = info.index;
        this.spotTypes[index - 1] = current_type; // index - 1 bc index++ already happend in recursive call of SetInputTypes
      } else {
        if (col.type == 'type') {
          if (col.index != 0) {
            this.operators[col.index - 1] =
              datatype_conversions[current_type].to_types;
          }
          current_type = col.convertToType;
        } else {
          if (col.type === 'column') {
            col_type = this.dtypes[col.column];
          } else if (col.type === 'value') {
            if (col.useAdvanced) {
              col_type =
                col.advancedValueType === 'filtered_timespan'
                  ? 'timedelta'
                  : col.advancedValueType;
            } else col_type = col.dtype;
          }
          current_type = this.proc_type(
            current_type,
            prev_current_type,
            col_type,
            col.index,
          );
        }
        this.spotTypes[index] = current_type;
        index++;
      }
    }

    return { col_type: current_type, index: index };
  }
  proc_type(current_type, prev_current_type, col_type, index) {
    if (current_type == null) {
      return col_type;
    }
    this.operators[index - 1] =
      datatype_conversions[current_type].allowed_operators[col_type];
    if (
      (current_type.indexOf('date') != -1 && col_type === 'timedelta') ||
      (col_type.indexOf('date') != -1 && current_type === 'timedelta')
    ) {
      current_type = 'datetime';
    } else if (
      (current_type.indexOf('date') != -1 && col_type.indexOf('date') != -1) ||
      (col_type === 'timedelta' && current_type === 'timedelta') ||
      (current_type === 'timedelta' &&
        (col_type === 'int' ||
          col_type === 'float' ||
          col_type === 'boolean')) ||
      (col_type === 'timedelta' &&
        (current_type === 'int' ||
          current_type === 'float' ||
          current_type === 'boolean'))
    ) {
      current_type = 'timedelta';
    } else if (current_type === 'int' && col_type === 'int') {
      current_type = 'int';
    } else if (
      (current_type === 'int' ||
        current_type === 'float' ||
        current_type === 'boolean') &&
      (col_type === 'int' || col_type === 'float' || col_type === 'boolean')
    ) {
      current_type = 'float';
    } else if (current_type == 'string' && col_type == 'string') {
      current_type = 'string';
    } else {
      if (this.editMode) {
        this.items[index].logicOperator = '';
        this.items[index].valid = 'red';
        this.items[index].valid = 'red';
      }
    }
    if (
      prev_current_type != null &&
      datatype_conversions[prev_current_type].allowed_operators[col_type]
        .length > 0 &&
      datatype_conversions[prev_current_type].allowed_operators[
        col_type
      ].indexOf(this.items[index].logicOperator) == -1
    ) {
      if (this.editMode)
        this.items[index].logicOperator =
          datatype_conversions[prev_current_type].allowed_operators[
            col_type
          ][0];
    }
    return current_type;
  }
}
