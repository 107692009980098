import { Component } from '@angular/core';

@Component({
  selector: 'resplendent-graph-animated-bar-loader',
  templateUrl: './graph-animated-bar-loader.component.html',
  styleUrls: ['./graph-animated-bar-loader.component.scss'],
})
export class GraphAnimatedBarLoaderComponent {
  constructor() {}
}
