<input
  nbInput
  [formControl]="inputFormControl"
  type="text"
  [nbAutocomplete]="auto"
/>
<nb-autocomplete #auto>
  <nb-option
    *ngFor="let option of filteredControlOptions$ | async"
    [value]="option"
  >
    {{ option }}
  </nb-option>
</nb-autocomplete>
