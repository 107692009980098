<nb-card>
    <nb-card-header>
        Select a timezone
    </nb-card-header>
    <nb-card-body>
        <input nbInput  type="text" (keyup)='Search($event)' class='mb-2' placeholder="search timezones"/>
        <nb-select [(selected)]="Column.timezone" fullWidth class='simple-select'>
            <nb-option *ngFor="let timezone of FilteredTimezones " [value]="timezone">
                {{timezone}}
            </nb-option>
        </nb-select>
    </nb-card-body>
</nb-card>
