import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import { IDataStreamColumn } from '@interfaces';
import { Observable, map, of, startWith, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-column-autocomplete',
  templateUrl: './column-autocomplete.component.html',
  styleUrls: ['./column-autocomplete.component.scss'],
})
export class ColumnAutocompleteComponent extends BaseComponent implements OnInit {
  @Input() columns: IDataStreamColumn[];
  @Output() selectedColumnChange = new EventEmitter<string>();
  @Input() selectedColumn: string;

  filteredColumns$: Observable<IDataStreamColumn[]>;
  inputFormControl: FormControl;

  constructor() {
    super();
  }

  ngOnInit() {
    this.inputFormControl = new FormControl('');
    // sort the columns by name/alias
    this.columns = this.columns.sort((a, b) => {
      const aName = a.alias ? a.alias : a.name;
      const bName = b.alias ? b.alias : b.name;
      return aName.localeCompare(bName);
    });
    this.filteredColumns$ = of(this.columns);

    this.filteredColumns$ = this.inputFormControl.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
      startWith(''),
      tap((value: string | IDataStreamColumn) => {
        if (typeof value !== 'string') {
          if ('name' in value) {
            this.selectedColumn = value.name;
            this.selectedColumnChange.emit(value.name);
          }
        }
      }),
      map((filterString) => this.filter(filterString)),
    );
    if (this.selectedColumn) {
      const selectedColumn = this.columns.find((column) => column.name === this.selectedColumn);
      this.inputFormControl.setValue(selectedColumn.alias ?? selectedColumn.name);
    }
  }

  private filter(value: string | IDataStreamColumn): IDataStreamColumn[] {
    if (typeof value !== 'string') return this.columns;
    const filterValue = value.toLowerCase();
    return this.columns.filter((column) => {
      const columnName = column.alias ? column.alias : column.name;
      return columnName.toLowerCase().includes(filterValue);
    });
  }

  showColumnName(column: string | IDataStreamColumn) {
    if (typeof column === 'string') return column;
    return column.alias ? column.alias : column.name;
  }
}
