import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { EventQueueService } from '@services';
import { IAppEvents } from '../../interface-registry/event-queue.interface';

@Component({
  selector: 'resplendent-user-action-dialog',
  templateUrl: './user-action-dialog.component.html',
  styleUrls: ['./user-action-dialog.component.scss'],
})
export class UserActionDialogComponent {
  @Input() Notification: Partial<IAppEvents>;
  @Input() ConfirmText: string = 'Confirm';
  @Input() CancelText: string = 'Cancel';
  @Input() TitleText: string;
  @Input() ContentText: string;
  /** Positive action will have the confirm button green and cancel red, negative will be opposite */
  @Input() ActionType: 'positive' | 'negative' = 'negative';

  constructor(private dialogRef: NbDialogRef<UserActionDialogComponent>, private eventQueue: EventQueueService) {}

  Cancel() {
    this.dialogRef.close(false);
  }
  Confirm() {
    for (let key in this.Notification) {
      this.eventQueue.dispatch(key as keyof IAppEvents, this.Notification[key]);
    }
    this.dialogRef.close(true);
  }
}
