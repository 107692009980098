import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'resplendent-websocket-disconnect-overlay',
  templateUrl: './websocket-disconnect-overlay.component.html',
  styleUrls: ['./websocket-disconnect-overlay.component.scss'],
})
export class WebsocketDisconnectOverlayComponent {
  constructor(private dialogRef: NbDialogRef<WebsocketDisconnectOverlayComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
