import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IBreadcrumbItem } from '@root/interface-registry/data-builder.interface';
import Case from 'case';
import {
  ICalculationData,
  ICalculationDataDictionary,
  ICalculationDataUpdate,
  IChildFilterDataMapResponse,
  IChildFilterDataResponse,
  IGetCalculationDataResponse,
} from '../interface-registry/data-service.interface';
import {
  IStringArrayMap,
  IStringMap,
  ValueOf,
} from '../interface-registry/general.interface';
import { SortingType } from '@interfaces';

/** A map of months and their index position in the 12 months list. All keys are lower cased. Supports 3 letter abbreviated month */
export const monthOrder = {
  jan: 0,
  feb: 1,
  mar: 2,
  apr: 3,
  may: 4,
  jun: 5,
  jul: 6,
  aug: 7,
  sep: 8,
  oct: 9,
  nov: 10,
  dec: 11,
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  // may is special, check above
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};

/**
 * A map of days of the week and their index position in the 7 week day list. All keys are lower cased.
 * Supports 3 letter abbreviated day of week.
 */
export const dowOrder = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

export const dowAndMonthOrder = { ...dowOrder, ...monthOrder };
Object.entries(monthOrder).forEach(([key, value]) => {
  dowAndMonthOrder[key] = value + 7;
});
/**
 * Turns all of the object's keys to camel case. Works in a shallow manner so no nested keys will be changed
 */
export const camelCaseObject = <T>(object: T): T => {
  const newObject = {};
  const kvs = Object.entries(object);
  // Convert all the keys to camel case and populate the new object
  kvs.forEach(([key, value]) => {
    const camelKey = Case.camel(key);
    newObject[camelKey] = value;
  });
  return newObject as T;
};

export const removeWordFromString = (word: string, str: string): string => {
  const regex = new RegExp(word);
  return str.replace(regex, '');
};

export const deepEqual = (
  object1,
  object2,
  keysToExclude?: string[],
): boolean => {
  // console.log('object1', object1, 'object2', object2);
  if (object1 === object2) {
    return true;
  } else if (
    typeof object1 !== 'object' ||
    object1 === null ||
    typeof object2 !== 'object' ||
    object2 === null
  ) {
    return false;
  }

  let keys1 = Object.keys(object1);
  let keys2 = Object.keys(object2);
  if (keysToExclude) {
    keys1 = keys1.filter((k) => !keysToExclude.includes(k));
    keys2 = keys2.filter((k) => !keysToExclude.includes(k));
  }

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2, keysToExclude)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const isObject = (object) => {
  return object != null && typeof object === 'object';
};

/** Generate a nice looking random color in HSL format */
export const randomNiceHslColor = (
  returnType: 'string' | 'arr' = 'string',
): string | number[] => {
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const h = randomInt(0, 360);
  const s = randomInt(42, 90);
  const l = randomInt(40, 80);
  if (returnType === 'string') {
    return `hsl(${h},${s}%,${l}%)`;
  } else {
    return [h, s, l];
  }
};

export const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const randomNiceHexColor = () => {
  const [h, s, l] = randomNiceHslColor('arr') as number[];
  return hslToHex(h, s, l);
};

/** Generates 2 random vibrant colors with enough contrast difference between them */
export const random2NiceHexColors = (): string[] => {
  const randomInt = ([min, max]: number[]) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const hConstraints = [0, 360];
  const sConstraints = [60, 90];
  const lConstraints = [35, 65];

  const color1 = {
    h: randomInt(hConstraints),
    s: randomInt(sConstraints),
    l: randomInt(lConstraints),
  };

  const color2 = {
    h: randomInt(hConstraints),
    s: randomInt(sConstraints),
    l: randomInt(lConstraints),
  };

  // Randomize the hsl values of color 2 until they are different enough from color1
  while (Math.abs(color2.h - color1.h) < 60) {
    color2.h = randomInt(hConstraints);
  }
  while (Math.abs(color2.s - color1.s) < 13) {
    color2.s = randomInt(sConstraints);
  }
  while (Math.abs(color2.l - color1.l) < 13) {
    color2.l = randomInt(lConstraints);
  }

  return [
    hslToHex(color1.h, color1.s, color1.l),
    hslToHex(color2.h, color2.s, color2.l),
  ];
};

export const hexToHSL = (
  H: string,
  returnType: 'string' | 'arr' = 'string',
): string | number[] => {
  // Convert hex to RGB first
  let r = 0 as any;
  let g = 0 as any;
  let b = 0 as any;
  if (H.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  if (returnType === 'string') {
    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  } else {
    return [h, s, l];
  }
};

/** Replace part of a string with a given string at a given index. Returns the new string */
export const replaceAtIndex = (
  str: string,
  index: number,
  replacement: string,
): string => {
  return (
    str.substr(0, index) +
    replacement +
    str.substr(index + replacement.length, str.length)
  );
};

/**
 * Copies the title. Depending on whether it's the first time the title was copied or not, the output will be the following:
 * - Title
 * - Title - Copy
 * - Title - Copy 2
 * - Title - Copy 3
 * - Title - Copy 4...
 */
export const titleCopy = (title: string): string => {
  if (!title) return title;
  let newTitle;
  if (title.includes(' - Copy')) {
    let lastCharIndex = title.length - 1;
    const lastChars = title[title.length - 2] + title[title.length - 1];
    const lastCharInt = parseInt(lastChars, 10);
    const lastCharIsInt = !isNaN(lastCharInt);
    if (lastCharIsInt) {
      const newLastChar = lastCharInt + 1;
      if (newLastChar >= 10) lastCharIndex -= 1;
      let replacementString = newLastChar.toString(10);
      if (newLastChar === 10) replacementString = ' ' + replacementString;
      newTitle = replaceAtIndex(title, lastCharIndex, replacementString);
    } else {
      newTitle = title + ' 2';
    }
  } else {
    newTitle = title + ' - Copy';
  }
  return newTitle;
};

/** Detects if the input is a promise. Could be an npm, package, bet it would get millions of downloads */
export const isPromise = (value: any): boolean => {
  return value && typeof value.then === 'function';
};

/**
 * Input - background color hex
 * Output - black or white foreground color
 */
export const getContrastColor = (
  backgroundColorHex: string,
): '#000000' | '#ffffff' => {
  backgroundColorHex = backgroundColorHex.slice(0, 7);
  const hexRegEx = /^#([0-9a-f]{6})$/i;
  const isValidHex = hexRegEx.test(backgroundColorHex);
  if (!isValidHex) {
    throw new Error(`Invalid hex - "${backgroundColorHex}"`);
  }

  const hex = backgroundColorHex.replace('#', '');
  let r: number = parseInt(hex.slice(0, 2), 16) / 255;
  let g: number = parseInt(hex.slice(2, 4), 16) / 255;
  let b: number = parseInt(hex.slice(4, 6), 16) / 255;

  // Apply gamma correction to the sRGB values
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate the relative luminance of the color
  const luminance: number = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return black for bright colors and white for dark colors
  return luminance > 0.479 ? '#000000' : '#ffffff';
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    return { r, g, b };
  }

  return null;
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  let errorStr;
  let errorNr;
  if (r < 0 || r > 255) {
    errorStr = 'r';
    errorNr = r;
  }
  if (g < 0 || g > 255) {
    errorStr = 'g';
    errorNr = g;
  }
  if (b < 0 || b > 255) {
    errorStr = 'b';
    errorNr = b;
  }
  if (errorStr) {
    throw new Error(
      `Input must be e numbers between 0 and 255, however value for "${errorStr}" was "${errorNr}"`,
    );
  }
  const rgb = [r, g, b];
  const hexString = rgb
    .map((x) => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');
  return '#' + hexString;
};

export const fadeHex = (hex: string): string => {
  const hsl = hexToHSL(hex, 'arr') as number[];
  let [h, s, l] = hsl;
  s = s - 15;
  l = l - 15;
  return hslToHex(h, s, l);
};

/** Return the smallest number in the array */
export const minInArray = (arr: number[]): number => {
  return arr.reduce((prev, curr) => {
    return prev < curr ? prev : curr;
  });
};
/** Returns the largest number in the array */
export const maxInArray = (arr: number[]): number => {
  return arr.reduce((prev, curr) => {
    return prev > curr ? prev : curr;
  });
};

/** Returns both min and max value of an array */
export const minMaxInArray = (arr: number[]): { min: number; max: number } => {
  if (arr.length === 0) return null;

  let min = arr[0];
  let max = arr[0];

  arr.forEach((n) => {
    if (n > max) max = n;
    if (n < min) min = n;
  });

  return { min, max };
};

/** Get the range between 2 numbers */
export const calculateRange = (min: number, max: number): number => {
  let range;
  if (min >= 0 && max >= 0) {
    range = max - min;
  } else if (min <= 0 && max >= 0) {
    range = max + Math.abs(min);
  } /**  (min <= 0 && max <= 0) */ else {
    range = min - max;
  }
  return range;
};

/**
 * Abbreviate a number to a shorter displayable number:
 * - 1.25843242 -> 1.26
 * - 22.53236 -> 22.5
 * - 101.4234 -> 101
 * - 3231 -> 3.23K
 * - 1222333 -> 1.22M
 */
export const abbreviateNumber = (str: string): string => {
  const nr = parseFloat(str);
  const floatSplit = str.split('.');
  let digits = floatSplit[0];
  let decimals = floatSplit[1];
  let outDigits = digits;
  let outDecimal = '';
  let outSuffix = '';

  if (digits === '') digits = '0';
  if (decimals === undefined) decimals = '';

  if (decimals) {
    // For single digit numbers show only 2 decimal values max
    if (digits.length === 1) {
      // Discard all other decimals and round
      const toFixed = nr.toFixed(2);
      // Do some shenanigans to remove any lagging zeroes
      const str = parseFloat(toFixed.toString()).toString();
      const split = str.split('.');
      outDigits = split[0];
      outDecimal = split[1];
    }
    // For 2 digit numbers show 1 decimal value max
    else if (digits.length === 2) {
      // Discard all other decimals and round
      const toFixed = nr.toFixed(1);
      // Do some shenanigans to remove any lagging zeroes
      const str = parseFloat(toFixed.toString()).toString();
      // Split resulting float at the dot and assign digit and decimals
      const split = str.split('.');
      outDigits = split[0];
      outDecimal = split[1];
    } else {
      outDecimal = '';
      outDigits = Math.round(nr).toString();
    }
  }

  let intValue = parseInt(outDigits);
  if (intValue >= 1000) {
    let suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixNum = 0;
    while (intValue >= 1000) {
      intValue /= 1000;
      suffixNum++;
    }

    // Remove all redundant decimals
    const toFixed = intValue.toFixed(2);
    // Do some shenanigans to remove any lagging zeroes
    const strValue = parseFloat(toFixed.toString()).toString();

    // Split the number into digits and decimals
    const outValueSplit = strValue.split('.');
    // If the number is a float
    if (outValueSplit.length === 2) {
      const digits = outValueSplit[0];
      const decimal = outValueSplit[1];
      // Set the digits as is
      outDigits = digits;
      // Don't set decimals when there are 3 digits (322.23 looks weird)
      if (digits.length !== 3) {
        outDecimal = decimal;
      }

      // If number is int already then just use it
    } else {
      outDigits = strValue;
    }
    outSuffix = suffixes[suffixNum];
  }

  // Construct the output string by combining digits, decimals and suffix
  let out = '';
  out += outDigits;
  if (outDecimal) out += `.${outDecimal}`;
  out += outSuffix;
  return out;
};

export const isInViewport = (elem: Element): boolean => {
  try {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isDate = (date: string) => {
  return !!calcStringDateToDate(date);
};

export const getRandomArraySample = <T>(arr: T[], sampleSize: number): T[] => {
  // Make sure the sample size isn't larger than the array
  while (sampleSize >= arr.length) {
    sampleSize = sampleSize / 2;
    if (sampleSize <= 1) break;
  }

  const indicesMap = {};
  let added = 0;
  let failsafe = 1000;

  // Loop until we got enough random unique indices or we hit the failsafe
  while (added < sampleSize && failsafe > 1) {
    failsafe--;
    // Generate a random index for the array
    const randomInt = Math.floor(Math.random() * (arr.length - 1));

    // If it was already added re-iterate
    const alreadyAdded = indicesMap[randomInt];
    if (alreadyAdded) {
      continue;
      // If it's unique use it
    } else {
      indicesMap[randomInt] = true;
      added++;
    }
  }

  const indices = Object.keys(indicesMap);
  return indices.map((i) => arr[i]);
};

/**
 * 2021-04-20 09:27:06.69420 -> new Date()
 */
export const calcStringDateToDate = (strDate: string): Date => {
  try {
    const dateTime = strDate.split(' ');
    const date = dateTime[0];
    const time = dateTime[1];
    const splitDate = date.split('-').map((n) => parseInt(n));
    const [year, month, day] = splitDate;
    const splitTime = time.split(':').map((n) => parseFloat(n));
    const [hours, minutes, secondsAndMilliseconds] = splitTime;
    const [seconds] = secondsAndMilliseconds
      .toString()
      .split('.')
      .map((n) => parseInt(n));

    const dateObj = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, seconds),
    );

    const isInvalid = dateObj.toString() === 'Invalid Date';
    if (isInvalid) return null;
    return dateObj;
  } catch (e) {
    return null;
  }
};

/** Shuffle the array in place. Also returns the array */
export const shuffleArray = <T>(array: T[]): T[] => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

/**
 * Check if all strings of array pass a condition. The condition is given as a callback. Function short circuits once
 * it finds a string that doesn't satisfy the callback
 */
export const allStringsAreX = (
  strings: string[],
  cb: (str: string) => boolean,
): boolean => {
  let are = true;
  for (let string of strings) {
    are = cb(string);
    if (!are) return false;
  }
  return true;
};

/** Determines if string is month */
export const isMonth = (str: string): boolean => {
  if (!str) return false;
  if (typeof str !== 'string')
    throw new Error("Only strings accepted. Get outta here, I'm walkin' here!");
  str = str.toLowerCase();
  return monthOrder[str] !== undefined;
};

/** Determines if string is day of the week */
export const isDow = (str: string): boolean => {
  if (!str) return false;
  if (typeof str !== 'string')
    throw new Error("Only strings accepted. Get outta here, I'm walkin' here!");
  str = str.toLowerCase();
  return dowOrder[str] !== undefined;
};

export const arrayHasDuplicates = (array: any[]): boolean => {
  return new Set(array).size !== array.length;
};

export const flipStringMapValuesToKeys = (
  stringMap: IStringMap,
): IStringMap => {
  const reverse: IStringMap = {};
  for (let key in stringMap) {
    const value = stringMap[key];
    if (typeof value !== 'string')
      throw new Error('HEY! only string map allowed');
    reverse[value] = key;
  }
  return reverse;
};

export const removeDuplicatesFromArray = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

/** Give url and name, get download from url or crash :) */
export const downloadFromUrl = async (url: string, name?: string) => {
  // We need 2 awaits lol
  const blob = new Blob([await (await fetch(url)).arrayBuffer()]);
  const a = document.createElement('a');
  name = name ? name : 'resplendent-download';
  const objectUrl = URL.createObjectURL(blob);
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', name);
  a.click();
  URL.revokeObjectURL(objectUrl);
  a.remove();
};

/** Works like Array.filter() only with objects, give it an object and a callback and Robert is your mom's brother */
export const filterObject = <T extends Object>(
  obj: T,
  cb: (x: ValueOf<T>) => boolean,
): T | null => {
  const res = {};
  const entries = Object.entries(obj);
  entries.forEach(([k, v]) => {
    const matchesFilter = cb(v);
    if (matchesFilter) res[k] = v;
  });
  const isEmpty = Object.keys(res).length === 0;
  return isEmpty ? null : (res as T);
};

/** Spit out the first property of the object */
export const firstInObject = <T extends Object>(obj: T): T[keyof T] => {
  const keys = Object.keys(obj);
  return obj[keys[0]];
};

export const last = <T>(arr: T[]): T => {
  return arr[arr.length - 1];
};

export const lastInObject = <T extends Object>(obj: T): T[keyof T] => {
  const keys = Object.keys(obj);
  return obj[keys[keys.length - 1]];
};

export const parseCalculationDataUpdate = (
  calcDataUpdate: ICalculationDataUpdate,
): ICalculationData => {
  const data = calcDataUpdate.data;
  if (!data) return null;
  const calcData: ICalculationData = {
    interfaceOf: 'ICalculationData',
    broken: !calcDataUpdate.data,
    calculation_uuid: calcDataUpdate.calculation_uuid,
    modal_data: convertNewModalDataFormatToUsable(calcDataUpdate.modal_data),
    x: data.x,
    y: data.y,
    x_groupings: data.x_groupings,
    is_category_x_axis: data.is_category_x_axis,
    type: data.type,
    delimiter: data.delimiter,
    column_labels: data.column_labels,
    column_links: data.column_links,
    segregation_column: data.segregation_column,
    table_uuid: data.table_uuid,
    table_name: data.table_name,
    is_child_filter: data.is_child_filter,
    calc_type: calcDataUpdate.calc_type,
    last_updated: calcDataUpdate.last_updated,
    extras: calcDataUpdate.data.extras,
    is_enabled: calcDataUpdate.feature_item_enabled,
    update_interval: calcDataUpdate.update_interval,
  };
  return calcData;
};

export const parseGetCalculationDataResponse = (
  calcDataResponse: IGetCalculationDataResponse,
): ICalculationData => {
  const data = calcDataResponse.data;
  if (!calcDataResponse.calc_type) {
    console.error(
      'Missing calc_type on calcDataRes for calcId => ' +
        calcDataResponse.calculation_uuid,
    );
  }
  const calcData: ICalculationData = {
    interfaceOf: 'ICalculationData',
    broken: calcDataResponse.broken,
    calculation_uuid: calcDataResponse.calculation_uuid,
    modal_data: convertNewModalDataFormatToUsable(calcDataResponse.modal_data),
    x: data.x,
    y: data.y,
    x_groupings: data.x_groupings,
    is_category_x_axis: data.is_category_x_axis,
    type: data.type,
    delimiter: data.delimiter,
    column_labels: data.column_labels,
    column_links: data.column_links,
    segregation_column: data.segregation_column,
    table_uuid: data.table_uuid,
    table_name: data.table_name,
    is_child_filter: data.is_child_filter,
    calc_type: calcDataResponse.calc_type,
    last_updated: calcDataResponse.last_updated,
    is_enabled: calcDataResponse.feature_item_enabled,
    update_interval: calcDataResponse.update_interval,
    extras: calcDataResponse.data.extras,
  };
  return calcData;
};
export const parseGetCalculationDataMapResponse = (
  calcDataResponseArr: IGetCalculationDataResponse[],
): ICalculationDataDictionary => {
  const calcDataMap: ICalculationDataDictionary = {};
  calcDataResponseArr.forEach((calcDataResponse) => {
    const data = calcDataResponse.data;
    if (!data) {
      calcDataMap[calcDataResponse.calculation_uuid] = null;
      return;
    }
    const calcData: ICalculationData =
      parseGetCalculationDataResponse(calcDataResponse);

    calcDataMap[calcDataResponse.calculation_uuid] = calcData;
  });
  return calcDataMap;
};

export const parseChildFilterDataResponse = (
  childFilterData: IChildFilterDataResponse,
): ICalculationData => {
  const data = childFilterData.data;

  const y = childFilterData.data.y;

  const calcData: ICalculationData = {
    interfaceOf: 'ICalculationData',
    broken: childFilterData.broken,
    calculation_uuid: childFilterData.calculation_uuid,
    modal_data: convertNewModalDataFormatToUsable(childFilterData.modal_data),
    x: data.x,
    y,
    x_groupings: data.x_groupings,
    is_category_x_axis: data.is_category_x_axis,
    type: data.type,
    delimiter: data.delimiter,
    column_labels: data.column_labels,
    column_links: data.column_links,
    segregation_column: data.segregation_column,
    table_uuid: data.table_uuid,
    table_name: data.table_name,
    is_child_filter: data.is_child_filter,
    calc_type: 'snapshot', // This fn is only for snapshot calculations
    last_updated: childFilterData.last_updated,
    is_enabled: childFilterData.feature_item_enabled,
    update_interval: childFilterData.update_interval,
  };
  return calcData;
};

export const parseChildFilterDataMapResponse = (
  childFilterDataMap: IChildFilterDataMapResponse,
): ICalculationDataDictionary => {
  const calcDataMap: ICalculationDataDictionary = {};

  for (const filterId in childFilterDataMap) {
    const childFilterData = childFilterDataMap[filterId];
    const calcData = parseChildFilterDataResponse(childFilterData);
    calcDataMap[childFilterData.calculation_uuid] = calcData;
  }

  return calcDataMap;
};

export const convertNewModalDataFormatToUsable = (
  modal_data?: IStringArrayMap,
): IStringMap[] | undefined => {
  if (!modal_data) return;

  const oldModalDataFormat: IStringMap[] = [];

  const rowCount = firstInObject(modal_data)?.length;
  if (rowCount === 0) return [];
  for (let i = 0; i < rowCount; i++) {
    const row: IStringMap = {};
    for (const col in modal_data) {
      row[col] = modal_data[col][i];
    }
    oldModalDataFormat.push(row);
  }

  return oldModalDataFormat;
};

/**
 * @param segments: Array of strings - The segments of the url
 * @description Takes a list of strings that are used for the sections of the breadcrumbs
 */
export const updateBreadcrumbItems = (
  segments: Array<string>,
): IBreadcrumbItem[] => {
  let breadcrumbItems: IBreadcrumbItem[] = [];
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (segment === '') {
      continue;
    }
    const url =
      '/' +
      segments
        .slice(0, i + 1)
        .join('/')
        .replace('/', '');
    // Capitalize the first letter of the segment and convert segments with dashes to spaces and capitalize
    const label = segment
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (l) => l.toUpperCase());

    breadcrumbItems.push({
      text: label,
      link: url,
    });
  }
  return breadcrumbItems;
};

/**
 * Converts CamelCase to UPPER_SNAKE_CASE
 * @param camelCase CamelCaseString
 * @returns SNAKE_CASE_STRING
 */
export const toUpperSnakeCase = (camelCase): string => {
  let foundMatch = true;
  while (foundMatch) {
    let prev: string = null;
    let i = 0;
    foundMatch = false;
    for (let char of camelCase) {
      i++;
      if (
        prev !== null &&
        prev.toLowerCase() === prev &&
        char.toUpperCase() === char &&
        prev !== '_' &&
        char !== '_' &&
        !(prev.toUpperCase() === prev && char.toLowerCase() === char)
      ) {
        camelCase =
          camelCase.slice(0, i - 1).toLowerCase() +
          '_' +
          camelCase.slice(i - 1);
        foundMatch = true;
        prev = char;
        break;
      }
      prev = char;
    }
  }
  return camelCase.toUpperCase();
};

export const findAndRemoveItemFromArray = <T>(item: T, arr: T[]): T[] => {
  const index = arr.indexOf(item);
  if (index === -1) return arr;
  arr.splice(index, 1);
  return arr;
};

/** Custom password strength validator */
export const getPasswordStrengthValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    const isAtLeast8CharsLong = value.length > 7;
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSymbol = /[!@#$%^&*()_+]+/.test(value);

    const passwordValid =
      isAtLeast8CharsLong &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumeric &&
      hasSymbol;
    // const passwordValid = isAtLeast8CharsLong;

    if (passwordValid) return null;

    const errors: ValidationErrors = {};

    if (!isAtLeast8CharsLong) errors.isLessThan8CharsLong = true;
    if (!hasUpperCase) errors.isMissingUpperCase = true;
    if (!hasLowerCase) errors.isMissingLowerCase = true;
    if (!hasNumeric) errors.isMissingNumber = true;
    if (!hasSymbol) errors.isMissingSymbol = true;

    return errors;
  };
};

/**
 * Checks if the array contains any of the days of the week
 */
export const containsDowOrMonth = (arr: string[]): boolean => {
  return arr.some((item) => item in dowAndMonthOrder);
};

/**
 * @description First, sort the data by day of the week, month of year, then anything else
 * gets sorted in ascending order
 * @param data
 * @param sortDirection
 */
export const sortByDowOrMonth = (
  data: any[],
  sortDirection: SortingType,
  sortColumn: string = null,
): any[] => {
  const otherData = data.filter((item) => !(item in dowAndMonthOrder));
  const daysOfWeekData = data.filter((item) => item in dowAndMonthOrder);
  let sortedDaysOfWeekData;
  let sortedOtherData;
  sortedDaysOfWeekData = daysOfWeekData.sort((a, b) => {
    let aVal, bVal;
    if (sortColumn) {
      aVal = a[sortColumn];
      bVal = b[sortColumn];
    } else {
      aVal = a;
      bVal = b;
    }
    return dowAndMonthOrder[aVal] - dowAndMonthOrder[bVal];
  });
  sortedOtherData = otherData.sort((a, b) => a.localeCompare(b));
  if (sortDirection === 'dowMonthDesc') {
    sortedDaysOfWeekData.reverse();
    sortedOtherData.reverse();
  }
  return [...sortedDaysOfWeekData, ...sortedOtherData];
};
