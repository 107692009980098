import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IDataStreamColumn,
  IPandasFilterItemV2,
  IPandasFilterV2,
} from '@interfaces';
import { NbDialogConfig, NbDialogService } from '@nebular/theme';
import { defaultPandasFilterItemV2 } from '@root/custom_scripts/config';
import { rrulestr } from 'rrule';
import { v4 } from 'uuid';
import { TimeDelta } from '../../custom_scripts/TimeDeltaStuff';
import { AdvancedValueV2DialogComponent } from './advanced-value-dialog/advanced-value-dialog.component';
import { CountGroupingV2DialogComponent } from './count-grouping-dialog/count-grouping-dialog.component';
import { ForceDatatypeV2DialogComponent } from './force-datatype-dialog/force-datatype-dialog.component';
import { Store } from '@ngxs/store';
import { IAppStateModel } from '@root/state/app.model';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '@base-component';
import { betweenDatesPreview } from './advanced-value-dialog/helpers';

interface IParenthesesColor {
  [parenthesesUuid: string]: string;
}

@Component({
  selector: 'resplendent-row-filter-gui-v2',
  templateUrl: './row-filter-gui-v2.component.html',
  styleUrls: ['./row-filter-gui-v2.component.scss'],
})
export class RowFilterGuiV2Component
  extends BaseComponent
  implements OnDestroy, OnChanges
{
  @Input() filterObject: IPandasFilterV2;
  @Input() columns: IDataStreamColumn[];
  @Input() emitOnDestroy = true;

  columnsMap: { [key: string]: IDataStreamColumn } = {};

  columnDtypesMap: { [key: string]: string } = {};
  columnLabelsMap: { [key: string]: string } = {};
  simpleColumns: string[] = [];

  @Output() destroyed = new EventEmitter<IPandasFilterV2>();
  @Output() filterChanged = new EventEmitter<IPandasFilterV2>();

  FiltersAreValid = false;

  logic_operator_counter = 0;

  filterItemBeingDragged;
  DragX;
  DragY;

  AdvancedValuePreviews: Record<string, string> = {};

  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;

  OperatorsListOriginal = [
    'Contains',
    '=',
    '!=',
    '<',
    '<=',
    '>',
    '>=',
    'Starts With',
    'Ends With',
  ];

  OperatorsList = JSON.parse(JSON.stringify(this.OperatorsListOriginal));
  parenthesesColors = ['#94cbff', '#8cfac7', '#ffe59e', '#ffa8b4'];
  colorToParenthesesMap: IParenthesesColor = {};
  firstLoad = true;

  constructor(private dialog: NbDialogService, private store: Store) {
    super();
  }

  ngOnDestroy() {
    if (this.emitOnDestroy) {
      if (!this.validateFilter()) return;
      this.destroyed.emit(this.filterObject);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.columns !== undefined &&
      changes.columns.currentValue !== changes.columns.previousValue
    ) {
      this.columnsMap = {};
      this.simpleColumns = [];
      for (let column of this.columns) {
        this.columnsMap[column.name] = column;
        this.columnDtypesMap[column.name] = column.dataType;
        this.columnLabelsMap[column.name] = column.alias;
        this.simpleColumns.push(column.name);
      }
      if (this.filterObject) this.updateAdvancedValuePreviews();
      this.colorForParentheses();
    }
  }

  colorForParentheses() {
    const lengthOfFilterObjectItems = this.filterObject.items.length;
    for (
      let filterIndex = 0;
      filterIndex < lengthOfFilterObjectItems;
      filterIndex++
    ) {
      const filterItem = this.filterObject.items[filterIndex];
      if (
        this.filterObject.parentheses.left_parentheses[filterItem.index]
          .length === 0
      )
        continue;
      // Parentheses in reverse order
      const parentheses =
        this.filterObject.parentheses.left_parentheses[filterItem.index];
      for (let i = 0; i < parentheses.length; i++) {
        const uuid =
          this.filterObject.parentheses.left_parentheses[filterItem.index][i];
        this.colorToParenthesesMap[uuid] =
          this.parenthesesColors[
            (i + filterIndex) % this.parenthesesColors.length
          ];
      }
    }
  }

  /**
   * @description This function highlights the related parentheses when the user hovers over a
   * parentheses.
   */
  public highlightRelatedParentheses(
    parenthesesUuid: string,
    isHovering: boolean,
  ) {
    const lengthOfFilterObjectItems = this.filterObject.items.length;
    for (
      let filterIndex = 0;
      filterIndex < lengthOfFilterObjectItems;
      filterIndex++
    ) {
      const filterItem = this.filterObject.items[filterIndex];
      if (
        this.filterObject.parentheses.left_parentheses[filterItem.index]
          .length === 0
      )
        continue;
      // Parentheses in reverse order
      const parentheses =
        this.filterObject.parentheses.left_parentheses[filterItem.index];
      for (let i = 0; i < parentheses.length; i++) {
        const uuid =
          this.filterObject.parentheses.left_parentheses[filterItem.index][i];
        if (uuid === parenthesesUuid) {
          this.colorToParenthesesMap[uuid] = isHovering
            ? '#f0f0f0'
            : this.parenthesesColors[
                (i + filterIndex) % this.parenthesesColors.length
              ];
        }
      }
    }
  }
  copyFilterItem(filterItem: IPandasFilterItemV2) {
    let newFilterItem: IPandasFilterItemV2 = JSON.parse(
      JSON.stringify(filterItem),
    );
    newFilterItem.index = this.filterObject.items.length;
    this.filterObject.parentheses.left_parentheses.push([]);
    this.filterObject.parentheses.right_parentheses.push([]);
    this.filterObject.items.push(newFilterItem);
    this.reIndex();
    this.validateFilter();
  }

  setAdvancedValue(index, col: IDataStreamColumn) {
    let dtype = col.dataType;
    if (
      dtype === 'datetime' ||
      dtype === 'date' ||
      dtype === 'timedelta' ||
      dtype === 'bool'
    )
      this.filterObject.items[index].useAdvanced = true;
    if (dtype === 'datetime' || dtype === 'date') {
      this.filterObject.items[index].advancedValueType = 'between_dates';
    } else if (dtype === 'timedelta') {
      this.filterObject.items[index].advancedValueType = 'timedelta';
    } else if (dtype === 'bool') {
      this.filterObject.items[index].advancedValueType = 'boolean';
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.filterObject.items,
      event.previousIndex,
      event.currentIndex,
    );
    this.reIndex();
    this.validateFilter();
  }

  addParentheses(index) {
    let uuid = v4();
    this.filterObject.parentheses.left_parentheses[index].unshift(uuid);
    this.filterObject.parentheses.right_parentheses[index].unshift(uuid);
    this.colorForParentheses();
    this.validateFilter();
  }
  deleteParentheses(uuid) {
    for (let index in this.filterObject.items) {
      let index1 =
        this.filterObject.parentheses.left_parentheses[index].indexOf(uuid);
      let index2 =
        this.filterObject.parentheses.right_parentheses[index].indexOf(uuid);
      if (index1 != -1) {
        this.filterObject.parentheses.left_parentheses[index].splice(index1, 1);
      }
      if (index2 != -1) {
        this.filterObject.parentheses.right_parentheses[index].splice(
          index2,
          1,
        );
      }
    }
    this.colorForParentheses();
    this.validateFilter();
  }
  moveParentheses(filterItem, direction, key) {
    if (key === 'left_parentheses') {
      if (direction === 'left') {
        this.filterObject.parentheses.left_parentheses[
          filterItem.index - 1
        ].push(
          this.filterObject.parentheses.left_parentheses[
            filterItem.index
          ].splice(
            this.filterObject.parentheses.left_parentheses[filterItem.index]
              .length - 1,
            1,
          )[0],
        );
      } else {
        if (
          this.filterObject.parentheses.right_parentheses[
            filterItem.index
          ].indexOf(
            this.filterObject.parentheses.left_parentheses[filterItem.index][
              this.filterObject.parentheses.left_parentheses[filterItem.index]
                .length - 1
            ],
          ) === -1
        ) {
          this.filterObject.parentheses.left_parentheses[
            filterItem.index + 1
          ].push(
            this.filterObject.parentheses.left_parentheses[
              filterItem.index
            ].splice(
              this.filterObject.parentheses.left_parentheses[filterItem.index]
                .length - 1,
              1,
            )[0],
          );
        }
      }
    } else if (key === 'right_parentheses') {
      if (direction === 'right') {
        this.filterObject.parentheses.right_parentheses[
          filterItem.index + 1
        ].push(
          this.filterObject.parentheses.right_parentheses[
            filterItem.index
          ].splice(
            this.filterObject.parentheses.right_parentheses[filterItem.index]
              .length - 1,
            1,
          )[0],
        );
      } else {
        if (
          this.filterObject.parentheses.left_parentheses[
            filterItem.index
          ].indexOf(
            this.filterObject.parentheses.right_parentheses[filterItem.index][
              this.filterObject.parentheses.right_parentheses[filterItem.index]
                .length - 1
            ],
          ) === -1
        ) {
          this.filterObject.parentheses.right_parentheses[
            filterItem.index - 1
          ].push(
            this.filterObject.parentheses.right_parentheses[
              filterItem.index
            ].splice(
              this.filterObject.parentheses.right_parentheses[filterItem.index]
                .length - 1,
              1,
            )[0],
          )[filterItem.index + 1];
        }
      }
    }
    this.colorForParentheses();
    this.validateFilter();
  }
  numberEntryFormat(string, allowDecimal = false) {
    //this function is used for a bunch of the number entries for custom validation
    let alreadyAllowedDecimal = false;
    let charList = string.split('');
    let indexToDeleteList = [];
    for (let charIndex in charList) {
      if (
        (isNaN(charList[charIndex]) && charList[charIndex] != '-') ||
        (charList[charIndex] === '-' && parseInt(charIndex) != 0)
      ) {
        if (
          allowDecimal &&
          charList[charIndex] === '.' &&
          !alreadyAllowedDecimal
        ) {
          alreadyAllowedDecimal = true;
        } else {
          indexToDeleteList.push(charIndex);
        }
      }
    }
    indexToDeleteList.reverse();
    for (let index of indexToDeleteList) {
      charList.splice(index, 1);
    }
    return charList.join('');
  }

  reIndex() {
    for (let index in this.filterObject.items) {
      this.filterObject.items[index].index = parseInt(index);
    }
  }

  addComparison() {
    //this adds a comparison box to the list
    this.filterObject.parentheses.left_parentheses.push([]);
    this.filterObject.parentheses.right_parentheses.push([]);
    this.filterObject.items.push(
      JSON.parse(JSON.stringify(defaultPandasFilterItemV2)),
    );
    this.reIndex();
    this.validateFilter();
  }

  deleteComparison(filter_index) {
    //this deletes a comparison box from the list and its associated logical operator
    if (this.filterObject.items.length > 1) {
      if (filter_index != -1) {
        //delete parentheses that are attached to this comparison
        let uuidsToDelete = [];
        if (
          this.filterObject.parentheses.left_parentheses[filter_index] !=
          undefined
        ) {
          for (let uuid of this.filterObject.parentheses.left_parentheses[
            filter_index
          ]) {
            uuidsToDelete.push(uuid);
          }
        }
        if (
          this.filterObject.parentheses.right_parentheses[filter_index] !=
          undefined
        ) {
          for (let uuid of this.filterObject.parentheses.right_parentheses[
            filter_index
          ]) {
            if (uuidsToDelete.indexOf(uuid) == -1) {
              uuidsToDelete.push(uuid);
            }
          }
        }

        for (let uuid of uuidsToDelete) {
          this.deleteParentheses(uuid);
        }

        //delete comparison
        this.filterObject.items.splice(filter_index, 1);

        //delete parentheses lists
        this.filterObject.parentheses.left_parentheses.splice(filter_index, 1);
        this.filterObject.parentheses.right_parentheses.splice(filter_index, 1);
      }
      this.reIndex();
      this.validateFilter();
      // Update the advanced value previews since the indexes have changed
      this.updateAdvancedValuePreviews();
    }
  }

  private isNumeric(str: string) {
    if (typeof str !== 'string') return false;
    return !isNaN(parseFloat(str));
  }

  validateFilter() {
    // Check if the filters are valid and set outline color accordingly
    let allFiltersValid = true;
    for (let index in this.filterObject.items) {
      const filterItem = this.filterObject.items[index];
      const column = filterItem.column;
      const columnDtype = this.columnsMap[column]?.dataType;
      const comparison = filterItem.comparison;
      const value = filterItem.value;
      const advancedValue = filterItem.advancedValue;
      const useAdvanced = filterItem.useAdvanced;
      const advancedValueType = filterItem.advancedValueType;

      const isColumnInvalid =
        !column || Object.keys(this.columnsMap).indexOf(column) === -1;
      const isComparisonInvalid = !comparison;
      const isValueInvalid = value === undefined && !useAdvanced;
      const isFloatIntValueInvalid =
        (columnDtype === 'float' || columnDtype === 'int') &&
        value !== undefined &&
        !this.isNumeric(value) &&
        !useAdvanced;
      const isDatetimeDateValueInvalid =
        (columnDtype === 'datetime' || columnDtype === 'date') &&
        (value === undefined || isNaN(Date.parse(value))) &&
        !useAdvanced;
      const isAdvancedValueInvalid =
        !advancedValue && useAdvanced && advancedValueType !== 'null';

      const isFilterInvalid =
        isColumnInvalid ||
        isComparisonInvalid ||
        isValueInvalid ||
        isFloatIntValueInvalid ||
        isDatetimeDateValueInvalid ||
        isAdvancedValueInvalid;

      this.filterObject.items[index].valid = isFilterInvalid
        ? '#ff3d71'
        : '#00d68f';
      allFiltersValid = allFiltersValid && !isFilterInvalid;
    }

    this.FiltersAreValid = allFiltersValid;
    if (allFiltersValid && !this.firstLoad) {
      this.filterChanged.emit(this.filterObject);
    } else if (this.firstLoad) {
      this.firstLoad = false;
    }
    return allFiltersValid;
  }

  updateAdvancedValuePreviews() {
    for (let filterItem of this.filterObject.items) {
      if (filterItem.useAdvanced) {
        try {
          this.AdvancedValuePreviews[filterItem.index] =
            this.getAdvancedValuePreview(filterItem);
        } catch (err) {
          console.error(err);
        }
      } else {
        this.AdvancedValuePreviews[filterItem.index] = '';
      }
    }
  }

  getAdvancedValuePreview(filterItem) {
    const advancedValueType = filterItem.advancedValueType;
    if (advancedValueType === 'datetime' || advancedValueType === 'date') {
      return this.getDateTimeAdvancedValuePreview(filterItem);
    } else if (advancedValueType === 'column') {
      return this.getColumnAdvancedValuePreview(filterItem);
    } else if (advancedValueType == 'null') {
      return this.getNullAdvancedValuePreview(filterItem);
    } else if (advancedValueType == 'boolean') {
      return this.getBooleanAdvancedValuePreview(filterItem);
    } else if (advancedValueType == 'timedelta') {
      return this.getTimeDeltaAdvancedValuePreview(filterItem);
    } else if (advancedValueType == 'between_dates') {
      return this.getBetweenDatesAdvancedValuePreview(filterItem);
    } else if (advancedValueType == 'filter_variable') {
      return this.getFilterVariableAdvancedValuePreview(filterItem);
    }
  }

  getDateTimeAdvancedValuePreview(filterItem) {
    const advancedValue = filterItem.advancedValue;
    if (advancedValue.time_type === 'current_time') {
      let offset = advancedValue.offset;
      return (
        (offset >= 0 ? 'Now + ' : 'Now ') + new TimeDelta(offset).toReadable()
      );
    } else if (advancedValue.time_type === 'relative_time') {
      return (
        advancedValue.beginning_or_end +
        ' of the ' +
        advancedValue.between_dates.start_unit +
        ' ' +
        advancedValue.between_dates.start_offset
      );
    } else {
      return advancedValue.time;
    }
  }

  getColumnAdvancedValuePreview(filterItem) {
    const advancedValue = filterItem.advancedValue;
    let preview =
      this.columnLabelsMap[advancedValue.column] !== null
        ? this.columnLabelsMap[advancedValue.column]
        : advancedValue.column;

    if (advancedValue.force_datatype != 'none' && advancedValue.use_offset) {
      if (
        advancedValue.force_datatype === 'datetime' ||
        advancedValue.force_datatype === 'date'
      ) {
        // Do nothing
      } else if (advancedValue.force_datatype === 'string') {
        preview = preview + ' + ' + advancedValue.offset;
      } else {
        preview =
          preview +
          (advancedValue.offset >= 0
            ? ' + ' + advancedValue.offset
            : ' - ' + -advancedValue.offset);
      }
    }
    return preview;
  }

  getNullAdvancedValuePreview(filterItem) {
    this.filterObject.items[filterItem.index].comparison = '=';
    return 'NULL';
  }

  getBooleanAdvancedValuePreview(filterItem) {
    this.filterObject.items[filterItem.index].comparison = '=';
    return filterItem.advancedValue.value + '';
  }

  getTimeDeltaAdvancedValuePreview(filterItem) {
    return new TimeDelta(filterItem.advancedValue.offset).toReadable();
  }

  getBetweenDatesAdvancedValuePreview(filterItem) {
    this.filterObject.items[filterItem.index].comparison = '=';
    return betweenDatesPreview(filterItem.advancedValue);
  }

  private getFilterVariableAdvancedValuePreview(filterItem) {
    const filterVariableId = filterItem.advancedValue.variable_id;
    const filterVariable = this.store.selectSnapshot((state) =>
      (
        state.app as IAppStateModel
      ).appState.filterVariableState.filterVariables.find(
        (filterVariable) => filterVariable.id === filterVariableId,
      ),
    );

    if (!filterVariable) {
      // this.getFilterVariables();
      return 'Variables not loaded';
    }

    return filterVariable.name;
  }

  openForceDatatypeDialog(filterItem: IPandasFilterItemV2) {
    //open the grouping options dialog
    let config = new NbDialogConfig({});
    config.context = {
      value: filterItem.forceDatatype,
    };
    const dialogRef = this.dialog.open(ForceDatatypeV2DialogComponent, config);
    dialogRef.componentRef.instance.valueChange
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value) => (filterItem.forceDatatype = value));
  }

  openGroupingOptionsDialog(filterItem: IPandasFilterItemV2) {
    //open the grouping options dialog
    let config = new NbDialogConfig({});
    config.context = {
      filterItem: filterItem,
      columns: this.columns,
    };
    const dialogRef = this.dialog.open(CountGroupingV2DialogComponent, config);
    dialogRef.componentRef.instance;
  }

  async openAdvancedValueDialog(filterItem: IPandasFilterItemV2) {
    //open the advanced value dialog
    let config = new NbDialogConfig({});
    config.context = {
      filterItem: filterItem,
      columns: this.columns,
    };
    const ref = this.dialog.open(AdvancedValueV2DialogComponent, config);
    ref.onClose.subscribe(() => {
      // Update the filter item comparison if the advanced value was set and the comparison is not set
      if (!filterItem.comparison) {
        if (filterItem.advancedValueType === 'filter_variable') {
          filterItem.comparison = '=';
        }
      }
      this.updateAdvancedValuePreviews();
      this.validateFilter();
    });
  }

  handleColumnSelection(filterItem: IPandasFilterItemV2) {
    const column = filterItem.column;
    const columnDtype = this.columnsMap[column]?.dataType;
    if (
      columnDtype === 'datetime' ||
      columnDtype === 'date' ||
      columnDtype === 'timedelta' ||
      columnDtype === 'bool'
    ) {
      filterItem.useAdvanced = true;
    } else {
      filterItem.useAdvanced = false;
    }
    this.validateFilter();
  }
}
