import { ICustomLabelAndColor, ITableModalDataV2 } from '@interfaces';
import { renderModalCellV2 } from '@pages/dash-v2/helpers';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';
import { GroupCellRendererParams } from 'ag-grid-community';

export async function buildModalData(
  widget: WidgetStateObject,
  globalLabelColors: ICustomLabelAndColor,
) {
  const calcArray = widget.calcArray;
  const gotSomethingToShowInModal = calcArray.length > 0;
  if (!gotSomethingToShowInModal) return;

  const modalDataList: ITableModalDataV2[] = [];
  const widgetColors = widget.getAllColors();

  await Promise.all(
    calcArray.map(async (calculation, i) => {
      const calcData = calculation.getActiveData();
      const calcColors = widgetColors[calculation.id];
      if (!calcData) return;
      const links = calcData.column_links;
      const calcId = calculation.activeCalcDataId;
      let subtitle = calculation.name;
      if (calcData && calcData.modal_data) {
        const selectedColumns = calculation.getSelectedColumns();

        let agColumnDefs = [];
        if (calcData.modal_data && calcData.modal_data[0]) {
          selectedColumns.forEach((col) => {
            agColumnDefs.push({
              cellRenderer: (params: GroupCellRendererParams) => {
                return renderModalCellV2(
                  params.data,
                  params.colDef.field,
                  calculation,
                );
              },
              headerName: calcData.column_labels[col],
              field: col,
              sortable: true,
            });
          });
        }

        if (calcData.modal_data.length > 0) {
          // Handle single value calculations
          const modalData = calcData.modal_data;
          let color = globalLabelColors?.[calculation.name];
          let colorMap;
          let segregationColumn;
          if (calcData.is_category_x_axis) {
            colorMap = calcColors;
            segregationColumn = calculation.xAxis;
          } else if (!color && calcColors?.[calculation.name]) {
            color = calcColors?.[calculation.name];
          } else if (calcData.x_groupings && calcData.x_groupings.length > 0) {
            // Temporary until uses can color by groupings
            color = calcColors[calcData.x_groupings[0]];
          }
          modalDataList.push({
            dataTitles: selectedColumns,
            data: modalData,
            delimiter: calcData.delimiter,
            agColumnDefs,
            subtitle,
            color,
            colorMap,
            links,
            stack: i,
            calcId,
            xAxisDataType: 'string',
            segregationColumn,
          });
        }
      } else {
        // Handle missing data
        modalDataList.push({
          dataTitles: ['No data for this element'],
          data: [],
          delimiter: null,
          agColumnDefs: [],
          subtitle,
          color: 'red',
          stack: i,
          calcId,
          xAxisDataType: 'string',
        });
      }
    }),
  );
  return modalDataList;
}
export function determineTextColor(backgroundHex: string): 'white' | 'black' {
  const hex = backgroundHex.replace('#', '');
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}
