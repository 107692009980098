import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICalculationStatusV2, StatusDotColor } from '@interfaces';
import { Store } from '@ngxs/store';
import { BaseComponent } from '@root/core-components/base-component';
import { IAppStateModel } from '@root/state/app.model';
import { BehaviorSubject, combineLatest, interval, Observable } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

@Component({
  selector: 'resplendent-widget-data-indicator',
  templateUrl: './widget-data-indicator.component.html',
  styleUrls: ['./widget-data-indicator.component.scss'],
})
export class WidgetDataIndicatorComponent
  extends BaseComponent
  implements OnInit
{
  @Input() widgetId: string;
  @Input() nextPlayThreshold: Date | null = null;
  @Output() emitResubAttempt = new EventEmitter<string[]>();

  widget$ = this.store
    .select(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    )
    .pipe(filter((v) => !!v));

  public dotColor: StatusDotColor = StatusDotColor.green;

  private calculationStatusesSource$ = new BehaviorSubject<
    ICalculationStatusV2[]
  >([]);
  public calculationStatuses$: Observable<ICalculationStatusV2[]> =
    this.calculationStatusesSource$.asObservable();

  public graphStatusColor: StatusDotColor = StatusDotColor.red;
  public graphIsDead: boolean = false;

  private reSubCounts: {
    [key: string /** Active calc/filter id */]: {
      attempts: number;
      lastAttempt: number;
    };
  } = {};

  ticker$ = interval(60000).pipe(map((_) => null));

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    if (!this.widgetId)
      throw new Error('Need a widget id to initialize data indicator');

    this.setCalcStatusesOnGraphUpdate();
    this.attemptReSubOnBadStatus();
  }

  private generateUpdateIntervalTooltip(updateIntervalSeconds: number): string {
    // If the update interval is between 1 and 59 minutes, show the minutes
    if (updateIntervalSeconds >= 60 && updateIntervalSeconds < 3600) {
      if (updateIntervalSeconds === 60) {
        return 'Updates every minute';
      }
      return `Updates every ${Math.floor(updateIntervalSeconds / 60)} minutes`;
    } else if (updateIntervalSeconds >= 3600) {
      // If the update interval is 1 hour or more, show the hours. If it's not a whole hour, show the minutes as well
      const hours = Math.floor(updateIntervalSeconds / 3600);
      const minutes = Math.floor((updateIntervalSeconds % 3600) / 60);
      return `Updates every ${hours} hour${hours > 1 ? 's' : ''}${
        minutes > 0 ? ` and ${minutes} minute${minutes > 1 ? 's' : ''}` : ''
      }`;
    }
  }

  /**
   * React to updates to the clock and graph observable and recalculate the status values on any change
   */
  private setCalcStatusesOnGraphUpdate() {
    const clockAndGraph$ = combineLatest([
      this.ticker$.pipe(startWith(null)),
      this.widget$.pipe(startWith(null)),
    ]);
    clockAndGraph$
      .pipe(
        throttleTime(250, undefined, { trailing: true }),
        map(([_, widget]) => widget),
        filter((x) => !!x),
        tap(async (widget) => {
          const calculations = widget.calcArray;
          let redCount = 0;
          let greenCount = 0;
          const filteredCalculations = calculations.filter(
            (calc) => !!calc.getActiveData(),
          );
          const calcStatuses: ICalculationStatusV2[] = await Promise.all(
            filteredCalculations.map(async (calculation) => {
              const id = calculation.id;
              const calcDataId = calculation.activeCalcDataId;
              const isFilterData = id === calcDataId;
              const activeCalcData = calculation.getActiveData();
              const latestDataUpdate = activeCalcData.last_updated;
              const calcUpdateInterval = activeCalcData.update_interval;
              // Make the dot red when the data is stale depending on its update interval
              let updateInterval = activeCalcData.update_interval
                ? activeCalcData.update_interval
                : 60;
              const updateIntervalTooltip =
                this.generateUpdateIntervalTooltip(updateInterval);
              updateInterval *= 1000;
              // It can be up to 2 minutes late
              const maxGood = updateInterval + 120000;
              // It can be up to 5 minutes late
              const minBad = updateInterval + 300000;
              const now = Date.now();
              const timePassedSinceUpdate = now - latestDataUpdate;
              const title = calculation.name;
              let color: StatusDotColor = StatusDotColor.red;
              if (timePassedSinceUpdate < maxGood) {
                color = StatusDotColor.green;
                greenCount++;
              } else if (timePassedSinceUpdate < minBad) {
                color = StatusDotColor.orange;
              } else {
                color = StatusDotColor.red;
                redCount++;
              }
              const isDead = color === StatusDotColor.red;
              const parentDataId = calculation.dataStreamId;
              return {
                id,
                parentDataId,
                calcDataId,
                isFilterData,
                latestDataUpdate,
                color,
                title,
                isDead,
                updateInterval: calcUpdateInterval,
                updateIntervalTooltip: updateIntervalTooltip,
              };
            }),
          );
          this.calculationStatusesSource$.next(calcStatuses);

          // Graph color is red/green if all of the calculations are red/green, any combination of statuses is treated
          // as orange status for the graph
          const allCalcAreRed = redCount === calcStatuses.length;
          const allCalcAreGreen = greenCount === calcStatuses.length;
          let graphColor: StatusDotColor;
          let isDead = false;
          if (allCalcAreRed) {
            graphColor = StatusDotColor.red;
            isDead = true;
          } else if (allCalcAreGreen) {
            graphColor = StatusDotColor.green;
          } else {
            graphColor = StatusDotColor.orange;
          }
          this.graphStatusColor = graphColor;
          this.graphIsDead = isDead;
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
  }

  /**
   * Attempt to resubscribe any metrics that are red and orange. We don't want to spam the server so we send out
   * requests less and less frequent for metrics that are flat out broken.
   * - Maintain a data structure in state tracking what ids are broken and how many requests were sent out for each one
   * - Send out a request 1 minute after it was detected as red/orange
   * - Send out the next request but take 28% longer than the previous one, this will mean that:
   *   - Request 8 will be 5 minutes after request 7
   *   - Request 28 will be 13 hours after request 27
   * - Once 30 requests were sent for a particular metric quit attempting it until the component re-initializes
   */
  private attemptReSubOnBadStatus() {
    this.ticker$
      .pipe(
        switchMap(() => this.calculationStatuses$),
        tap((statuses) => {
          const broken = statuses.filter((s) => s.color === StatusDotColor.red);
          const now = Date.now();
          const toAttempt: string[] = [];
          const hadToResub = { ...this.reSubCounts };
          broken.forEach((status) => {
            const id = status.calcDataId;
            delete hadToResub[id];
            let reSubCount = this.reSubCounts[id];
            if (!reSubCount) {
              this.reSubCounts[id] = { attempts: 0, lastAttempt: now };
              reSubCount = this.reSubCounts[id];
            }

            reSubCount.attempts++;
            reSubCount.lastAttempt = now;
            toAttempt.push(id);
          });

          // If we didn't interact with an id it means that it's green, so we need to restart its timer in
          // case it fails update again
          const noResubNeededAnymore = Object.keys(hadToResub);
          noResubNeededAnymore.forEach((id) => delete this.reSubCounts[id]);

          if (toAttempt.length > 0) {
            this.emitResubAttempt.emit(toAttempt);
            toAttempt.forEach((id) => {
              const x = this.reSubCounts[id];
            });
          }
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe();
  }
}
