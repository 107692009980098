import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  templateUrl: './base.component.html',
})
export class BaseComponent implements OnDestroy {
  protected isDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private isLoadingSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$ = this.isLoadingSource$.asObservable();

  public ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  public log(value) {
    console.log(value);
  }

  protected startLoading() {
    this.isLoadingSource$.next(true);
  }

  protected stopLoading() {
    this.isLoadingSource$.next(false);
  }

  protected get isLoading() {
    return this.isLoadingSource$.getValue();
  }
}
