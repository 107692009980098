import {
  parseCalculationDataUpdate,
  parseGetCalculationDataMapResponse,
} from '@helpers';
import {
  CalcDataSource,
  CalculationOperationType,
  DataStreamType,
  ICalculationData,
  ICalculationDataDictionary,
  ICalculationDataUpdate,
  ICalculationV2,
  IClientTable,
  IColumnInfo,
  IDataModifier,
  IGetCalculationDataResponse,
  IPandasFilterV2,
  ISimpleFilter,
  ITrendFreq,
  IWidgetConfig,
} from '@interfaces';
import { Store } from '@ngxs/store';
import { Widget } from '../app.actions';
import { IAppStateModel } from '../app.model';
import { VirtualColumnBase } from '@root/interface-registry/data-modifier.interface';

interface ICalculationStateObject extends ICalculationV2 {
  isLoading: boolean;
  calculationData?: ICalculationDataDictionary;
  activeCalcDataId: string;
  shareToken?: string;
}

export class CalculationStateObject implements ICalculationStateObject {
  // ICalculationV2
  id: string;
  dataStreamId: string;
  dataStreamType: DataStreamType;
  widgetGroupId: string;
  name: string;
  calculationType: string;
  calculationOperation: CalculationOperationType;
  calculationColumn?: string;
  useFilter: boolean;
  filter: {
    simpleFilter?: ISimpleFilter;
    pandasFilter?: IPandasFilterV2;
    advancedFilter: boolean;
  };
  includedColumns: string[];
  excludeColumnsMode: boolean;
  shareToken?: string;

  // standard only
  xAxis?: string;
  xAxis2?: string;
  delimiter2?: string;
  useCustomCalculationColumn?: boolean;
  customCalculationColumn?: VirtualColumnBase;
  // groupingCols?: string[];

  // snapshot only
  trendFreq?: ITrendFreq;
  delimiterEnabled?: boolean;
  delimiter?: string;
  percentFilter: IPandasFilterV2 | null;
  groupBy?: string;
  dataModifier?: IDataModifier;
  clientTable?: IClientTable;

  // Class specific
  isLoading: boolean;
  calcDataId: string;
  stateRef: Store;
  widgetId: string;
  // Store multiple instances of calculation data, one will be the main calc data and all the others child filter data
  calculationData?: ICalculationDataDictionary;

  constructor(
    inputObj: Partial<ICalculationStateObject>,
    store: Store,
    widgetId: string,
  ) {
    this.stateRef = store;
    this.widgetId = widgetId;
    for (const key of Object.keys(inputObj)) {
      this[key] = inputObj[key];
    }
    if (!this.calculationData) {
      this.calculationData = {};
    }
    if (!this.clientTable && !this.dataModifier) {
      this.setDataStreamInfo();
    }
    this.shareToken = store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.frontEndState.shareToken,
    );
  }

  get hasData() {
    const calcData = this.getActiveData();
    if (!calcData) return false;
    if (!calcData?.x || !calcData?.y) return false;
    return true;
  }

  get hasBrokenData() {
    const calcData = this.getActiveData();
    if (calcData.broken) return true;
    return false;
  }

  get columnInfo() {
    const columnInfo = this.loadColumnInfo();
    if (!columnInfo) throw new Error('No column info found for calculation');

    return columnInfo;
  }

  get hasColumnInfo() {
    const columnInfo = this.loadColumnInfo();
    return !!columnInfo;
  }

  // Gets the id of the active calculation.
  get activeCalcDataId() {
    return this.calcDataId;
  }

  /** Seg column is either groupBy for snapshot calcs or xAxis for standard calcs */
  get segregationColumn() {
    return this.groupBy ?? this.xAxis;
  }

  /**
   * @description Checks if the calculation has a valid internal filter.
   */
  get hasValidFilter() {
    if (!this.useFilter) return true;
    if (this.filter?.simpleFilter?.items.find((item) => item.value))
      return true;
    // The pandas filter is valid if all of the filter items valid property is #00d68f
    if (
      this.filter?.pandasFilter?.items.find((item) => item.valid !== '#00d68f')
    )
      return false;
    return true;
  }

  get hasModalData() {
    if (!this.hasData) return false;
    if (this.getActiveData().modal_data) return true;
    return false;
  }

  get filterVariableIds(): string[] {
    const filterVariableIds: string[] = [];
    if (!this.useFilter || !this.filter.pandasFilter?.items)
      return filterVariableIds;
    for (const item of this.filter.pandasFilter.items) {
      if (
        item?.advancedValueType === 'filter_variable' &&
        item?.advancedValue?.variable_id !== undefined
      ) {
        filterVariableIds.push(item.advancedValue.variable_id);
      }
    }
    return filterVariableIds;
  }

  private loadColumnInfo(): IColumnInfo[] | null {
    // If we don't have any column info, let's check if we have access to the data stream
    if (!this.dataModifier?.columnInfo && !this.clientTable?.columnInfo) {
      this.setDataStreamInfo();
    }
    let columnInfo: IColumnInfo[];
    if (this.dataStreamType === 'dataModifier') {
      columnInfo = this.dataModifier?.columnInfo;
    } else if (this.dataStreamType === 'table') {
      columnInfo = this.clientTable?.columnInfo;
    }
    if (!columnInfo) {
      return null;
    }
    return columnInfo;
  }

  /**
   *
   * @param calcDataId? If undefined, set the active calc data to the main calc data.
   * If a string, set the active calc data to the child filter data with that id.
   */
  public setCalcDataId(calcDataId: string) {
    // When we initially set the calc data id, we don't subscribe to the data yet.
    // We wait until the widget is ready to subscribe to the data.
    let readyToSubscribe = false;
    // Set the active calc data id if it's not already set
    if (!this.activeCalcDataId) {
      this.calcDataId = calcDataId;
    }
    // We don't need to unsubscribe from the current calc data if we are setting it to the same id
    if (calcDataId !== this.activeCalcDataId) {
      this.unsubscribeFromCalcData();
      readyToSubscribe = true;
    }

    if (readyToSubscribe) {
      this.calcDataId = calcDataId;
      this.subscribeToCalcData();
    }
  }

  private setDataStreamInfo() {
    // Try get the calculation's data stream
    const dataStream = this.stateRef.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.dataStreamsState?.dataStreams?.[
          this.dataStreamId
        ],
    );
    if (dataStream) {
      if (this.dataStreamType === 'table') {
        this.clientTable = {
          fkSourceUuid: dataStream.sourceUuid,
          pkTableUuid: dataStream.id,
          columnInfo: dataStream.columnDetails,
          customerUuid: '',
        };
      } else if (this.dataStreamType === 'dataModifier') {
        this.dataModifier = {
          broken: false,
          columnInfo: dataStream.columnDetails,
          customerUuid: '',
          dataFilter: null,
          datasetName: dataStream.title,
          dataUuid: dataStream.id,
          defaultTableFilter: false,
          tableUuid: dataStream.sourceUuid,
          calculationTemplates: [],
        };
      }
    }
  }

  private unsubscribeFromCalcData() {
    this.stateRef.dispatch(
      new Widget.UnsubscribeFromCalculationData(
        [this.activeCalcDataId],
        this.shareToken,
      ),
    );
  }

  private subscribeToCalcData() {
    this.stateRef.dispatch(
      new Widget.GetCalculationData(
        [this.activeCalcDataId],
        [this.widgetId],
        true,
        true,
        this.shareToken,
      ),
    );
  }

  public caresAboutCalcDataId(calcDataId: string) {
    return this.activeCalcDataId === calcDataId || this.id === calcDataId;
  }

  public getActiveData(): ICalculationData | undefined {
    return this.calculationData[this.activeCalcDataId];
  }

  public setCalcData(
    data:
      | ICalculationData
      | ICalculationDataDictionary
      | ICalculationDataUpdate
      | IGetCalculationDataResponse[],
    type: CalcDataSource,
  ) {
    let calcDataMap: ICalculationDataDictionary = {};
    // Process possible sources of calcData
    if (type === 'rawCalcDataDict') {
      calcDataMap = data as ICalculationDataDictionary;
    } else if (type === 'dataUpdate') {
      const calcData = parseCalculationDataUpdate(
        data as ICalculationDataUpdate,
      );
      if (!calcData) return;
      calcDataMap[calcData.calculation_uuid] = calcData;
    } else if (type === 'getCalcData') {
      const calcDatas = parseGetCalculationDataMapResponse(
        data as IGetCalculationDataResponse[],
      );
      calcDataMap = calcDatas;
    }
    // Make sure only calcs belonging to this calculation are added to the calculationData map
    const filteredIds = Object.keys(calcDataMap).filter(
      (id) =>
        !!calcDataMap[id] &&
        this.caresAboutCalcDataId(calcDataMap[id].calculation_uuid),
    );
    if (filteredIds.length === 0) return;
    const filteredCalcDataMap: ICalculationDataDictionary = {};
    filteredIds.forEach((id) => {
      filteredCalcDataMap[id] = calcDataMap[id];
      filteredCalcDataMap[id].dataSource = type;
    });
    this.calculationData = { ...this.calculationData, ...filteredCalcDataMap };
  }

  public reInitialize(inputCalc?: ICalculationV2) {
    let calculation: CalculationStateObject;
    if (inputCalc) {
      calculation = new CalculationStateObject(
        inputCalc,
        this.stateRef,
        this.widgetId,
      );
    } else {
      calculation = new CalculationStateObject(
        this,
        this.stateRef,
        this.widgetId,
      );
    }
    return calculation;
  }

  public getAllColumns() {
    const columnInfoList = this.columnInfo;
    return columnInfoList.map((col) => col.name);
  }

  public getColumnDataTypes() {
    const columnInfoList = this.columnInfo;
    const columnDataTypes: { [key: string]: string } = {};
    columnInfoList.forEach((col) => {
      columnDataTypes[col.name] = col.dataType;
    });
    return columnDataTypes;
  }

  public getSelectedColumns() {
    if (this.excludeColumnsMode) {
      return this.getAllColumns().filter(
        (col) => !this.includedColumns.includes(col),
      );
    }
    return this.includedColumns;
  }

  public toStorableCalculation(): ICalculationV2 {
    return {
      id: this.id,
      dataStreamId: this.dataStreamId,
      dataStreamType: this.dataStreamType,
      widgetGroupId: this.widgetGroupId,
      name: this.name,
      calculationType: this.calculationType,
      calculationOperation: this.calculationOperation,
      calculationColumn: this.calculationColumn,
      useFilter: this.useFilter && this.hasValidFilter,
      filter: this.filter,
      includedColumns: this.includedColumns,
      excludeColumnsMode: this.excludeColumnsMode,
      xAxis: this.xAxis,
      xAxis2: this.xAxis2,
      delimiter2: this.delimiter2,
      trendFreq: this.trendFreq,
      delimiterEnabled: this.delimiterEnabled,
      delimiter: this.delimiter,
      percentFilter: this.percentFilter,
      groupBy: this.groupBy,
      useCustomCalculationColumn: this.useCustomCalculationColumn,
      customCalculationColumn: this.customCalculationColumn,
      // dataModifier: this.dataModifier,
      // clientTable: this.clientTable,
    };
  }
}
