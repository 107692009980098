import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'resplendent-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
  @Input() public disabled = false;
}
