import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  NbDialogConfig,
  NbDialogService,
  NbPopoverDirective,
} from '@nebular/theme';
import {
  datatype_conversions,
  defaultPandasFilterV2,
  defaultColumnOperation,
  timezones,
} from '@root/custom_scripts/config';
import { TimeDelta } from '@root/custom_scripts/TimeDeltaStuff';
import { rrulestr } from 'rrule';
import { v4 } from 'uuid';
import { TimezoneDialogComponent } from './timezone-dialog/timezone-dialog.component';
import { IDataStreamColumn } from '@interfaces';
import { AdvancedValueV2DialogComponent } from '../row-filter-gui-v2/advanced-value-dialog/advanced-value-dialog.component';
import {
  ItemListType,
  VirtualColumn,
  VirtualColumnBase,
  VirtualColumnColumn,
  VirtualColumnType,
  VirtualColumnValue,
  vColDtypes,
} from '@root/interface-registry/data-modifier.interface';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NestedVirtualColumn } from './helpers';
import { StateService } from '@services';
import { deepEqual } from '@helpers';
import deepcopy from 'deepcopy';
import { FilterDialogComponent } from '@scams/components/filter-dialog/filter-dialog.component';

@Component({
  selector: 'resplendent-column-operation-gui',
  templateUrl: './column-operation-gui.component.html',
  styleUrls: ['./column-operation-gui.component.scss'],
})
export class ColumnOperationGuiComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() virtualColumn: VirtualColumn;
  @Input() columns: IDataStreamColumn[];
  @Input() showGrouping = true;
  @Input() aggregateMode = false;
  @Output() virtualColumnChange = new EventEmitter<VirtualColumnBase>();

  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;

  Filter: VirtualColumnBase;
  items: ItemListType[] = [];

  isAlive = true;

  logic_operator_counter = 0;
  allFiltersValid = true;

  itemBeingDragged: ItemListType;

  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;

  operators: string[][] = [];
  SpotTypes = {};
  colDtypes = {};

  userTimezone: string;
  timezones = timezones;

  grouping_descriptions = {
    first_match:
      'The first row that matches this filter will have the virtual column created from its values and the result will be used for all rows in the group',
  };

  typeLabels = {
    float: 'Float',
    int: 'Integer',
    string: 'Text',
    timedelta: 'Timedelta',
    datetime: 'DateTime',
    date: 'Date',
    boolean: 'Boolean',
  };
  dtypes: { value: vColDtypes; label: string }[] = [
    { value: 'string', label: 'Text' },
    { value: 'float', label: 'Float' },
    { value: 'int', label: 'Integer' },
  ];

  dtype_conversions = datatype_conversions;

  colNames = [];
  colAliases = {};

  moveMode = false;

  constructor(
    private dialog: NbDialogService,
    private stateService: StateService,
    private changeDetector: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    this.colNames = this.columns.map((col) => col.name);
    this.colDtypes = this.columns.reduce((acc, col) => {
      acc[col.name] = col.dataType;
      return acc;
    }, {});
    this.colAliases = this.columns.reduce((acc, col) => {
      acc[col.name] = !!col.alias ? col.alias : col.name;
      return acc;
    }, {});
    const filterObjectChange = changes.virtualColumn;
    /**
     * Call handleFilterData() asynchronously on initialization. Reason for calling it async
     * the first time is because OnChanges runs before OnInit, so some state might not be set
     * up fully before the method is called.
     */
    // if (filterObjectChange?.firstChange === true) {
    //   setTimeout(() => {
    //     this.handleFilterData();
    //   }, 0);
    // } else {
    //   this.handleFilterData();
    // }
  }
  ngOnInit() {
    if (this.stateService.sessionVariables.user.timezone) {
      this.userTimezone = this.stateService.sessionVariables.user.timezone;
    } else {
      this.userTimezone = 'UTC';
    }
    this.handleFilterData();
  }
  ngOnDestroy() {
    this.isAlive = false;
  }
  closePopovers() {
    this.popovers.forEach((popover) => popover.hide());
  }
  dragStart(item: ItemListType) {
    this.itemBeingDragged = item;
  }
  switchItems(item) {
    if (!this.itemBeingDragged || this.itemBeingDragged.index === item.index)
      return;
    // if (this.itemBeingDragged.index > item.index) {
    //   this.virtualColumn.pandasFilter.rightParentheses[item.index].forEach((uuid) =>
    //     this.moveParentheses(item, 'right', 'right_parentheses'),
    //   );
    // } else {
    //   this.virtualColumn.pandasFilter.rightParentheses[this.itemBeingDragged.index].forEach((uuid) =>
    //     this.moveParentheses(this.itemBeingDragged, 'left', 'right_parentheses'),
    //   );
    // }

    moveItemInArray(this.items, this.itemBeingDragged.index, item.index);
    this.validateFilter();
  }
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    setTimeout(() => {
      this.itemBeingDragged = undefined;
    }, 10);
  }
  colItem(item: ItemListType) {
    return item as VirtualColumnColumn;
  }
  valueItem(item: ItemListType) {
    return item as VirtualColumnValue;
  }
  typeItem(item: ItemListType) {
    return item as VirtualColumnType;
  }
  handleFilterData() {
    this.Filter = deepcopy(this.virtualColumn.pandasFilter);
    this.items = this.Filter.filter;
    if (this.Filter.grouping.filter == undefined) {
      this.Filter.grouping.filter = {
        pandas_filter: JSON.parse(JSON.stringify(defaultPandasFilterV2)),
      };
    }
    if (this.items.length == 0) {
      this.items.push({
        index: 0,
        type: 'column',
        valid: 'red',
        column: undefined,
        useOffset: false,
        offset: 0,
        logicOperator: '+',
        aggregate: this.aggregateMode,
        aggregateOperation: 'sum',
      });
    } else {
      if (this.aggregateMode) {
        this.items.forEach((item) => {
          if (item.type === 'column' && !item.aggregate) {
            item.aggregate = true;
            item.aggregateOperation = 'sum';
          }
        });
      }
    }
    this.validateFilter();
  }

  addParentheses(index) {
    let uuid = v4();
    this.Filter.leftParentheses[index].unshift(uuid);
    this.Filter.rightParentheses[index].unshift(uuid);
    setTimeout(() => {
      this.validateFilter();
    }, 0);
  }
  deleteParentheses(uuid) {
    for (let index in this.items) {
      let index1 = this.Filter.leftParentheses[index].indexOf(uuid);
      let index2 = this.Filter.rightParentheses[index].indexOf(uuid);
      if (index1 != -1) {
        this.Filter.leftParentheses[index].splice(index1, 1);
      }
      if (index2 != -1) {
        this.Filter.rightParentheses[index].splice(index2, 1);
      }
    }
    setTimeout(() => {
      this.validateFilter();
    }, 0);
  }
  moveParentheses(item: ItemListType, direction, key) {
    if (key == 'left_parentheses') {
      let parentheses = this.Filter.leftParentheses;
      if (direction == 'left') {
        parentheses[item.index - 1].push(
          parentheses[item.index].splice(
            parentheses[item.index].length - 1,
            1,
          )[0],
        );
      } else {
        if (
          this.Filter.rightParentheses[item.index].indexOf(
            parentheses[item.index][parentheses[item.index].length - 1],
          ) == -1
        ) {
          parentheses[item.index + 1].push(
            parentheses[item.index].splice(
              parentheses[item.index].length - 1,
              1,
            )[0],
          );
        }
      }
    } else if (key == 'right_parentheses') {
      let parentheses = this.Filter.rightParentheses;
      if (direction == 'right') {
        parentheses[item.index + 1].push(
          parentheses[item.index].splice(
            parentheses[item.index].length - 1,
            1,
          )[0],
        );
      } else {
        if (
          this.Filter.leftParentheses[item.index].indexOf(
            parentheses[item.index][parentheses[item.index].length - 1],
          ) == -1
        ) {
          parentheses[item.index - 1].push(
            parentheses[item.index].splice(
              parentheses[item.index].length - 1,
              1,
            )[0],
          )[item.index + 1];
        }
      }
    }
    setTimeout(() => {
      this.validateFilter();
    }, 0);
  }
  numberEntryFormat(string, allowDecimal = false) {
    //this function is used for a bunch of the number entries for custom validation
    let alreadyAllowedDecimal = false;
    let charList = string.split('');
    let indexToDeleteList = [];
    for (let charIndex in charList) {
      if (
        (isNaN(charList[charIndex]) && charList[charIndex] != '-') ||
        (charList[charIndex] == '-' && parseInt(charIndex) != 0)
      ) {
        if (
          allowDecimal &&
          charList[charIndex] == '.' &&
          !alreadyAllowedDecimal
        ) {
          alreadyAllowedDecimal = true;
        } else {
          indexToDeleteList.push(charIndex);
        }
      }
    }
    indexToDeleteList.reverse();
    for (let index of indexToDeleteList) {
      charList.splice(index, 1);
    }
    return charList.join('');
  }

  reIndex() {
    for (let index in this.items) {
      this.items[index].index = parseInt(index);
    }
  }
  add_object(object_type) {
    this.Filter.leftParentheses.push([]);
    this.Filter.rightParentheses.push([]);
    switch (object_type) {
      case 'column':
        this.items.push({
          index: this.items.length,
          type: 'column',
          valid: 'red',
          column: undefined,
          useOffset: false,
          offset: 0,
          timezone: 'UTC',
          logicOperator: '+',
          aggregate: this.aggregateMode,
          aggregateOperation: 'sum',
        });
        break;
      case 'type':
        this.items.push({
          index: this.items.length,
          type: 'type',
          valid: 'red',
          convertToType: 'string',
          units: 'None',
          timezone: 'UTC',
        });
        break;
      case 'value':
        this.items.push({
          index: this.items.length,
          type: 'value',
          valid: 'red',
          value: null,
          advancedValue: {},
          advancedValueType: 'datetime',
          useAdvanced: false,
          dtype: 'string',
          logicOperator: '+',
        });
        break;
    }
    this.validateFilter();
  }
  openAdvancedValueDialog(object: VirtualColumnValue) {
    const ref = this.dialog.open(AdvancedValueV2DialogComponent, {
      context: {
        filterItem: object,
        columns: this.columns,
        allowedTypes: ['datetime', 'date', 'timedelta', 'filtered_timespan'],
      },
    });
    ref.onClose.subscribe(() => this.validateFilter());
  }
  UpdateAdvancedValuePreviews() {
    for (let item of this.items) {
      if (item.type === 'value' && item.useAdvanced) {
        try {
          if (
            item.advancedValueType == 'datetime' ||
            item.advancedValueType == 'date'
          ) {
            if (item.advancedValue.time_type == 'current_time') {
              let offset = item.advancedValue.offset;
              item.valuePreview =
                (offset >= 0 ? 'Now + ' : 'Now ') +
                new TimeDelta(offset).toReadable();
            } else if (item.advancedValue.time_type === 'relative_time') {
              item.valuePreview =
                item.advancedValue.beginning_or_end +
                ' of the ' +
                item.advancedValue.between_dates.start_unit +
                ' ' +
                item.advancedValue.between_dates.start_offset;
            } else {
              item.valuePreview = item.advancedValue.time;
            }
          } else if (item.advancedValueType == 'column') {
            item.valuePreview = item.advancedValue.column;

            if (
              item.advancedValue.force_datatype != 'none' &&
              item.advancedValue.use_offset
            ) {
              if (
                item.advancedValue.force_datatype == 'datetime' ||
                item.advancedValue.force_datatype == 'date'
              ) {
              } else if (item.advancedValue.force_datatype == 'string') {
                item.valuePreview =
                  item.valuePreview + ' + ' + item.advancedValue.offset;
              } else {
                item.valuePreview =
                  item.valuePreview +
                  (item.advancedValue.offset >= 0
                    ? ' + ' + item.advancedValue.offset
                    : ' - ' + -item.advancedValue.offset);
              }
            }
          } else if (item.advancedValueType == 'null') {
            item.valuePreview = 'NULL';
            this.items[item.index]['item'] = '=';
          } else if (item.advancedValueType == 'boolean') {
            this.items[item.index]['item'] = '=';
            item.valuePreview = item.advancedValue.value + '';
          } else if (item.advancedValueType == 'timedelta') {
            item.valuePreview = new TimeDelta(
              item.advancedValue.offset,
            ).toReadable();
          } else if (item.advancedValueType == 'between_dates') {
            item.valuePreview =
              'Between ' +
              item.advancedValue.start_unit +
              (item.advancedValue.start_offset >= 0 ? '+' : '-') +
              Math.abs(item.advancedValue.start_offset) +
              ' start and ' +
              item.advancedValue.end_unit +
              (item.advancedValue.end_offset >= 0 ? '+' : '-') +
              Math.abs(item.advancedValue.end_offset) +
              ' end';
          } else if (item.advancedValueType == 'filtered_timespan') {
            item.valuePreview = 'Filtered Timespan';
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        item.valuePreview = '';
      }
    }
  }
  deleteItem(filter_index) {
    //this deletes a item box from the list and its associated logical operator
    if (this.items.length > 1) {
      if (filter_index != -1) {
        //delete parentheses that are attached to this item
        let uuidsToDelete = [];
        if (this.Filter.leftParentheses[filter_index] != undefined) {
          for (let uuid of this.Filter.leftParentheses[filter_index]) {
            uuidsToDelete.push(uuid);
          }
        }
        if (this.Filter.rightParentheses[filter_index] != undefined) {
          for (let uuid of this.Filter.rightParentheses[filter_index]) {
            if (uuidsToDelete.indexOf(uuid) == -1) {
              uuidsToDelete.push(uuid);
            }
          }
        }

        for (let uuid of uuidsToDelete) {
          this.deleteParentheses(uuid);
        }

        //delete item
        this.items.splice(filter_index, 1);

        //delete parentheses lists
        this.Filter.leftParentheses.splice(filter_index, 1);
        this.Filter.rightParentheses.splice(filter_index, 1);
      }
      setTimeout(() => {
        this.validateFilter();
      }, 0);
    }
  }
  validateFilter() {
    this.items.forEach((item) => (item.valid = 'green'));
    this.reIndex();
    this.checkOperatorStruct();
    const { types, operators } = new NestedVirtualColumn(
      this.Filter,
      this.operators,
      this.columns,
      true,
    ).getTypes();
    this.SpotTypes = types;
    this.operators = operators;

    //check if the filters are valid and set outline color accordingly
    for (let index in this.items) {
      let filterItem = this.items[index];
      if (
        //make sure a column is selected
        (filterItem.type === 'column' &&
          (filterItem.column == undefined ||
            filterItem.column == '' ||
            (filterItem.aggregate && !filterItem.aggregateOperation))) ||
        //make sure the types are ok for the type conver
        (filterItem.type == 'type' &&
          ((this.SpotTypes[parseInt(index) - 1] != undefined &&
            this.dtype_conversions[
              this.SpotTypes[parseInt(index) - 1]
            ].to_types.indexOf(filterItem.convertToType) == -1) ||
            this.Filter.leftParentheses[index].length > 0 ||
            index == '0')) ||
        // make sure that a unit is selected if nessesary
        (filterItem.type == 'type' &&
          this.dtype_conversions[this.SpotTypes[parseInt(index) - 1]] &&
          this.dtype_conversions[this.SpotTypes[parseInt(index) - 1]][
            filterItem.convertToType
          ] != null &&
          this.dtype_conversions[this.SpotTypes[parseInt(index) - 1]][
            filterItem.convertToType
          ].indexOf(filterItem.units) === -1) ||
        (filterItem.type === 'value' &&
          !filterItem.useAdvanced &&
          ['float', 'int'].includes(filterItem.dtype) &&
          Number.isNaN(parseFloat(filterItem.value + '')))
      ) {
        filterItem.valid = 'red';
      }
    }
    this.checkForChanges();
    this.UpdateAdvancedValuePreviews();
    // this.changeDetector.detectChanges();
  }

  checkForChanges() {
    setTimeout(() => {
      this.allFiltersValid = this.items.every((item) => item.valid !== 'red');
      if (
        this.allFiltersValid &&
        !deepEqual(this.Filter, this.virtualColumn.pandasFilter)
      ) {
        this.virtualColumnChange.emit(deepcopy(this.Filter));
      }
    }, 0);
  }

  OpenTimezoneDialog(index) {
    let config = new NbDialogConfig({});
    config.context = {
      Column: this.items[index],
    };
    this.dialog.open(TimezoneDialogComponent, config);
  }
  openFilterDialog(index) {
    const item = this.colItem(this.items[index]);
    this.dialog
      .open(FilterDialogComponent, {
        context: {
          filterInput: item.aggregateFilter,
          columns: this.columns,
          title: 'Filter the rows to be aggregated',
        },
      })
      .onClose.subscribe((filter) => {
        if (filter === undefined) return;
        item.aggregateFilter = filter;
        this.checkForChanges();
      });
  }
  toInt(s) {
    return parseInt(s);
  }
  checkOperatorStruct() {
    while (this.operators.length < this.items.length) {
      this.operators.push([]);
    }
  }
}
