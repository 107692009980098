import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toUpperSnakeCase } from '@helpers';
import { IAppEvents, IRequestPayloadMap } from '@interfaces';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { WebsocketDisconnectOverlayComponent } from '@root/core-components/websocket-disconnect-overlay/websocket-disconnect-overlay.component';
import { AuthService } from '@services';
import { environment } from 'environments/environment';
import { firstValueFrom, Observable, of, race, Subject, timer } from 'rxjs';
import { catchError, first, map, takeUntil, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { v4 } from 'uuid';
import {
  IAPIMessage,
  IAPIResponse,
} from '../interface-registry/data-service.interface';
import { EventQueueService } from './event-queue.service';
import { IAppStateModel } from '@root/state/app.model';
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor(
    private eventQueue: EventQueueService,
    private http: HttpClient,
    private authService: AuthService,
    private dialog: NbDialogService,
    private store: Store,
  ) {
    this.eventQueue.on('AUTHENTICATION_COMPLETE').subscribe((payload) => {
      // No need to reconnect if we're just refreshing the token
      if (payload.is_manual_token_refresh && !!this.webSocket) return;
      this.connectWebsocket(false);
    });
    this.eventQueue.on('REFRESH_PUBLIC_LINKS').subscribe((_) => {
      if (this.noAuthSocketWasInitialized) location.reload();
    });
    eventQueue
      .on('ADMIN_REFRESH_PUBLIC_LINKS')
      .subscribe(({ res, success }) => {
        if (res === null && this.noAuthSocketWasInitialized) location.reload();
      });
  }

  private webSocket: WebSocketSubject<any>;
  private noAuthSocket: WebSocketSubject<any>;
  private noAuthSocketWasInitialized = false;
  private socketUrl = `${environment.websocketUrl}/websocket/`;

  // Push a null value when webSocket connection breaks
  private connectionBroke$ = new Subject<null>();
  private connectionNoAuthBroke$ = new Subject<null>();

  private disconnectedOverlayRef: NbDialogRef<WebsocketDisconnectOverlayComponent>;
  private disconnected = false;

  private connectWebsocket(isReconnectAttempt: boolean) {
    if (!this.authService.getTokenIsValid()) {
      setTimeout(() => {
        this.connectWebsocket(isReconnectAttempt);
      }, 500);
      return;
    }
    this.webSocket = webSocket(this.socketUrl);
    this.webSocket
      .pipe(
        tap((msg) => {
          // Handle messages from the server
          try {
            this.handleMessage(msg);
          } catch (err) {
            console.error(err);
            console.error('Websocket message handling failed :(');
          }
        }),
        // Handle any errors
        catchError((err, data) => {
          this.disconnected = true;
          setTimeout(() => {
            this.disconnectedOverlay();
          }, 4000);
          console.error(err);
          // Notify subject that connection broke and end end this subscription
          this.connectionBroke$.next(null);
          setTimeout(() => {
            this.connectWebsocket(true);
          }, 3000); // Called if at any point webSocket API signals some kind of error.
          return of(null);
        }),
        takeUntil(this.connectionBroke$),
      )
      .subscribe();
    this.disconnected = false;
    setTimeout(() => {
      this.closeDisconnectedOverlay();
    }, 200);
    if (isReconnectAttempt) {
      this.eventQueue.dispatch('WEBSOCKET_RECONNECTED', null);
      console.log('reconnecting');
    }
  }

  private connectNoAuthWebsocket(token: string, isReconnectAttempt: boolean) {
    this.noAuthSocket = webSocket(this.socketUrl);
    this.noAuthSocket
      .pipe(
        tap((msg) => {
          // Handle messages from the server
          try {
            this.handleMessage(msg);
          } catch (err) {
            console.error(err);
            console.error('Websocket message handling failed :(');
          }
        }),
        // Handle any errors
        catchError((err, data) => {
          console.error(err);
          // Notify subject that connection broke and end end this subscription
          this.connectionNoAuthBroke$.next(null);
          setTimeout(() => {
            this.connectNoAuthWebsocket(token, true);
          }, 3000); // Called if at any point webSocket API signals some kind of error.
          return of(null);
        }),
        takeUntil(this.connectionNoAuthBroke$),
      )
      .subscribe();
    if (isReconnectAttempt) {
      this.eventQueue.dispatch('WEBSOCKET_RECONNECTED', null);
      console.log('reconnecting');
    }
  }
  private closeDisconnectedOverlay() {
    if (this.disconnectedOverlayRef !== undefined && !this.disconnected) {
      this.disconnectedOverlayRef.close();
      this.disconnectedOverlayRef = undefined;
    }
  }
  private disconnectedOverlay() {
    if (this.disconnectedOverlayRef === undefined && this.disconnected) {
      this.disconnectedOverlayRef = this.dialog.open(
        WebsocketDisconnectOverlayComponent,
        {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          hasBackdrop: false,
        },
      );
    }
  }
  private async handleMessage(message) {
    if (message.error_message === undefined) message.error_message = '';
    // Once every minute or so the user can get issued a new token in any server
    if (message.new_token)
      this.eventQueue.dispatch('REFRESH_STALE_TOKEN', message.new_token);
    if ('response_body' in message) {
      if (message['response_type'] == 'DataSetCreation') {
        if (message['response_type'] == 'AgentResponse') {
          if (message['response_body']['message_type'] === 'update_table_info')
            message['response_body']['message_type'] = 'SAVE_TABLE';
          this.sendEvent(
            message['response_body']['message_type'],
            message['response_body']['message_body'],
            message.request_id,
            message.error_message,
          );
        } else {
          this.sendEvent(
            message['response_type'],
            message['response_body'],
            message.request_id,
            message.error_message,
          );
        }
      } else {
        this.sendEvent(
          message['response_type'],
          message['response_body'],
          message.request_id,
          message.error_message,
        );
      }
    } else if ('MessageType' in message) {
      const msg = message as IAPIMessage<keyof IAppEvents>;
      // Idk what else is covered by this, but CALCULATION_DATA_UPDATE is the only one I've seen
      // and it wasn't easy to find
      this.eventQueue.dispatch(msg.MessageType, msg.Message);
    }
  }
  private async sendEvent(
    eventType: string,
    payload: any,
    request_id: string,
    error_message: string = '',
  ) {
    let notification = {};
    notification[eventType] = payload;
    this.showErrorToast(eventType, payload, error_message);
    this.eventQueue.dispatch(
      toUpperSnakeCase(eventType) as keyof IAppEvents,
      this.formatAPIResponse(payload, request_id, error_message),
    );
  }
  public closeWebsocket() {
    if (!!this.webSocket) {
      this.webSocket.unsubscribe();
      delete this.webSocket;
    }
  }
  private formatAPIResponse(res, request_id, error_message) {
    if (res === false) {
      return {
        success: false,
        res: null,
        request_id: request_id,
        error: error_message,
      };
    } else {
      return {
        success: true,
        res: res,
        request_id: request_id,
        error: error_message,
      };
    }
  }

  public checkUpperSnakeCase(str: string) {
    console.log(toUpperSnakeCase(str));
  }

  /**
   * Sends a websocket message but returns a promise with the response expected to come from the backend
   * much like an HTTP call
   * @param requestType the request type
   * @param body the message body
   * @returns
   */
  public async asyncRequest<T extends keyof IRequestPayloadMap>(
    requestType: T,
    body?: IRequestPayloadMap[T],
    timeoutMs: number = 10000,
  ): Promise<IAppEvents[T]> {
    return firstValueFrom(this.asyncRequest$(requestType, body, timeoutMs));
  }

  public asyncRequest$<T extends keyof IRequestPayloadMap>(
    requestType: T,
    body?: IRequestPayloadMap[T],
    timeoutMs: number = 10000,
  ): Observable<IAppEvents[T]> {
    const requestId = v4();
    if (body === undefined) {
      body = null;
    }
    this.websocketSend({
      request_id: requestId,
      request_type: requestType,
      request_body: body,
    });
    const snakeShit = toUpperSnakeCase(requestType) as keyof IAppEvents;

    const matchingResponse$ = this.eventQueue
      .on(snakeShit)
      .pipe(
        first((res) => (res as IAPIResponse<T>).request_id === requestId),
      ) as any;
    // We typecast to any bc TS doesn't detect us doing the filtering for IAPIResponse objects
    return matchingResponse$ as Observable<IAppEvents[T]>;
  }

  /**
   * This function adds the authentication token to the message and
   * waits until it has authentication and a websocket connection before sending the message
   */
  private async websocketSend(message) {
    const token = this.authService.getFullToken();
    const impersonatingCustomerId = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.impersonatingCustomerId,
    );
    if (token && this.webSocket !== undefined && !this.webSocket.closed) {
      message.token = token;
      if (impersonatingCustomerId)
        message.impersonatingCustomerId = impersonatingCustomerId;
      if (message.request_id === undefined) {
        message.request_id = v4();
      }
      this.webSocket.next(message);
    } else {
      setTimeout(() => {
        if (!token) {
          console.error(
            'Failed authentication, retrying websocket send...',
            message,
          );
        }
        this.websocketSend(message);
      }, 1000);
    }
  }

  /**
   * Sends an unauthenticated websocket message but returns a promise with the response expected
   * to come from the backend much like an HTTP call
   */
  public async unAuthenticatedAsyncRequest<T extends keyof IRequestPayloadMap>(
    requestType: T,
    body?: IRequestPayloadMap[T],
    token?: string,
    timeoutMs: number = 10000,
  ): Promise<IAppEvents[T]> {
    return firstValueFrom(
      this.unAuthenticatedAsyncRequest$(requestType, body, token, timeoutMs),
    );
  }

  public unAuthenticatedAsyncRequest$<T extends keyof IRequestPayloadMap>(
    requestType: T,
    body?: IRequestPayloadMap[T],
    token?: string,
    timeoutMs: number = 20000,
  ): Observable<IAppEvents[T]> {
    const request_id = v4();
    if (body === undefined) {
      body = null;
    }
    this.websocketNoAuthSend(
      {
        request_id: request_id,
        request_type: requestType,
        request_body: body,
      },
      token,
    );

    const snakeShit = toUpperSnakeCase(requestType) as any;

    const matchingResponse$ = this.eventQueue
      .on(snakeShit)
      .pipe(first((res) => res.request_id === request_id));

    const timeoutTimer$ = timer(timeoutMs).pipe(
      map(() => {
        throw new Error(
          `Request ${requestType} timed out after 10 seconds on the unauthenticated websocket channel. Either the server did not respond or the response had a bad request_id`,
        );
      }),
    );

    return race(matchingResponse$, timeoutTimer$);
  }
  /**
   * This method is responsible for sending messages through the unauthenticated ws connection,
   * an optional token can be provided in case a shared access auth token is available to the user
   */
  private async websocketNoAuthSend(message, token?: string) {
    // Connection to unauthenticated socket is not started automatically, so start it on the
    // first unAuth ws message send
    if (!this.noAuthSocketWasInitialized) {
      this.connectNoAuthWebsocket(token, false);
      this.noAuthSocketWasInitialized = true;
    }
    if (this.noAuthSocket && !this.noAuthSocket.closed) {
      message.token = token;
      if (message.request_id === undefined) {
        message.request_id = v4();
      }
      this.noAuthSocket.next(message);
    } else {
      setTimeout(() => {
        this.websocketNoAuthSend(message, token);
      }, 200);
    }
  }

  private showErrorToast(requestType, response, error_message) {
    if (response === false && typeof response === 'boolean') {
      let message =
        error_message === ''
          ? 'Your request to ' + requestType + ' failed in the backend.'
          : requestType + ' failed: ' + error_message;
      console.error(message);
      this.eventQueue.dispatch('SHOW_TOAST', {
        message: 'Error: ' + error_message,
        title: 'Uh Oh...',
        duration: 5000,
        status: 'danger',
      });
    } else if (response === 'invalidRequest') {
      this.eventQueue.dispatch('SHOW_TOAST', {
        message: requestType + " isn't a valid request",
        title: 'Silly Goose...',
        duration: 5000,
        status: 'basic',
      });
    }
  }
}
